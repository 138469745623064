import { CellDoubleClickedEvent, ColDef, GridApi, GridOptions } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { formatMoney } from "../../Utils/MoneyUtils";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";

import axios from "axios";
import { DineroObject } from "dinero.js";
import { LoanType } from "../../entity/models/UtilityInterfaces";
import Loader from "../Common/Loader";
import "./Tier1LedgerAdjustment.scss";

export interface ListTier1LedgerAdjustmentInfo {
  LoanId: number;
  LoanType: LoanType;
  TransactionId: string;
  CreditedTo: string;
  PaymentAmount: DineroObject;
  InvoiceNumber: string;
  ChangeInReceivables: DineroObject;
  SupplierInvoiceNumber: string;
  ChangeInPayables: DineroObject;
  InterestAmount: DineroObject;
  UtilizedBySupplier: DineroObject;
  UtilizedBySelf: DineroObject;
}

export interface ListTier1LedgerAdjustmentResponse {
  tier1LedgerAdjustmentInfoList: ListTier1LedgerAdjustmentInfo[];
}

export interface UpdateTier1EntriesForLoanResponse {
  message: string;
}

export interface ImageSourceFiles {
  InvoiceFile?: string;
  GrnFile?: string;
  DebitNotes?: string[];
}
/**
 * @Component
 * This component renders the tier1 early payment screen. It displays all invoices of a Tier1 on which it can take an early payment.
 */
const Tier1LedgerAdjustment = () => {
  const location = useLocation<any>();
  const [gridApi, setGridApi] = useState({} as GridApi);
  const { actor } = useContext(userContext);
  const { pathParam } = useParams<{ pathParam?: string }>();
  const [tier1LedgerAdjustmentData, setTier1LedgerAdjustmentData] = useState([] as ListTier1LedgerAdjustmentInfo[]);
  // const [invoicetoupdate, setInvoicetoupdate] = useState<Tier2Invoice>();
  // const [remark, setRemark] = useState("");
  const [loader, setLoader] = useState(true);
  // const [visible, setVisible] = useState<visibleTab>("invoice");
  // const [signedSrc, setSignedSrc] = useState<ImageSourceFiles>({});
  // const [uploading, setUploading] = useState(false);
  // const [openDialog, setOpenDialog] = useState<boolean>(false);

  // const { actor } = useContext(userContext);
  // const updateremark = (event: React.ChangeEvent<HTMLTextAreaElement>) => setRemark(event.target.value);

  // invoice:
  // vendor:
  // invoice_date:
  // invoice_amount:
  // net_amount:
  // early_payment:
  // discount:
  // action:

  /**
   * This is the column definition for the screen.
   */

  //  payment:
  //  invoice_no:
  //  delta_recievable:
  //  supplier_invoice:
  //  delta_payables:
  //  early_payment:
  //  discount:
  // import { ColDef, ColGroupDef, GridReadyEvent, CellClickedEvent, CellDoubleClickedEvent, CellContextMenuEvent } from '@ag-grid/all-modules';

  const onCellDoubleClicked = (event: CellDoubleClickedEvent) => {
    console.log("the cell is double clicked", event.colDef, event.data);
  };
  const columnDefs: any = [
    // { headerName: "Select", field: "selected", checkboxSelection: true, minWidth: 40},
    {
      headerName: "Transaction ID",
      field: "transaction_id",
      minWidth: 180,
    },
    // {
    //   headerName: "Credited To",
    //   field: "credited_to",
    //   minWidth: 180,
    // },
    {
      headerName: "Utilized by Supplier",
      field: "utilized_by_supplier",
      minWidth: 180,
    },
    {
      headerName: "Amount Breakup",
      wrapText: true,
      cellStyle: { overflow: "visible" },
      children: [
        {
          headerName: "Early Payment Amount",
          field: "payment_amount",
          minWidth: 180,
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          columnGroupShow: "open",
        },
        {
          headerName: " Interest Amount",
          field: "interest_amount",
          minWidth: 180,
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          columnGroupShow: "open",
        },
        {
          headerName: "Utilized by Self",
          field: "utilized_by_self",
          minWidth: 180,
          cellStyle: { color: "#48AC23" },
        },
      ],
    },

    {
      headerName: "Invoice Number",
      field: "invoice_no",
      onCellDoubleClicked,
    },
    { headerName: "Change in Recievables", field: "delta_recievable", cellStyle: { color: "#48AC23" } },
    { headerName: "Supplier Invoice No.", field: "supplier_invoice" },
    { headerName: "Change in Paybales", field: "delta_payables", cellStyle: { color: "#48AC23" } },
    // { headerName: "Current Account", field: "current_account", cellStyle: { color: "#48AC23" } },
    // { headerName: "Discounting", field: "discounting", cellStyle: { color: "#48AC23" } },
    {
      headerName: "Select",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 180,
    sortable: true,
    cellStyle: { color: "#4D4F5C" },
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };
  const onGridReady = async (params?: GridOptions) => {
    await axios
      .get<ListTier1LedgerAdjustmentResponse>("/api/ListTier1LedgerAdjustment")
      .then((response) => {
        console.log("response", response.data);
        // setTier2actiondata(response.data);
        setTier1LedgerAdjustmentData(response.data.tier1LedgerAdjustmentInfoList);
        console.log(tier1LedgerAdjustmentData);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
    setGridApi(params.api);
    console.log("gridApi", gridApi);
  };
  console.log("gridApi", gridApi);
  /**
   * This method sieve out the required field from the API response of pending state invoices.
   */
  const getrowdata = () => {
    return tier1LedgerAdjustmentData.map((inv: any) => {
      if (inv.LoanType === "Tier1EarlyPayment") {
        return {
          transaction_id: inv.TransactionId,
          // credited_to: inv.CreditedTo,
          utilized_by_supplier: formatMoney(inv.UtilizedBySupplier),
          payment_amount: formatMoney(inv.PaymentAmount),
          interest_amount: formatMoney(inv.InterestAmount),
          utilized_by_self: formatMoney(inv.UtilizedBySelf),
          invoice_no: inv.InvoiceNumber,
          // delta_recievable: formatMoney(inv.ChangeInReceivables), //negative value
          delta_recievable: "-" + formatMoney(inv.ChangeInReceivables), // negative value
          supplier_invoice: "NA",
          delta_payables: "NA",
          loanId: inv.LoanId,
          loanType: inv.LoanType,
        };
      } else if (inv.LoanType === "Tier2EarlyPayment") {
        return {
          transaction_id: inv.TransactionId,
          // credited_to: inv.CreditedTo,
          utilized_by_supplier: formatMoney(inv.UtilizedBySupplier),
          payment_amount: formatMoney(inv.PaymentAmount),
          interest_amount: formatMoney(inv.InterestAmount),
          utilized_by_self: formatMoney(inv.UtilizedBySelf),
          invoice_no: inv.InvoiceNumber,
          delta_recievable: "-" + formatMoney(inv.ChangeInReceivables), // negative value
          supplier_invoice: inv.SupplierInvoiceNumber,
          delta_payables: "-" + formatMoney(inv.ChangeInPaybles),
          loanId: inv.LoanId,
          loanType: inv.LoanType,
        };
      } else {
        console.log("error LoanType");
        return {};
      }
    });

    // return [
    //   {
    //     transaction_id: 101,
    //     invoice_no: 1120,
    //     delta_recievable: formatMoney(toDineroObject(-200000)),
    //     supplier_invoice: 1130,
    //     delta_payables: formatMoney(toDineroObject(-200000)),
    //     // current_account: "NA",
    //     // discounting: "NA",
    //   },
    //   {
    //     transaction_id: 102,
    //     invoice_no: 1120,
    //     delta_recievable: formatMoney(toDineroObject(-1000000)),
    //     supplier_invoice: 1130,
    //     delta_payables: "NA",
    //     // current_account: formatMoney(toDineroObject(990000)),
    //     // discounting: formatMoney(toDineroObject(10000)),
    //   },
    // ];
  };

  const UpdateTier1EntriesForLoan = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    console.log("selectedNodes", selectedNodes);

    const selectedData = selectedNodes.map((node) => node.data);
    console.log("selectedData", selectedData);

    const keysToKeep = ["loanId", "loanType"];

    const getReqKeyArray = (list: any) => list.map((o: any) => Object.fromEntries(keysToKeep.map((k) => [k, o[k]])));

    const loanIdsAndTypes = getReqKeyArray(selectedData);
    console.log("loanIdsAndTypes", loanIdsAndTypes);

    callApi(loanIdsAndTypes);
  };

  const callApi = async (loanIdsAndTypes: any) => {
    await axios
      .post<UpdateTier1EntriesForLoanResponse>("/api/UpdateTier1EntriesForLoan", { loanIdsAndTypes })
      .then((response) => {
        console.log("post response", response);
        window.location.reload();
      })
      .catch((error) => {
        console.log("post error", error.response.data);
      });
    // document.getElementById("modal").style.display = "none";
    //  callapi(discountedtier1invoice);
    console.log("gridApi", gridApi);
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Entry Adjustment", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <div>
        <div className="ag-theme-alpine mygrid" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
          {loader && <Loader />}
          <AgGridReact
            enableCellTextSelection={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            rowData={getrowdata()}
            domLayout="autoHeight"
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            rowClassRules={{
              highlight(params) {
                return params.data.invoice === "KEINV1234";
              },
            }}
          />
        </div>
        <div className="column" style={{ paddingLeft: "2vw" }}>
          <div className="buttons">
            <button
              className="button is-bold is-success"
              type="button"
              value="Download"
              onClick={() => {
                // gridApi.exportDataAsCsv({ fileName: actor.name + "_Adjustments_" + pathParam });
              }}
            >
              Download Payment Advice
            </button>
            <button
              className="button is-bold is-success"
              type="button"
              value="Download"
              onClick={() => {
                gridApi.exportDataAsCsv({ fileName: actor.name + "_Adjustments_" + pathParam, onlySelected: true });
                UpdateTier1EntriesForLoan();
              }}
            >
              Download
            </button>
            <button
              className="button is-bold is-danger"
              type="button"
              value="Download"
              onClick={() => {
                // gridApi.exportDataAsCsv({ fileName: actor.name + "_Adjustments_" + pathParam });
              }}
            >
              Raise Complaint
            </button>
          </div>
        </div>
      </div>
    </LoggedInSkeleton>
  );
};

export default Tier1LedgerAdjustment;
