// import Axios from "axios";
import React, { Component } from "react";
// import { Tier1EarlyPayment } from "../../../../src/database/entity/Tier1EarlyPayment";
// import { Tier2Invoice,  } from "../../../../src/database/entity/Tier2Invoice";
// import { DiscountableTier1InvoiceInfo } from "../../../../src/models/UtilityInterfaces";
// import Tier1Action from "./Tier1EarlyPayment";
import { ImageSourceFiles } from "../Tier1Action/Tier1Action";

interface BtnCellRendererProps {
  // clicked: (arg0: Tier2Invoice, index: number, imageSource: ImageSourceFiles, selectedData:any, arg4: string, arg5: DiscountableTier1InvoiceInfo[]) => Promise<void>;
  clicked: (arg0: string, arg1: number) => Promise<void>;
  // value: Tier2Invoice;
  rowIndex: number;
  data: any;
  value: string;
}
interface BtnCellRendererState {
  imageSource: ImageSourceFiles;
}
class BtnCellRenderer extends Component<BtnCellRendererProps, BtnCellRendererState> {
  constructor(props: BtnCellRendererProps) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
    this.state = {
      imageSource: {} as ImageSourceFiles,
    };
  }

  // async getImage(tier2InvoiceDetails: Tier2InvoiceDetails) {
  //   const invoiceImageId = tier2InvoiceDetails.invoiceFile.split("/").pop();
  //   const invoiceImage = await Axios.get(`/image/${invoiceImageId}`);
  //   let grnImage;
  //   let debitNoteImage;
  //   if (tier2InvoiceDetails.grnFile !== undefined) {
  //     const grnImageId = tier2InvoiceDetails.grnFile.split("/").pop();
  //     grnImage = await Axios.get(`/image/${grnImageId}`);
  //   }
  //   if (tier2InvoiceDetails.debitNotes.length > 0) {
  //     const debitNotesImageId = tier2InvoiceDetails.debitNotes[0].debitNoteFile.split("/").pop();
  //     debitNoteImage = await Axios.get(`/image/${debitNotesImageId}`);
  //   }

  //   this.setState({
  //     imageSource: {
  //       InvoiceFile: invoiceImage.data,
  //       GrnFile: grnImage && grnImage.data,
  //       DebitNotes: debitNoteImage && [debitNoteImage.data],
  //     },
  //   });
  // }
  public async btnClickedHandler() {
    console.log("PROPS", this.props);
    // if (Object.keys(this.state.imageSource).length === 0) {
    //   await this.getImage(this.props.value.tier2InvoiceDetails);
    //   console.log("Image Source", this.state.imageSource);
    // }

    // this.props.clicked(this.props.value, this.props.rowIndex, this.state.imageSource, this.props.data, this.props.feild, this.props.invoicesAvailableForDiscounting);
    this.props.clicked(this.props.data.invoice, this.props.rowIndex);
  }

  render() {
    return (
      <button className="button is-accept" onClick={this.btnClickedHandler}>
        Take Early Payment
      </button>
    );
  }
}

export default BtnCellRenderer;
