import React from "react";
import { Link } from "react-router-dom";

import { Dashboard } from "@mui/icons-material";
import { FrontendActor } from "../../entity/models/FrontendActor";
import {
  AdminTopBarType,
  BankTopBar,
  BankTopBarType,
  NavBarStructure,
  // ReconTopBarType,
} from "./Constants";

export function getBankTopBarButtons(currentPage: BankTopBarType) {
  return [
    {
      hasSubTab: false,
      name: "Disbursement Screen",
      to: "/bank/disbursement",
      className: `navbar-item ${currentPage === BankTopBar.Disbursement ? "this-page" : ""}`,
      icon: <i className="fa-solid fa-arrow-trend-up" />,
    },
    {
      hasSubTab: false,
      name: "Collection Screen",
      to: "/bank/collection",
      icon: <i className="fa-solid fa-arrow-trend-down" />,
    },
    {
      hasSubTab: false,
      name: "Settings",
      to: "/bank/settings",
      icon: <i className="fa-solid fa-gear" />,
    },
  ] as NavBarStructure[];
}

export function getTier2TopBarButtons() {
  return [
    {
      hasSubTab: false,
      name: "Early Payment",
      to: "/tier2/early",
      icon: <i className="fa-solid fa-money-check-dollar" />,
    },
    {
      hasSubTab: false,
      name: "Ledger Adjustment",
      to: "/tier2/ledger",
      icon: <i className="fa-solid fa-sliders" />,
    },
    {
      hasSubTab: false,
      name: "Audit Trail",
      to: "/tier2/audit",
      icon: <i className="fa-solid fa-magnifying-glass-dollar" />,
    },
  ] as NavBarStructure[];
}

export function getTier1TopBarButtons() {
  return [
    {
      hasSubTab: false,
      name: "Early Payment",
      to: "/tier1/early",
      icon: <i className="fa-solid fa-money-check-dollar" />,
    },
    {
      hasSubTab: false,
      name: "Ledger Adjustment",
      to: "/tier1/ledger",
      icon: <i className="fa-solid fa-sliders" />,
    },
    {
      hasSubTab: false,
      name: "Audit Trail",
      to: "/tier1/audit",
      icon: <i className="fa-solid fa-magnifying-glass-dollar" />,
    },
    {
      hasSubTab: false,
      name: "Upload",
      to: "/tier1/upload",
      icon: <i className="fa-solid fa-arrow-up-from-bracket" />,
    },
    {
      hasSubTab: false,
      name: "Settings",
      to: "/tier1/settings",
      icon: <i className="fa-solid fa-gear" />,
    },
  ] as NavBarStructure[];
}

export function getAnchorTopBarButtons() {
  return [
    {
      hasSubTab: false,
      name: "Upload",
      to: "/anchor/upload",
      icon: <i className="fa-solid fa-arrow-up-from-bracket" />,
    },
    {
      hasSubTab: false,
      name: "Audit Trail",
      to: "/anchor/audit",
      icon: <i className="fa-solid fa-magnifying-glass-dollar" />,
    },
    {
      hasSubTab: false,
      name: "Dashboard",
      to: "/anchor/ld",
      icon: <Dashboard />,
    },
    {
      hasSubTab: false,
      name: "Settings",
      to: "/anchor/settings",
      icon: <i className="fa-solid fa-gear" />,
    },
  ] as NavBarStructure[];
}

export function getAtherTestTopBarButtons() {
  return [
    <Link key={4} to="/athertest/recon360">
      RECON360
    </Link>,
  ];
}

export function getAdminTopBarButtons(currentPage: AdminTopBarType) {
  console.log("currentPage", currentPage);

  return [
    {
      hasSubTab: false,
      name: "Register",
      to: "/admin/Register",
      icon: <i className="fa-solid fa-user-plus" />,
    },

    {
      hasSubTab: false,
      name: "Disbursment Account",
      to: "/admin/disbursmentaccount",
      icon: <i className="fa-solid fa-user-secret" />,
    },
    {
      hasSubTab: false,
      name: "Collection Account",
      to: "/admin/collectionaccount",
      icon: <i className="fa-solid fa-user-tie" />,
    },
  ] as NavBarStructure[];
}

export function getReconTopBarButtons(
  _pageName: string,
  _actorName: string,
  _openCollapseObj: Record<string, unknown>,
  actor: FrontendActor
) {
  // const { actor } = useContext(userContext);
  // const accountName = actor?.name
  //   .toString()
  //   .toLowerCase()
  //   .replace(/[^a-z\d]+/gi, "");
  let buttons: NavBarStructure[] = [];

  if (actor.actorType === "Tier1" && process.env.REACT_APP_NAKAD_ENV === "test") {
    buttons = getTier1TopBarButtons();
  } else if (actor.actorType === "Tier2" && process.env.REACT_APP_NAKAD_ENV === "test") {
    buttons = getTier2TopBarButtons();
  } else if (actor.actorType === "Anchor" && process.env.REACT_APP_NAKAD_ENV === "test") {
    buttons = getAnchorTopBarButtons();
  }

  const allNavBarButtons: NavBarStructure[] = [...buttons];

  const filteredSubMenu = allNavBarButtons?.map((navBarBtns) => {
    if (navBarBtns?.subMenu?.length > 0) {
      navBarBtns.subMenu = navBarBtns.subMenu.filter((subMenu) => (subMenu.show === undefined ? true : subMenu.show));
      return navBarBtns;
    } else return navBarBtns;
  });

  const filteredNavBarButtons = filteredSubMenu?.filter((button) => {
    if (button.show === undefined) {
      return true;
    }
    return button.show;
  });

  return filteredNavBarButtons;
}
