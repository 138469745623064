import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router";
import { AnchorTier1Mapping } from "../../entity/ActorMappings";
import { ActorMappings } from "../../entity/ActorMappings";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
// import "./Settings.scss";

export interface ListAllActorMappingsResponse {
  /**
   * List of Actor Mappings
   */
  actorMappings: ActorMappings[];
}

export const AnchorSettings = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const [mappingData, setMappingData] = useState({ actorMappings: [] } as ListAllActorMappingsResponse);
  const columnDefs = [
    { headerName: "Supplier Id", field: "supplier_id", minWidth: 200 },
    { headerName: "Supplier", field: "supplier", minWidth: 200 },
    { headerName: "Invoice Buffer (Days)", field: "invoice_buffer", minWidth: 200 },
    { headerName: "Payment Terms (Days)", field: "payment_terms", minWidth: 200 },
  ];
  const defaultColDef = {
    flex: 1,
    minWidth: 200,
    sortable: true,
    cellStyle: { color: "#4D4F5C" },
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  };

  const onGridReady = async () => {
    const { data } = await axios.get<ListAllActorMappingsResponse>("/api/ListAllActorMappings", {
      params: {
        parentId: actor.id,
      },
    });
    console.log("mappingData", data);
    setMappingData(data);
  };
  const getrowdata = () => {
    return mappingData.actorMappings.map((mdata) => {
      return {
        supplier_id: mdata.child.actorId, // confirm this one
        supplier: mdata.child.actorInfo.name,
        invoice_buffer: (mdata.mappingData as AnchorTier1Mapping).discountingBufferDays,
        payment_terms: (mdata.mappingData as AnchorTier1Mapping).invoiceToPaymentGapInDays,
      };
    });
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Settings", actor.name, location?.state?.openCollapseOb, actor)}
    >
      <div className="ag-theme-alpine mygrid">
        {mappingData.actorMappings.length === 0 && <Loader />}
        <AgGridReact
          enableCellTextSelection={true}
          suppressMenuHide={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowData={getrowdata()}
          domLayout="autoHeight"
        />
      </div>
    </LoggedInSkeleton>
  );
};
