import { ColDef } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { DineroObject } from "dinero.js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { dateValueFormatter } from "../../Utils/DateUtils";
import { formatMoney } from "../../Utils/MoneyUtils";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
import "./Tier1AuditTrail.scss";

export interface ListTier1AuditTrailResponse {
  Tier1AuditTrailInfoList: Tier1AuditTrailInfo[];
}

export interface Tier1AuditTrailInfo {
  InvoiceNumber: string;
  InvoiceDate: Date;
  Customer: string;
  InvoiceAmount: DineroObject;
  Advance: DineroObject;
  DebitNote: DineroObject;
  CreditNote: DineroObject;
  NetReceivable: DineroObject;
  EarlyPayment: DineroObject;
  EarlyPaymentAccount: string;
  InterestAmount: DineroObject;
  PaymentAmount: DineroObject;
  PaymentAccount: string;
  Supplier: string;
  SupplierInvoice: string;
  SupplierInvoiceAmount: DineroObject;
  SupplierAdvance: DineroObject;
  SupplierDebitNote: DineroObject;
  SupplierCreditNote: DineroObject;
  NetPayable: DineroObject;
  SupplierEarlyPayment: DineroObject;
  SupplierPaymentAccount: string;
  SupplierPayment: DineroObject;
  SupplierSOAAdjustment: string;
}

/**
 * This interface is used to interact with BtnCellRenderer Component.
 */
export interface EntryAdjusted {
  anchorInvoiceId: string;
  tier2InvoiceId: string;
  anchorId: number;
  tier1Id: number;
  tier2Id: number;
  anchorAdjustment: string;
}

/**
 * This is the interface for CollectionRowThis is the interface for CollectionRow
 * @interface
 *
 */
export interface CollectionRow {
  invoice: string;
  supplier: string;
  invoice_date: Date;
  payable_amount: string;
  supplier_payable: string;
  nakad_payable: string;
  entryAdjusted?: EntryAdjusted;
}

export interface RowData {
  invoice: string;
}

/**
 * @Component
 * Anchor Component for Anchor Entry Adjustment tab. It contains two sub tabs - pending and completed.
 *
 * 1.Pending - All the anchor invoices(Tier1Invoices) which were matched against a Tier2Invoice. The loan for the mapping has been sanctioned and disbursed. This tab represents the invoices which are due to be adjusted in Anchor's ERP.
 *
 * 2.Completed - All the anchor invoices(Tier1Invoices) which are adjusted in ERP.
 *
 * This component uses Ag grid library to demostrate the data in tabular form.
 */
const Tier1AuditTrail = () => {
  const location = useLocation<any>();
  // const [tier1AuditTrailData, setTier1AuditTrailData] = useState([] as Tier1AuditTrailInfo[]);
  const [tier1AuditTrailOpenData, setTier1AuditTrailOpenData] = useState([] as Tier1AuditTrailInfo[]);
  const [tier1AuditTrailSettledData, setTier1AuditTrailSettledData] = useState([] as Tier1AuditTrailInfo[]);
  // const [gridApi, setGridApi] = useState({} as GridApi);
  const { actor } = useContext(userContext);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [hideColumn, setHideColumn] = useState(true);
  const [plus, setPlus] = useState(true);
  const [gridColumnApi1, setGridColumnApi1] = useState(null);
  const [hideColumn1, setHideColumn1] = useState(true);
  // const [plus, setPlus] = useState(true);
  const [loaderOpen, setLoaderOpen] = useState(true);
  const [loaderSettled, setLoaderSettled] = useState(true);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const history = useHistory();
  const { pathParam } = useParams<{ pathParam?: string }>();
  const DEFAULT_ACTIVE_TAB = "Open";
  const pathWay = "/tier1/audit";

  /**
   * react hook
   * runs first time only when dependency array is empty
   * if empty array not given than it runs everytime on the change dependency array
   * dependency array defines variable on which use effect is used e.g: api call on first load of page
   * @public
   */
  useEffect(() => {
    if (!pathParam) {
      history.push(`${pathWay}/${DEFAULT_ACTIVE_TAB}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (activeTab: string) => {
    if (pathParam !== activeTab) {
      history.push(`${pathWay}/${activeTab}`);
    }
  };

  /** This is a column definition of the grid on Anchor Entry Adjustment page - Pending sub-tab
   * @public
   */

  const colElements = Array.from(document.getElementsByClassName("ag-header-cell") as HTMLCollectionOf<HTMLElement>);

  console.log("ele", colElements);

  // console.log("netPaymentItem", netPaymentItem)

  // const item = colElements.forEach((elem) => {
  //   elem.addEventListener('click', () => {
  //     const attribute = elem.getAttribute('col-id');
  //     //you can add you custom styling based on selected index here
  //     console.log("attribute", attribute)
  //   });
  // });

  // console.log("item", item)

  const colDefns: any = [
    {
      headerName: "Invoice ID",
      field: "invoice_id",
      minWidth: 160,
      suppressMenu: false,
      sortable: false,
    },
    // { headerName: "Invoice number", field: "invoice", minWidth: 180, suppressMenu: true, sortable: false },
    {
      headerName: "Customer",
      field: "customer",
      minWidth: 180,
      suppressMenu: true,
      sortable: false,
    },
    {
      headerName: "Invoice date",
      field: "invoice_date",
      valueFormatter: dateValueFormatter,
      minWidth: 180,
      suppressMenu: true,
      sortable: false,
    },
    {
      headerName: "Net Receivable Breakup",
      children: [
        {
          headerName: "Invoice Amount",
          field: "invoice_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "invoice_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Advance",
          field: "advance_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "advance_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Debit Note",
          field: "debit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "debit_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Credit Note",
          field: "credit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "credit_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },

        {
          headerName: "Net Receivable",
          field: "net_receivable",
          cellStyle: { color: "#48AC23" },
          minWidth: 180,
          headerComponentParams: { plus, setPlus },

          // tooltipField: "net_payment",
        },
      ],
    },

    {
      headerName: "Early Payment ",
      field: "early_payment",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Early Payment Account",
      field: "early_payment_account",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
      hide: true,
    },
    // {
    //   headerName: "Interest Rate",
    //   field: "interest",
    //   cellStyle: { color: "#48AC23" },
    //   minWidth: 180,
    // },
    {
      headerName: "Interest Amount",
      field: "interest_amount",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Payment Amount",
      field: "payment_amount",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Payment Account",
      field: "payment_account",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
      hide: true,
    },
    {
      headerName: "Supplier",
      field: "supplier",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Supplier Invoice",
      field: "supplier_invoice",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Net Payable Breakup",
      children: [
        {
          headerName: "Supplier Invoice Amount",
          field: "supplier_invoice_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "supplier_invoice_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Advance",
          field: "supplier_advance",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "supplier_advance",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Debit Note",
          field: "supplier_debit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "supplier_debit_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Credit Note",
          field: "supplier_credit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "supplier_credit_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Net Payable",
          field: "net_payable",
          cellStyle: { color: "#48AC23" },
          minWidth: 180,
          headerComponentParams: { plus, setPlus },
        },
      ],
    },

    {
      headerName: "Utilized by Supplier",
      field: "supplier_early_payment",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Early Pay Account",
      field: "supplier_early_pay_account",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
      hide: true,
    },
    {
      headerName: "Payment",
      field: "payment",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
      hide: true,
    },
    {
      headerName: "Supplier SOA Adjustment",
      field: "supplier_acknowledgment",
      cellStyle: (params: any) => (params.value === "No" ? { color: "red" } : { color: "#48AC23" }),
      minWidth: 180,
    },
    // {
    //   headerName: "Lender's Share",
    //   field: "lender_payable",
    //   headerTooltip: "Net amount payable to the lender",
    //   cellStyle: { color: "#48AC23" },
    //   minWidth: 200,
    //   headerComponentParams: { menuIcon: "fa-external-link-alt" },
    //   suppressMenu: true,
    //   sortable: false,
    // },
    // {
    //   headerName: "Supplier's Share",
    //   field: "supplier_payable",
    //   headerTooltip: "Net amount payable to the supplier",
    //   cellStyle: { color: "#48AC23" },
    //   minWidth: 180,
    //   suppressMenu: false,
    //   sortable: false,
    // },
    // {
    //   headerName: "Supplier SOA Adjustment",
    //   field: "supplier_acknowledgment",
    //   cellStyle: { color: "#48AC23" },
    //   // cellRenderer: "btnCellRenderer",
    //   // cellClass: "center-cell",
    //   // cellRendererParams: {
    //   //   clicked(entryAdjusted: EntryAdjusted) {
    //   //     updateAnchorAdjustment(entryAdjusted);
    //   //     console.log("Entry Adjusted", entryAdjusted);
    //   //   },
    //   // },
    //   minWidth: 180,
    //   suppressMenu: false,
    //   sortable: false,
    // },
  ];
  // const components = useMemo(() => {
  //   return {
  //     agColumnHeader: CustomHeader,
  //   };
  // }, []);
  /** This is a column definition of the grid on Anchor Entry Adjustment page - Completed sub-tab
   * @public
   */
  const settledColDefns: any[] = [
    { headerName: "Invoice ID", field: "invoice_id", minWidth: 160 },
    // { headerName: "Invoice number", field: "invoice", minWidth: 180 },
    { headerName: "Customer", field: "customer", minWidth: 180 },
    {
      headerName: "Invoice date",
      field: "invoice_date",
      valueFormatter: dateValueFormatter,
      minWidth: 180,
    },
    {
      headerName: "Net Receivable Breakup",
      children: [
        {
          headerName: "Invoice Amount",
          field: "invoice_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "invoice_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Advance",
          field: "advance_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "advance_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Debit Note",
          field: "debit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "debit_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Credit Note",
          field: "credit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "credit_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },

        {
          headerName: "Net Receivable",
          field: "net_receivable",
          cellStyle: { color: "#48AC23" },
          minWidth: 180,
          headerComponentParams: { plus, setPlus },
        },
      ],
    },

    {
      headerName: "Early Payment ",
      field: "early_payment",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Early Payment Account",
      field: "early_payment_account",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
      hide: true,
    },
    // {
    //   headerName: "Interest Rate",
    //   field: "interest",
    //   cellStyle: { color: "#48AC23" },
    //   minWidth: 180,
    // }
    {
      headerName: "Interest Amount",
      field: "interest_amount",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Payment Amount",
      field: "payment_amount",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Payment Account",
      field: "payment_account",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
      hide: true,
    },
    {
      headerName: "Supplier",
      field: "supplier",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Supplier Invoice",
      field: "supplier_invoice",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Net Payable Breakup",
      children: [
        {
          headerName: "Supplier Invoice Amount",
          field: "supplier_invoice_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "supplier_invoice_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Advance",
          field: "supplier_advance",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "supplier_advance",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Debit Note",
          field: "supplier_debit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "supplier_debit_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Credit Note",
          field: "supplier_credit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "supplier_credit_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Net Payable",
          field: "net_payable",
          cellStyle: { color: "#48AC23" },
          minWidth: 180,
          headerComponentParams: { plus, setPlus },
        },
      ],
    },

    {
      headerName: "Utilized by Supplier",
      field: "supplier_early_payment",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Early Pay Account",
      field: "supplier_early_pay_account",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
      hide: true,
    },
    {
      headerName: "Payment",
      field: "payment",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
      hide: true,
    },
    {
      headerName: "Supplier SOA Adjustment",
      field: "supplier_acknowledgment",
      cellStyle: (params: any) => (params.value === "No" ? { color: "red" } : { color: "#48AC23" }),
      minWidth: 180,
    },
    // {
    //   headerName: "Lender's Share",
    //   field: "lender_payable",
    //   headerTooltip: "Net amount payable to the lender",
    //   cellStyle: { color: "#48AC23" },
    //   minWidth: 180,
    // },
    // {
    //   headerName: "Supplier's Share",
    //   field: "supplier_payable",
    //   headerTooltip: "Net amount payable to the supplier",
    //   cellStyle: { color: "#48AC23" },
    //   minWidth: 180,
    // },
  ];

  const defaultColDef: ColDef = {
    flex: 2,
    minWidth: 200,
    sortable: true,
    resizable: true,
    wrapText: true,
    filter: true,
    autoHeight: true,
    cellStyle: { color: "#4D4F5C" },
    tooltipComponent: "customTooltip",
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };

  // const defaultColDef: ColDef= useMemo(() => {
  //   return {
  //     editable: true,
  //     sortable: true,
  //     flex: 1,
  //     minWidth: 100,
  //     filter: true,
  //     resizable: true,
  //     headerComponentParams: {
  //       menuIcon: 'fa-bars',
  //     },
  //   };
  // }, []);

  /**
   * Button Component on pending sub-tab to confirm adjustment of entry in ERP.
   */
  const frameworkComponents = {
    // btnCellRenderer: BtnCellRenderer,
    // agColumnHeader: CustomHeader,
    // customTooltip: CustomTooltip,
  };

  /**
   * This handler is called on the initial rendering of the screen. It fetches all the list of anchor invoices against which loan has been sanctioned and disbursed.
   * @public
   */
  const onGridReadyOpen = async (params: any) => {
    await axios
      .get<ListTier1AuditTrailResponse>("/api/ListTier1AuditTrails", {
        params: {
          screenType: "open",
        },
      })
      .then((response) => {
        console.log("response", response.data.Tier1AuditTrailInfoList);
        setTier1AuditTrailOpenData(response.data.Tier1AuditTrailInfoList);
        setLoaderOpen(false);
      })
      .catch((error) => {
        console.log("error", error);
      });

    // console.log("Returned AnchorTier2INvoicesMapping", result.data.anchorTier2InvoicesMapping);
    // //setAnchorData(result.data.anchorTier2InvoicesMapping);
    // setGridApi(params.api);

    setGridColumnApi(params.columnApi);
    setGridColumnApi1(params.columnApi);
    console.log("setGridColumnApi", params.columnApi);
    console.log("setGridColumnApi", gridColumnApi);
  };

  const onGridReadySettled = async (params: any) => {
    await axios
      .get<ListTier1AuditTrailResponse>("/api/ListTier1AuditTrails", {
        params: {
          screenType: "settled",
        },
      })
      .then((response) => {
        console.log("response", response.data.Tier1AuditTrailInfoList);
        // setTier1AuditTrailData(response)
        setTier1AuditTrailSettledData(response.data.Tier1AuditTrailInfoList);
        setLoaderSettled(false);
      })
      .catch((error) => {
        console.log("error", error);
      });

    // console.log("Returned AnchorTier2INvoicesMapping", result.data.anchorTier2InvoicesMapping);
    // //setAnchorData(result.data.anchorTier2InvoicesMapping);
    // setGridApi(params.api);

    setGridColumnApi(params.columnApi);
    setGridColumnApi1(params.columnApi);
    console.log("setGridColumnApi", params.columnApi);
    console.log("setGridColumnApi", gridColumnApi);
  };

  /**
   * This method returns the data which is published on the pending sub-tab of the screen.
   * @public
   */

  // const checkInterest = (val: any) => {
  //   if (val === null) {
  //     return "NA";
  //   } else {
  //     return val.toFixed(2) + "%";
  //   }
  // };

  const getOpenRowData = () => {
    return tier1AuditTrailOpenData.map((inv) => {
      return {
        invoice_id: inv.InvoiceNumber,
        customer: inv.Customer,
        invoice_date: inv.InvoiceDate,
        invoice_amount: formatMoney(inv.InvoiceAmount),
        debit_amount: formatMoney(inv.DebitNote),
        credit_amount: formatMoney(inv.CreditNote),
        advance_amount: formatMoney(inv.Advance),
        net_receivable: formatMoney(inv.NetReceivable),
        early_payment: formatMoney(inv.EarlyPayment),
        early_payment_account: inv.EarlyPaymentAccount,
        // interest: inv.InterestRate.toFixed(2) + "%",
        // interest: checkInterest(inv.InterestRate),
        interest_amount: formatMoney(inv.InterestAmount),
        payment_amount: formatMoney(inv.PaymentAmount),
        payment_account: inv.PaymentAccount,
        supplier: inv.Supplier,
        supplier_invoice: inv.SupplierInvoice,
        supplier_invoice_amount: formatMoney(inv.SupplierInvoiceAmount),
        supplier_advance: formatMoney(inv.SupplierAdvance),
        supplier_debit_amount: formatMoney(inv.SupplierDebitNote),
        supplier_credit_amount: formatMoney(inv.SupplierCreditNote),
        net_payable: formatMoney(inv.NetPayable),
        supplier_early_payment: formatMoney(inv.SupplierEarlyPayment),
        supplier_early_pay_account: inv.SupplierPaymentAccount,
        // payment: inv.Payment,
        supplier_acknowledgment: inv.SupplierSOAAdjustment,
      };
    });

    // const pendingAnchorData = anchorData.filter((aData) => aData.anchorAdjustment === "Pending");
    // return pendingAnchorData.map((aData) => {
    //   const row: CollectionRow = {
    //     invoice: aData.anchorInvoiceId,
    //     supplier: aData.anchorInvoice.tier1.actorInfo.name,
    //     invoice_date: aData.anchorInvoice.invoiceDate,
    //     payable_amount: formatMoney(aData.anchorInvoice.invoiceAmount),
    //     nakad_payable: formatMoney(aData.loanAmount),
    //     supplier_payable: formatMoney(MoneyUtil.subtractMoney(aData.anchorInvoice.invoiceAmount, aData.loanAmount)),
    //     entryAdjusted: {
    //       anchorInvoiceId: aData.anchorInvoiceId,
    //       tier2InvoiceId: aData.tier2InvoiceId,
    //       anchorId: aData.anchorId,
    //       tier1Id: aData.tier1Id,
    //       tier2Id: aData.tier2Id,
    //       anchorAdjustment: aData.anchorAdjustment,
    //     },
    //   };
    //   return row;
    // });
    // return [
    //   {
    //     // invoice: 1010,
    //     invoice_id: 100,
    //     customer: "Mega Enterprises",
    //     invoice_date: "14 February 2022",
    //     invoice_amount: formatMoney(toDineroObject(5000)),
    //     debit_amount: formatMoney(toDineroObject(5000)),
    //     credit_amount: formatMoney(toDineroObject(5000)),
    //     advance_amount: formatMoney(toDineroObject(5000)),
    //     net_receivable: formatMoney(toDineroObject(5000)),
    //     lender_payable: formatMoney(toDineroObject(5000)),
    //     supplier_payable: formatMoney(toDineroObject(5000)),
    //     supplier_acknowledgment: "Yes",
    //   },
    //   {
    //     // invoice: 1011,
    //     invoice_id: 101,
    //     customer: "Mega Enterprises",
    //     invoice_date: "14 February 2022",
    //     invoice_amount: formatMoney(toDineroObject(5000)),
    //     debit_amount: formatMoney(toDineroObject(5000)),
    //     credit_amount: formatMoney(toDineroObject(5000)),
    //     advance_amount: formatMoney(toDineroObject(5000)),
    //     net_receivable: formatMoney(toDineroObject(5000)),
    //     lender_payable: formatMoney(toDineroObject(5000)),
    //     supplier_payable: formatMoney(toDineroObject(5000)),
    //     supplier_acknowledgment: "Yes",
    //   },
    // ];
  };
  /**
   * This method returns the data which is published on the completed sub-tab of the screen.
   * @public
   */
  const getSettledRowData = () => {
    return tier1AuditTrailSettledData.map((inv) => {
      return {
        invoice_id: inv.InvoiceNumber,
        customer: inv.Customer,
        invoice_date: inv.InvoiceDate,
        invoice_amount: formatMoney(inv.InvoiceAmount),
        debit_amount: formatMoney(inv.DebitNote),
        credit_amount: formatMoney(inv.CreditNote),
        advance_amount: formatMoney(inv.Advance),
        net_receivable: formatMoney(inv.NetReceivable),
        early_payment: formatMoney(inv.EarlyPayment),
        early_payment_account: inv.EarlyPaymentAccount,
        // interest: inv.InterestRate.toFixed(2) + "%",
        // interest: checkInterest(inv.InterestRate),
        interest_amount: formatMoney(inv.InterestAmount),
        payment_amount: formatMoney(inv.PaymentAmount),
        payment_account: inv.PaymentAccount,
        supplier: inv.Supplier,
        supplier_invoice: inv.SupplierInvoice,
        supplier_invoice_amount: formatMoney(inv.SupplierInvoiceAmount),
        supplier_advance: formatMoney(inv.SupplierAdvance),
        supplier_debit_amount: formatMoney(inv.SupplierDebitNote),
        supplier_credit_amount: formatMoney(inv.SupplierCreditNote),
        net_payable: formatMoney(inv.NetPayable),
        supplier_early_payment: formatMoney(inv.SupplierEarlyPayment),
        supplier_early_pay_account: inv.SupplierPaymentAccount,
        // payment: inv.Payment,
        supplier_acknowledgment: inv.SupplierSOAAdjustment,
      };
    });

    // const completedAnchorData = anchorData.filter((aData) => aData.anchorAdjustment === "Done");
    // console.log(completedAnchorData);
    // return completedAnchorData.map((aData) => {
    //   const row: CollectionRow = {
    //     invoice: aData.anchorInvoiceId,
    //     supplier: aData.anchorInvoice.tier1.actorInfo.name,
    //     invoice_date: aData.anchorInvoice.invoiceDate,
    //     payable_amount: formatMoney(aData.anchorInvoice.invoiceAmount),
    //     nakad_payable: formatMoney(aData.loanAmount),
    //     supplier_payable: formatMoney(MoneyUtil.subtractMoney(aData.anchorInvoice.invoiceAmount, aData.loanAmount)),
    //     entryAdjusted: {
    //       anchorInvoiceId: aData.anchorInvoiceId,
    //       tier2InvoiceId: aData.tier2InvoiceId,
    //       anchorId: aData.anchorId,
    //       tier1Id: aData.tier1Id,
    //       tier2Id: aData.tier2Id,
    //       anchorAdjustment: aData.anchorAdjustment,
    //     },
    //   };
    //   return row;
    // });
    // invoice:
    // supplier:
    // invoice_date:
    // invoice_amount:
    // debit_amount:
    // advance_amount:
    // net_payment:
    // lender_payable:
    // supplier_payable:
    // supplier_acknowledgment:
    // return [
    //   {
    //     invoice: 1012,
    //     invoice_id: 103,
    //     customer: "Mega Enterprises",
    //     invoice_date: "18 February 2022",
    //     invoice_amount: formatMoney(toDineroObject(5000)),
    //     debit_amount: formatMoney(toDineroObject(5000)),
    //     credit_amount: formatMoney(toDineroObject(5000)),
    //     advance_amount: formatMoney(toDineroObject(5000)),
    //     net_receivable: formatMoney(toDineroObject(5000)),
    //     lender_payable: formatMoney(toDineroObject(5000)),
    //     supplier_payable: formatMoney(toDineroObject(5000)),
    //     supplier_acknowledgment: "Yes",
    //   },
    //   {
    //     invoice: 1013,
    //     invoice_id: 104,
    //     customer: "Mega Enterprises",
    //     invoice_date: "18 February 2022",
    //     invoice_amount: formatMoney(toDineroObject(5000)),
    //     debit_amount: formatMoney(toDineroObject(5000)),
    //     credit_amount: formatMoney(toDineroObject(5000)),
    //     advance_amount: formatMoney(toDineroObject(5000)),
    //     net_receivable: formatMoney(toDineroObject(5000)),
    //     lender_payable: formatMoney(toDineroObject(5000)),
    //     supplier_payable: formatMoney(toDineroObject(5000)),
    //     supplier_acknowledgment: "Yes",
    //   },
    // ];
  };

  /**
   * @public
   * This method is called to signal our application that Anchor has updated it's ERP. It also sends a notification to bank detailing the same.
   */
  // const updateAnchorAdjustment = async (entryAdjusted: EntryAdjusted) => {
  //   const result = await axios.post<UpdateAnchorTier2InvoicesMappingResponse>(
  //     "/api/UpdateAnchorTier2InvoicesMapping",
  //     entryAdjusted
  //   );
  //   console.log(result.data);
  //   await PushNotification({
  //     notification: createNotification(
  //       result.data.anchorTier2InvoiceMapping.bankId,
  //       `${result.data.anchorTier2InvoiceMapping.anchorInvoice.anchor.actorInfo.name} has routed payment in favor of ${result.data.anchorTier2InvoiceMapping.bank.actorInfo.name} for loan number# ${result.data.anchorTier2InvoiceMapping.mappingInfo.loanId}`,
  //       result.data.anchorTier2InvoiceMapping.anchorInvoice.anchor.actorType,
  //       "Bank-Payment"
  //     ),
  //   });
  //   onGridReady();
  // };

  const showColumn = () => {
    console.log("gridColumnApi", gridColumnApi);
    gridColumnApi.setColumnsVisible(["invoice_amount", "debit_amount", "credit_amount", "advance_amount"], hideColumn);
    console.log("gridColumnApiDown", gridColumnApi);
    setHideColumn(!hideColumn);
  };

  const showColumn1 = () => {
    console.log("gridColumnApi", gridColumnApi1);
    gridColumnApi1.setColumnsVisible(
      ["supplier_invoice_amount", "supplier_debit_amount", "supplier_credit_amount", "supplier_advance"],
      hideColumn1
    );
    console.log("gridColumnApiDown", gridColumnApi1);
    setHideColumn1(!hideColumn1);
  };
  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Audit Trail", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <button onClick={showColumn} id="show" style={{ display: "none" }}>
        show
      </button>
      <button onClick={showColumn1} id="show1" style={{ display: "none" }}>
        show
      </button>
      <div className="tabs  is-toggle" style={{ marginLeft: "2vw" }}>
        <ul>
          <li className={`${pathParam === "Open" ? "is-active" : " "}`} onClick={() => toggle("Open")}>
            <a>
              <span>Open</span>
            </a>
          </li>
          <li className={`${pathParam === "Settled" ? "is-active" : " "}`} onClick={() => toggle("Settled")}>
            <a>
              <span>Settled</span>
            </a>
          </li>
        </ul>
      </div>
      {pathParam === "Open" && (
        <div style={containerStyle}>
          <div className="ag-theme-alpine mygrid" id="table-1" style={gridStyle}>
            {loaderOpen && <Loader />}
            <AgGridReact
              enableCellTextSelection={true}
              suppressMenuHide={true}
              defaultColDef={defaultColDef}
              frameworkComponents={frameworkComponents}
              onGridReady={onGridReadyOpen}
              rowData={getOpenRowData()}
              columnDefs={colDefns}
              domLayout="autoHeight"
              tooltipShowDelay={0}
              // tooltipHideDelay={2000}
              // components={components}
            />
          </div>
        </div>
      )}
      {pathParam === "Settled" && (
        <div className="ag-theme-alpine mygrid" id="table-1" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
          {loaderSettled && <Loader />}
          <AgGridReact
            enableCellTextSelection={true}
            suppressMenuHide={true}
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponents}
            onGridReady={onGridReadySettled}
            rowData={getSettledRowData()}
            columnDefs={settledColDefns}
            domLayout="autoHeight"
            // components={components}
          />
        </div>
      )}
      <div className="column" style={{ paddingLeft: "2vw" }}>
        <input
          className="button is-bold is-success theme_btn"
          type="button"
          value="Download"
          onClick={() => {
            // gridApi.exportDataAsCsv({ fileName: actor.name + "_Adjustment_" + pathParam });
          }}
        />
      </div>
    </LoggedInSkeleton>
  );
};

export default Tier1AuditTrail;
