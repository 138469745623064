import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router";
import { Bar, BarChart, Cell, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts";
// import invoiceAgingPng from "../../Graphics/invoiceAgingGraphicIcon.svg"
// import arrow from "../../Graphics/arrow.png";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
import "./AnchorLedgerDashboard.scss";
// import v from "../../Graphics/v.png"
const columnDefs = [
  { headerName: "Rank", field: "rank" },
  { headerName: "Supplier", field: "supplier" },
  {
    headerName: "Disputed Value(Rs Cr)",
    field: "disputed_value",
    cellStyle: (param: any) => {
      if (param.value > "14") return { color: "red" };
      return { color: "green" };
    },
  },
  { headerName: "# Disputes", field: "disputes" },
  { headerName: "Dispute resove TAT", field: "tat" },
];

const defaultColDef = {
  flex: 2,
  minWidth: 160,
  sortable: true,
  resizable: true,
  wrapText: true,
  filter: true,
  autoHeight: true,
  cellStyle: { color: "#4D4F5C" },
  tooltipComponent: "customTooltip",
  headerComponentParams: {
    template:
      '<div class="ag-cell-label-container" role="presentation">' +
      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
      '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
      '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
      "  </div>" +
      "</div>",
  },
};

const getrowdata = () => {
  return [
    {
      rank: 1,
      supplier: "Haritha",
      disputed_value: 40,
      disputes: 12,
      tat: 15,
    },
    {
      rank: 2,
      supplier: "Metalman",
      disputed_value: 22,
      disputes: 35,
      tat: 10,
    },
    {
      rank: 3,
      supplier: "Gabriel",
      disputed_value: 15,
      disputes: 7,
      tat: 22,
    },
    {
      rank: 4,
      supplier: "I-PEC",
      disputed_value: 14,
      disputes: 1,
      tat: 5,
    },
    {
      rank: 5,
      supplier: "MALHE",
      disputed_value: 8,
      disputes: 8,
      tat: 5,
    },
    {
      rank: 6,
      supplier: "Shriram P.",
      disputed_value: 5,
      disputes: 10,
      tat: 15,
    },
    {
      rank: 7,
      supplier: "Mega P.",
      disputed_value: 3,
      disputes: 8,
      tat: 25,
    },
  ];
};

const columnDefsForInvoiveHealth = [
  { headerName: "Rank", field: "rank", minWidth: 200 },
  { headerName: "Supplier", field: "supplier", minWidth: 200 },
  {
    headerName: "%debit (of invoice)",
    field: "debit_percentage",
    minWidth: 200,
    cellStyle: (param: any) => {
      if (param.value > "14") return { color: "red" };
      return { color: "green" };
    },
  },
  { headerName: "Debit value(Rs Cr)", field: "debit_value", minWidth: 200 },
  { headerName: "Invoice value (Rs Cr)", field: "invoice_value", minWidth: 200 },
];

const getrowdataForInvoiceHealth = () => {
  return [
    {
      rank: 1,
      supplier: "Bony P.",
      debit_percentage: 25,
      debit_value: 5.5,
      invoice_value: 22,
    },
    {
      rank: 2,
      supplier: "Jayshree",
      debit_percentage: 11,
      debit_value: 4.4,
      invoice_value: 40,
    },
    {
      rank: 3,
      supplier: "Gabriel",
      debit_percentage: 9,
      debit_value: 0.45,
      invoice_value: 5,
    },
    {
      rank: 4,
      supplier: "MALHE",
      debit_percentage: 3,
      debit_value: 0.45,
      invoice_value: 15,
    },
    {
      rank: 5,
      supplier: "Wintec",
      debit_percentage: 3,
      debit_value: 0.15,
      invoice_value: 5,
    },
    {
      rank: 6,
      supplier: "Mega P.",
      debit_percentage: 2,
      debit_value: 0.06,
      invoice_value: 3,
    },
    {
      rank: 7,
      supplier: "Shriram P.",
      debit_percentage: 2,
      debit_value: 0.06,
      invoice_value: 3,
    },
  ];
};
const reconAgingData = [
  {
    name: "<15 days",
    upValue: 250,
    value: 2500,
  },
  {
    name: "16-30 days",
    upValue: 180,
    value: 1800,
  },
  {
    name: "31-60 days",
    upValue: 50,
    value: 500,
  },
  {
    name: "61+days",
    upValue: 20,
    value: 200,
  },
];
const paymentOutflowData = [
  {
    name: "May",
    upValue: 30,
    supplier: 3000,
  },
  {
    name: "June",
    upValue: 50,
    supplier: 5000,
  },
  {
    name: "July",
    upValue: 60,
    supplier: 6000,
  },
  {
    name: "August+",
    upValue: 45,
    supplier: 4500,
  },
];
const buyerSupplierReconciliationData = [
  {
    name: "Buyer Account",
    upValue: 200,
    buyer: 2000,
    // downValue: 0,
    supplier: 0,
  },
  {
    name: "Mismatch",
    upValue: 20,
    buyer: 200,
    downValue: 200,
    supplier: 2000,
  },
  {
    name: "Missed",
    upValue: 50,
    buyer: 500,
    downValue: 220,
    supplier: 2200,
  },
  {
    name: "Extra",
    upValue: 30,
    buyer: 300,
    downValue: 240,
    supplier: 2400,
  },
  {
    name: "Supplier Account",
    upValue: 270,
    buyer: 2700,
    // downValue: 0,
    supplier: 0,
  },
];
const invoiceBreakupData = [
  {
    name: "Invoice",
    upValue: 200,
    uv: 2000,
    // downValue: 0,
    pv: 0,
  },
  {
    name: "Advance",
    upValue: 20,
    uv: 200,
    downValue: 180,
    pv: 1800,
  },
  {
    name: "Debit",
    upValue: 30,
    uv: 300,
    downValue: 150,
    pv: 1500,
  },
  {
    name: "Credit",
    upValue: 5,
    uv: 50,
    downValue: 150,
    pv: 1500,
  },
  {
    name: "Payable",
    upValue: 155,
    uv: 1550,
    // downValue: 0,
    pv: 0,
  },
];
const disputeResolutinTATData = [
  {
    name: "<10 days",
    upValue: 30,
    "Buyer pending": 3000,
    downValue: 20,
    "Supplier pending": 2000,
  },
  {
    name: "11-20 days",
    upValue: 20,
    downValue: 25,
    "Buyer pending": 2000,
    "Supplier pending": 2500,
  },
  {
    name: "21-30 days",
    upValue: 7,
    downValue: 10,
    "Buyer pending": 700,
    "Supplier pending": 1000,
  },
  {
    name: "31+days",
    upValue: 5,
    downValue: 12,
    "Buyer pending": 500,
    "Supplier pending": 1200,
  },
];
const invoiceAging = [
  {
    name: "<30 days",
    upValue: 30,
    "Payment Due": 300,
    // downValue: 3,
    Overdue: 30,
  },
  {
    name: "31-60 days",
    upValue: 50,
    downValue: 15,
    "Payment Due": 500,
    Overdue: 150,
  },
  {
    name: "61+ days",
    upValue: 60,
    downValue: 22,
    "Payment Due": 600,
    Overdue: 220,
  },
];
const invoiceAging2 = [
  {
    name: "Total",
    upValue: 140,
    downValue: 40,
    "Payment Due": 700,
    Overdue: 200,
  },
];
export const AnchorLeadershipDashboard = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Leadership Dashboard", actor.name, location?.state?.openCollapseOb, actor)}
    >
      <div className="mainContainer" style={{ marginTop: "35px" }}>
        <div className="container">
          <div className="childContainer bottomHeading">
            <div className="title">Buyer-Supplier Reconciliation (Rs. Lakhs)</div>
            <div className="subtitle">
              <span>
                <select name="supplier" id="supplier" className="dropdown">
                  <option value="I-PEC">I-PEC</option>
                  <option value="metalman">Metalman</option>
                  <option value="Gabriel">Gabriel</option>
                  <option value="All-Supplier">All Suppliers</option>
                </select>
              </span>
              {/* <span>TOTAL</span> */}
            </div>
            <ResponsiveContainer height={"70%"} width={"100%"}>
              <BarChart
                width={600}
                height={450}
                data={buyerSupplierReconciliationData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 15,
                }}
              >
                <XAxis dataKey="name" axisLine={false} tickLine={false} style={{ fill: "black" }} />
                <Tooltip
                  wrapperStyle={{ backgroundColor: "white" }}
                  labelStyle={{ color: "black" }}
                  itemStyle={{ color: "rgb(250, 92, 124)" }}
                  formatter={(value: any) => {
                    return `${value / 10}`;
                  }}
                  labelFormatter={(value: any) => {
                    return `${value}`;
                  }}
                />
                {/* <Legend style={{ display: "none" }} /> */}
                <Bar dataKey="supplier" stackId="a" fill="transparent" />
                <Bar dataKey="buyer" stackId="a" fill="#0acf97" radius={[10, 10, 0, 0]}>
                  {buyerSupplierReconciliationData.map((item, index) => {
                    if (item.name === "Mismatch" || item.name === "Missed") return <Cell key={index} fill="#0acf97" />;
                    if (item.name === "Extra") return <Cell key={index} fill="rgb(250, 92, 124)" />;
                    return <Cell key={index} fill="#0acf97" />;
                  })}
                  <LabelList dataKey="upValue" position="center" style={{ fill: "white" }} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="childContainer">
            <div className="title">Invoice Aging</div>
            <div className="subtitle">
              <span>
                <select name="supplier" id="supplier" className="dropdown">
                  <option value="I-PEC">I-PEC</option>
                  <option value="metalman">Metalman</option>
                  <option value="Gabriel">Gabriel</option>
                  <option value="All-Supplier">All Suppliers</option>
                </select>
              </span>
              {/* <span>TOTAL</span> */}
            </div>
            <div className="doubleGraph">
              <ResponsiveContainer height={"70%"} width={"60%"}>
                <BarChart
                  width={360}
                  height={225}
                  data={invoiceAging}
                  margin={{
                    top: 230,
                    right: 30,
                    left: 20,
                    bottom: 15,
                  }}
                >
                  <XAxis dataKey="name" axisLine={false} tickLine={false} style={{ fill: "black" }} />
                  <Tooltip
                    formatter={(value: any) => {
                      return `${value / 10}`;
                    }}
                  />
                  {/* <Legend /> */}
                  <Bar dataKey="Payment Due" stackId="a" fill="#0acf97">
                    <LabelList dataKey="upValue" position="center" style={{ fill: "white" }} />
                  </Bar>
                  <Bar dataKey="Overdue" stackId="a" fill="rgb(250, 92, 124)" radius={[10, 10, 0, 0]}>
                    <LabelList dataKey="downValue" position="center" style={{ fill: "white" }} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              {/* <div className="icon">
                <div className="veticalBar">
                  <img style={{ height: "300px", verticalAlign: "center" }} src={arrow} />
                </div>
                
              </div> */}
              <ResponsiveContainer height={"70%"} width={"20%"}>
                <BarChart width={120} height={450} data={invoiceAging2}>
                  <XAxis dataKey="name" axisLine={false} tickLine={false} style={{ fill: "black" }} />
                  <Tooltip
                    formatter={(value: any) => {
                      return `${value / 20}`;
                    }}
                  />
                  <Bar dataKey="Payment Due" stackId="a" fill="#0acf97">
                    <LabelList dataKey="upValue" position="center" style={{ fill: "white" }} />
                  </Bar>
                  <Bar dataKey="Overdue" stackId="a" fill="rgb(250, 92, 124)" radius={[10, 10, 0, 0]}>
                    <LabelList dataKey="downValue" position="center" style={{ fill: "white" }} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
      <div className="tableContainer">
        <div className="innerContainer">
          <div className="title"> Supplier-wise disputes</div>
          <div className="table">
            <div className="ag-theme-alpine mygrid" id="table-1">
              <AgGridReact
                enableCellTextSelection={true}
                suppressMenuHide={true}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                headerHeight={40}
                rowData={getrowdata()}
                domLayout="autoHeight"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mainContainer">
        <div className="container">
          <div className="childContainer">
            <div className="title">Reconciliation Aging</div>
            <div className="subtitle">
              <span>VALUE</span>
              {/* <span>*ACCOUNTS ON CLICK</span> */}
            </div>
            <ResponsiveContainer height={"70%"} width={"100%"}>
              <BarChart
                width={600}
                height={450}
                data={reconAgingData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 15,
                }}
              >
                <XAxis dataKey="name" axisLine={false} tickLine={false} style={{ fill: "black" }} />
                <Tooltip
                  formatter={(value: any) => {
                    return `${value / 10}`;
                  }}
                />
                {/* <Legend layout="vertical" /> */}
                <Bar dataKey="value" fill="#0acf97" radius={[10, 10, 0, 0]}>
                  <LabelList dataKey="upValue" position="center" style={{ fill: "white" }} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="childContainer">
            <div className="title">Dispute resolution TAT</div>
            <div className="subtitle">
              <span>
                <select name="supplier" id="supplier" className="dropdown">
                  <option value="I-PEC">I-PEC</option>
                  <option value="metalman">Metalman</option>
                  <option value="Gabriel">Gabriel</option>
                  <option value="All-Supplier">All Suppliers</option>
                </select>
              </span>
              {/* <span>TOTAL</span> */}
            </div>
            <ResponsiveContainer height={"70%"} width={"100%"}>
              <BarChart
                width={600}
                height={450}
                data={disputeResolutinTATData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 15,
                }}
              >
                <XAxis dataKey="name" axisLine={false} tickLine={false} style={{ fill: "black" }} />
                <Tooltip
                  formatter={(value: any) => {
                    return `${value / 100}`;
                  }}
                />
                <Legend />
                <Bar dataKey="Buyer pending" stackId="a" fill="#0acf97">
                  <LabelList dataKey="upValue" position="center" style={{ fill: "white" }} />
                </Bar>
                <Bar dataKey="Supplier pending" stackId="a" fill="rgb(250, 92, 124)" radius={[10, 10, 0, 0]}>
                  <LabelList dataKey="downValue" position="center" style={{ fill: "white" }} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="mainContainer">
        <div className="container">
          <div className="childContainer">
            <div className="title">Payment Outflow (Rs. Lakhs)</div>
            <div className="subtitle">
              <span>
                <select name="supplier" id="supplier" className="dropdown">
                  <option value="I-PEC">I-PEC</option>
                  <option value="metalman">Metalman</option>
                  <option value="Gabriel">Gabriel</option>
                  <option value="All-Supplier">All Suppliers</option>
                </select>
              </span>
              {/* <span>TOTAL</span> */}
            </div>
            <ResponsiveContainer height={"70%"} width={"100%"}>
              <BarChart
                width={600}
                height={450}
                data={paymentOutflowData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 15,
                }}
              >
                <XAxis dataKey="name" axisLine={false} tickLine={false} style={{ fill: "black" }} />
                <Tooltip
                  formatter={(value: any) => {
                    return `${value / 100}`;
                  }}
                />
                {/* <Legend layout="vertical" /> */}
                <Bar dataKey="supplier" fill="#0acf97" radius={[10, 10, 0, 0]}>
                  <LabelList dataKey="upValue" position="center" style={{ fill: "white" }} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="childContainer">
            <div className="title">Invoice Breakup (Rs. Lakhs)</div>
            <div className="subtitle">
              <span>
                <select name="supplier" id="supplier" className="dropdown">
                  <option value="I-PEC">I-PEC</option>
                  <option value="metalman">Metalman</option>
                  <option value="Gabriel">Gabriel</option>
                  <option value="All-Supplier">All Suppliers</option>
                </select>
              </span>
              {/* <span>TOTAL</span> */}
            </div>
            <ResponsiveContainer height={"70%"} width={"100%"}>
              <BarChart
                width={600}
                height={450}
                data={invoiceBreakupData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 15,
                }}
              >
                <XAxis dataKey="name" axisLine={false} tickLine={false} style={{ fill: "black" }} />
                <Tooltip
                  formatter={(value: any) => {
                    return `${value / 10}`;
                  }}
                />
                <Bar dataKey="pv" stackId="a" fill="transparent">
                  {/* <LabelList dataKey="downValue" position="center" style={{ fill: "white" }} /> */}
                </Bar>
                <Bar dataKey="uv" stackId="a" fill="#0acf97" radius={[10, 10, 0, 0]}>
                  {invoiceBreakupData.map((item, index) => {
                    if (item.name === "Credit") return <Cell key={index} fill="rgb(250, 92, 124" />;
                    return <Cell key={index} fill="#0acf97" />;
                  })}
                  <LabelList dataKey="upValue" position="center" style={{ fill: "white" }} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="tableContainer">
        <div className="innerContainer">
          <div className="title">Supplier Health</div>
          <div className="table">
            <div className="ag-theme-alpine mygrid" id="table-1" style={gridStyle}>
              <AgGridReact
                enableCellTextSelection={true}
                suppressMenuHide={true}
                columnDefs={columnDefsForInvoiveHealth}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                headerHeight={40}
                rowData={getrowdataForInvoiceHealth()}
                domLayout="autoHeight"
              />
            </div>
          </div>
        </div>
      </div>
    </LoggedInSkeleton>
  );
};
