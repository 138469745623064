import moment from "moment";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import DatePicker from "react-datepicker";

const CustomEditorComponent = forwardRef(function CustomEditorComponent(props: any, ref) {
  const refDatePicker = useRef();
  const [date, setDate] = useState(moment(props.value, "DD/MM/YYYY").toDate());
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
  }, [editing, props]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return moment(date).format("DD/MM/YYYY");
      },
    };
  });

  const onChange = (selectedDate: any) => {
    setDate(selectedDate);
    setEditing(false);
  };

  return (
    <div>
      <DatePicker
        ref={refDatePicker}
        portalId="root"
        popperClassName="ag-custom-component-popup"
        selected={date}
        dateFormat="dd/MM/yyyy"
        onChange={onChange}
      />
    </div>
  );
});

export default CustomEditorComponent;
