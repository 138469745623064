import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router";
import { ActorMappings, Tier1Tier2Mapping } from "../../entity/ActorMappings";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
import { BtnCellRenderer } from "./BtnCellRenderer";
import CustomEditorComponent from "./DateCellEditor";
import "./Settings.scss";
// import moment from "moment";
// import { dateValueFormatter } from "../../Utils/DateUtils";
// const CustomDateComponent = forwardRef((props: any, ref) => {
//   const [date, setDate] = useState(null);
//   //const [picker, setPicker] = useState(null);
//   const refDatePicker = useRef();
//   const refInput = useRef();

//   const onDateChanged = (selectedDate: any) => {
//     setDate(selectedDate);
//     props.onDateChanged();
//   };

//   useImperativeHandle(ref, () => ({
//     getDate() {
//       return date;
//     },

//     setDate(date: any) {
//       setDate(date);
//     }
//   }));

//   return (
//     <div ref={refInput} className="ag-input-wrapper">
//       <DatePicker
//         ref={refDatePicker}
//         portalId="root"
//         dateFormat="dd/MM/yyyy"
//         popperClassName="ag-custom-component-popup"
//         selected={date}
//         onChange={onDateChanged}
//       />
//     </div>
//   );
// });

export interface ListAllActorMappingsResponse {
  actorMappings: ActorMappings[];
}

export interface Tier1BlockData {
  tier2Id: string;
  stopDays: number;
  suspend: any;
}

function updateBlockDays(tier1SettingsData: Tier1BlockData) {
  // const newStopDays = prompt("Please Enter the number of days you would want to extend the block");
  console.log(`block called for this tier2 ${tier1SettingsData.tier2Id} changin from these 
    days ${tier1SettingsData.stopDays} to new number of days `);
  document.getElementById("modal").style.display = "flex";
}

export const Tier1Settings = () => {
  const location = useLocation<any>();
  // const [startDate, setStartDate] = useState(new Date());
  // const [suspend, setSuspend] = useState(true);
  const [suspend, setSuspend] = useState([
    { id: 0, suspend1: true },
    { id: 1, suspend1: true },
  ]);

  const [startDate, setStartDate] = useState([
    { id: 0, startDate1: new Date() },
    { id: 1, startDate1: new Date() },
  ]);

  const [currentRow, setCurrentRow] = useState(0);

  // const [close, setClose] = useState(false)

  const { actor } = useContext(userContext);
  const [mappingData, setMappingData] = useState({
    actorMappings: [],
  } as ListAllActorMappingsResponse);
  const columnDefs = [
    { headerName: "Supplier ID", field: "supplier_id", minWidth: 200 },
    { headerName: "Supplier", field: "supplier", minWidth: 200 },
    {
      headerName: "Invoice Buffer (Days)",
      field: "invoice_buffer",
      minWidth: 200,
    },
    {
      headerName: "Payment Term (Days)",
      field: "payment_terms",
      minWidth: 200,
    },

    // { headerName: "Due Date Buffer (Days)", field: "due_date_buffer", minWidth: 200 },
    {
      headerName: "Suspend Supplier",
      field: "suspended_supplier",
      cellRenderer: "btnCellRenderer",
      // cellRenderer: "btnCellRenderer1",
      cellClass: "left-cell",
      cellRendererParams: {
        clicked(tier1SettingsData: Tier1BlockData, rowIndex: number, btnType: string) {
          setCurrentRow(rowIndex);
          console.log("btnType", btnType);
          updateBlockDays(tier1SettingsData);
          console.log("Block Days Updated", tier1SettingsData);
        },
        suspend,
      },
      minWidth: 200,
    },
    {
      headerName: "Suspended Days Remaining",
      field: "remaining_days_blocked",
      minWidth: 200,
    },
    {
      headerName: "Suspended Till (Date)",
      field: "last_date_till_suspended",
      minWidth: 200,
      editable: true,
      cellEditor: "customEditor",
    },
  ];

  const frameworkComponents = {
    btnCellRenderer: BtnCellRenderer,
    customEditor: CustomEditorComponent,
    // btnCellRenderer1: CustomCellRender,
  };

  const defaultColDef = {
    flex: 1,
    minWidth: 200,
    sortable: true,
    cellStyle: { color: "#4D4F5C" },
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  };

  const onGridReady = async () => {
    const { data } = await axios.get<ListAllActorMappingsResponse>("/api/ListAllActorMappings", {
      params: {
        parentId: actor.id,
      },
    });
    console.log("mappingData", data);
    setMappingData(data);
  };

  const getrowdata = () => {
    return mappingData.actorMappings.map((mdata, index) => {
      console.log("date,currentRow", currentRow, startDate[currentRow].startDate1.toLocaleDateString());
      return {
        supplier_id: mdata.child.actorId, // confirm this one
        supplier: mdata.child.actorInfo.name,
        payment_terms: (mdata.mappingData as Tier1Tier2Mapping).invoiceToPaymentGapInDays,
        invoice_buffer: (mdata.mappingData as Tier1Tier2Mapping).discountingBufferDays,
        // due_date_buffer: (mdata.mappingData as Tier1Tier2Mapping).paymentBufferDays,
        suspended_supplier: {
          tier2Id: mdata.childId,
          stopDays: 0,
          suspend,
        },
        remaining_days_blocked: diffDays(new Date(), startDate[index].startDate1),
        last_date_till_suspended: startDate[index].startDate1.toLocaleDateString(),
      };
    });
  };
  /*useEffect(() => {
    async function mappings() {
      const { data } = await axios.get<ListAllActorMappingsResponse>("/api/ListAllActorMappings");
      console.log(data);
      setMappingData(data.actorMappings);
    }
    mappings();
    console.log(mappingData);
  }, []);
   const tier1Settings = () => {
    return (
      <div className="container">
        <div className="columns" style={{ width: "100%" }}>
          <div className="column is-one-third bold">Supplier</div>
          <div className="column bold">
            Invoice Buffer <br />
            (days)
          </div>
          <div className="column bold">
            Due Date Buffer <br /> (days)
          </div>
          <div className="column bold">
            Payment Terms <br /> (days)
          </div>
        </div>
        {mappingData &&
          mappingData.actorMappings.map((mdata) => (
            <div key={mdata.childId} className="columns margin" style={{ width: "100%" }}>
              <div className="column is-one-third background-blue">{mdata.child.actorInfo.name}</div>
              <div className="column background-white">
                {(mdata.mappingData as Tier1Tier2Mapping).discountingBufferDays}
              </div>
              <div className="column background-white">
                {(mdata.mappingData as Tier1Tier2Mapping).paymentBufferDays}
              </div>
              <div className="column background-white"></div>
            </div>
          ))}
      </div>
    );
  };*/

  const closemodal = () => {
    document.getElementById("modal").style.display = "none";
  };

  const confirmHandler = () => {
    console.log("currentRow", currentRow);

    setSuspend(
      suspend.map((item: any) => {
        if (item.id === currentRow) {
          item.suspend1 = false;
          return item;
        } else {
          return item;
        }
      })
    );

    console.log("suspend12", suspend);
  };

  const closeHandler = () => {
    // setStartDate(new Date());
    setStartDate(
      startDate.map((item: any) => {
        if (item.id === currentRow) {
          item.startDate1 = new Date();
          return item;
        } else {
          return item;
        }
      })
    );
  };

  const confirmEnableHandler = () => {
    // setStartDate(new Date());
    // setSuspend(suspend);
    setStartDate(
      startDate.map((item: any) => {
        if (item.id === currentRow) {
          item.startDate1 = new Date();
          return item;
        } else {
          return item;
        }
      })
    );
    setSuspend(
      suspend.map((item: any) => {
        if (item.id === currentRow) {
          item.suspend1 = true;
          return item;
        } else {
          return item;
        }
      })
    );
  };

  const diffDays = (date: any, otherDate: any) => {
    return Math.floor(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
  };

  const changeDateHandler = (date: any) => {
    setStartDate(
      startDate.map((item: any) => {
        if (item.id === currentRow) {
          item.startDate1 = date;
          // console.log("currentRowdate",currentRow)
          console.log("currentRowIndexDate", currentRow, date);
          return item;
        } else {
          return item;
        }
      })
    );
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Settings", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <div className="ag-theme-alpine mygrid">
        {mappingData.actorMappings.length === 0 && <Loader />}
        <AgGridReact
          enableCellTextSelection={true}
          frameworkComponents={frameworkComponents}
          suppressMenuHide={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowData={getrowdata()}
          domLayout="autoHeight"
          popupParent={document.body}
        />
      </div>
      {/* {
        suspend.map((data, index) =>(
          data.suspend1 ?
          <div className="modal" id="modal" key={index}>
          <div className="modal-background" />
          <div className="modal-card" style={{ height: "70%" }}>
            <header className="modal-card-head">
              <p className="modal-card-title">
                Choose the date till you want to <br /> suspend the supplier
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  closemodal();
                  closeHandler();
                }}
              />
            </header>
            <section className="modal-card-body">
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-success"
                onClick={() => {
                  closemodal();
                  confirmHandler();
                }}
              >
                Confirm
              </button>
             
            </footer>
          </div>
        </div> : 
        <div className="modal" id="modal" key={index}>
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                Are you sure you want to enable <br /> discounting for this Supplier
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  closemodal();
                }}
              />
            </header>
            
            <footer className="modal-card-foot">
              <button
                className="button is-success"
                onClick={() => {
                  closemodal();
                  confirmEnableHandler();
                }}
              >
                Confirm
              </button>
             
            </footer>
          </div>
        </div>
        ))
      } */}
      {suspend[currentRow].suspend1 ? (
        <div className="modal" id="modal">
          <div className="modal-background" />
          <div className="modal-card" style={{ height: "470px", width: "470px" }}>
            <header className="modal-card-head">
              <p className="modal-card-title">
                Choose the date till you want to <br /> suspend the supplier
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  closemodal();
                  closeHandler();
                }}
              />
            </header>
            <section className="modal-card-body" style={{ paddingLeft: "120px" }}>
              <div>
                <DatePicker
                  selected={startDate[currentRow].startDate1}
                  // onChange={(date: Date) => setStartDate(date)}
                  onChange={(date: any) => changeDateHandler(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-success"
                onClick={() => {
                  closemodal();
                  confirmHandler();
                }}
              >
                Confirm
              </button>
            </footer>
          </div>
        </div>
      ) : (
        <div className="modal" id="modal">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                Are you sure you want to enable <br /> discounting for this Supplier
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  closemodal();
                }}
              />
            </header>

            <footer className="modal-card-foot">
              <button
                className="button is-success"
                onClick={() => {
                  closemodal();
                  confirmEnableHandler();
                }}
              >
                Confirm
              </button>
            </footer>
          </div>
        </div>
      )}
    </LoggedInSkeleton>
  );
};
