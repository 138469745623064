import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";

const Tier2Account = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [form, setForm] = useState({
    accountname: "",
    accountnum: "",
    confirmaccountnum: "",
    ifsccode: "",
    bankname: "",
    branchname: "",
  });
  const [uploading, setUploading] = useState(false);
  const update = (event: React.ChangeEvent<HTMLInputElement>) =>
    setForm({ ...form, [event.target.name]: event.target.value });

  const uploaddetail = (event: any) => {
    event.preventDefault();
    setUploading(true);
    // const { accountname , accountnum } = event.target.elements;
    history.push("/tier2/early");
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Account", actor.name, location?.state?.openCollapseOb, actor)}
    >
      <div className="container">
        <p className="title has-text-info tier-2-head">Tier 2 Account details</p>
        <div className="tier-2-login">
          <div className="column is-4 is-offset-4">
            <form onSubmit={uploaddetail}>
              <div className="field">
                <div className="control">
                  <label className="label">Account Name</label>
                  <input
                    className="input"
                    type="text"
                    name="accountname"
                    placeholder="Account Holder Name"
                    value={form.accountname}
                    onChange={update}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="label">Account Number</label>
                  <input
                    className="input"
                    type="password"
                    name="accountnum"
                    placeholder="Bank Account Number"
                    value={form.accountnum}
                    onChange={update}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="label">Confirm Account Number</label>
                  <input
                    className="input"
                    type="password"
                    name="confirmaccountnum"
                    placeholder="Confirm Account Number"
                    value={form.confirmaccountnum}
                    onChange={update}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="label">IFSC Code</label>
                  <input
                    className="input"
                    type="text"
                    name="ifsccode"
                    placeholder="Bank IFSC code"
                    value={form.ifsccode}
                    onChange={update}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="label">Bank Name</label>
                  <input
                    className="input"
                    type="text"
                    name="bankname"
                    placeholder="Bank Name"
                    value={form.bankname}
                    onChange={update}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="label">Branch Name</label>
                  <input
                    className="input"
                    type="text"
                    name="branchname"
                    placeholder="Branch Name"
                    value={form.branchname}
                    onChange={update}
                    required={true}
                  />
                </div>
                <div className="field is-grouped is-grouped-centered">
                  <div className="buttons">
                    <button
                      className="is-info  button "
                      disabled={uploading ? true : false}
                      style={{
                        backgroundColor: uploading ? "#808080" : "#4072e3",
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoggedInSkeleton>
  );
};

export default Tier2Account;
