import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Loan } from "../../entity/Loan";
// import { BankLoan } from "../../../../src/routes/listInvoicesForBankApproval";

interface BtnCellRendererProps {
  clicked: (arg0: Loan) => void;
  value: Loan;
  pendingLoans: any;
  rowIndex: number;
}

class BankCollectionDetailsButtonCellRenderer extends Component<BtnCellRendererProps> {
  constructor(props: BtnCellRendererProps) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }

  public btnClickedHandler() {
    console.log("PROPS", this.props);
    console.log(this.props.pendingLoans[this.props.rowIndex].loanId);
    // if (Object.keys(this.state.imageSource).length === 0) {
    //   await this.getImage(this.props.value.tier2InvoiceDetails);
    //   console.log("Image Source", this.state.imageSource);
    // }

    // this.props.clicked(this.props.value, this.props.rowIndex, this.state.imageSource, this.props.data, this.props.feild, this.props.invoicesAvailableForDiscounting);
    // this.props.clicked(this.props);
  }

  render() {
    console.log("props", this.props);
    return (
      <button className="button is-accept">
        <Link
          to={{
            // pathname: "/bank/disbursement/" + this.props.value.tier1Tier2InvoiceMapping.tier2InvoiceId,
            // pathname: "/bank/disbursement/" + this.props.pendingLoans[this.props.rowIndex].loanId
            pathname: "/bank/collection/" + this.props.value,
            // tier2inId ==> loanId
          }}
          style={{ color: "white" }}
        >
          More Details
        </Link>
      </button>
    );
  }
}

export default BankCollectionDetailsButtonCellRenderer;
