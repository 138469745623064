import { Alert } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

const TopFixed: React.CSSProperties = {
  position: "fixed",
  right: 0,
  width: "460px",
  zIndex: 11010,
};

interface ApiErrorProps {
  showUploadSuccessAlert?: boolean;
  showUploadErrorAlert?: boolean;
  showUploadWarningAlert?: boolean;
  apiErrorMsg: string;
}

const WRAPPER_ID = "alert-portal-wrapper";

const ApiErrorCatch = (props: ApiErrorProps) => {
  const TopPos = 20;

  const a = {
    success: false,
    error: false,
    warn: false,
  };

  if (props.showUploadSuccessAlert) a.success = true;
  if (props.showUploadErrorAlert) a.error = true;
  if (props.showUploadWarningAlert) a.warn = true;

  let trueCount = Object.values(a).filter((v) => v).length;

  const getTopPos = (num = 1) => {
    if (num < 1) num = 1;
    else trueCount = trueCount - 1;

    return num * 60 + TopPos;
  };

  return (
    <ReactPortal wrapperId={WRAPPER_ID}>
      {props.showUploadSuccessAlert ? (
        <Alert severity="success" className="fade_up" style={{ ...TopFixed, top: getTopPos(trueCount) }}>
          {props.apiErrorMsg}
        </Alert>
      ) : null}
      {props.showUploadErrorAlert ? (
        <Alert severity="error" className="fade_up" style={{ ...TopFixed, top: getTopPos(trueCount) }}>
          {props.apiErrorMsg}
        </Alert>
      ) : null}
      {props.showUploadWarningAlert ? (
        <Alert severity="warning" className="fade_up" style={{ ...TopFixed, top: getTopPos(trueCount) }}>
          {props.apiErrorMsg}
        </Alert>
      ) : null}
    </ReactPortal>
  );
};

function createWrapperAndAppendToBody(wrapperId) {
  const wrapperElement = document.createElement("div");
  wrapperElement.id = wrapperId;
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

function ReactPortal({ children, wrapperId = WRAPPER_ID }) {
  const [wrapperElement, setWrapperElement] = useState(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let wrapperCreated = false;

    // create and append to body
    if (!element) {
      wrapperCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      // delete on unMount
      if (wrapperCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
}

export default ApiErrorCatch;
