import { ColDef } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { DineroObject } from "dinero.js";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { DiscountableTier2InvoiceInfo } from "../../entity/models/UtilityInterfaces";
import { Tier1Tier2InvoiceMapping } from "../../entity/Tier1Tier2InvoiceMapping";
import { Tier2Invoice } from "../../entity/Tier2Invoice";
import { dateValueFormatter } from "../../Utils/DateUtils";
import { formatMoney } from "../../Utils/MoneyUtils";
import * as MoneyUtil from "../../Utils/MoneyUtils";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
import BtnCellRenderer from "./BtnCellRenderer";
import CustomTooltip from "./CustomTooltip";
// import "./Tier1EarlyPayment.scss";

export interface UpdateTier2InvoicesForDiscountingResponse {
  tier1Tier2InvoiceMapping: Tier1Tier2InvoiceMapping;
}

export interface ListTier2InvoicesForDiscountingResponse {
  discountableTier2InvoiceList: DiscountableTier2InvoiceInfo[];
  nonDiscountableTier2InvoiceList: Tier2Invoice[];
}

export interface ImageSourceFiles {
  InvoiceFile?: string;
  GrnFile?: string;
  DebitNotes?: string[];
}

export interface Tier1InvoiceBreakupData {
  InvoiceAmount: number;
  DebitNote: number;
  Advance: number;
  NetRecievable: number;
}
/**
 * @Component
 * This component renders the tier1 early payment screen. It displays all invoices of a Tier1 on which it can take an early payment.
 */
const Tier2EarlyPayment = () => {
  const location = useLocation<any>();
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [hideColumn, setHideColumn] = useState(true);
  const [plus, setPlus] = useState(true);

  // const [tier2actiondata, setTier2actiondata] = useState({
  //   invoices: [],
  // } as ListTier2InvoicesResponse);
  const [invoicesAvailableForDiscounting, setInvoicesAvailableForDiscounting] = useState(
    [] as DiscountableTier2InvoiceInfo[]
  );
  const [discountedtier2invoice, setDiscountedTier2Invoice] = useState({} as DiscountableTier2InvoiceInfo);
  useEffect(() => {
    const colElements = Array.from(document.getElementsByClassName("ag-header-cell") as HTMLCollectionOf<HTMLElement>);

    console.log("ele", colElements);
    // console.log("hideColumn useffect",hideColumn)
  }, []);
  // const [visible, setVisible] = useState<visibleTab>("invoice");
  // const [signedSrc, setSignedSrc] = useState<ImageSourceFiles>({});
  // const [uploading, setUploading] = useState(false);
  // const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { actor } = useContext(userContext);
  // const updateremark = (event: React.ChangeEvent<HTMLTextAreaElement>) => setRemark(event.target.value);

  // const [tier1InvoiceBreakupData, setTier1InvoiceBreakupData] = useState<Tier1InvoiceBreakupData>();

  // const [invoicetoupdate, setInvoicetoupdate] = useState<Tier2Invoice>();
  const [loader, setLoader] = useState(true);

  /**
   * This is the column definition for the screen.
   */

  // invoice:
  // vendor:
  // invoice_date:
  // invoice_amount:
  // net_amount:
  // early_payment:
  // discount:
  // action:
  // const onDoubleClickNetRecivables = (event: CellDoubleClickedEvent) => {
  //   console.log("the cell is double clicked", event.colDef, event.data);
  //   const invoiceData: Tier1InvoiceBreakupData = {
  //     Advance: 3000,
  //     InvoiceAmount: 3000,
  //     DebitNote: 3000,
  //     NetRecievable: 3000,
  //   };
  //   setTier1InvoiceBreakupData(invoiceData);
  //   document.getElementById("modal").style.display = "flex";
  // };

  // const onDoubleClickEarlyPayment = (event: CellDoubleClickedEvent) => {
  //   console.log("the cell is double clicked", event.colDef, event.data);
  //   console.log("the cell is double clicked", event.colDef, event.data);
  //   const invoiceData: Tier1InvoiceBreakupData = {
  //     Advance: 3000,
  //     InvoiceAmount: 3000,
  //     DebitNote: 3000,
  //     NetRecievable: 3000,
  //   };
  //   setTier1InvoiceBreakupData(invoiceData);
  //   document.getElementById("modal").style.display = "flex";
  // };
  const columnDefs: any[] = [
    // { headerName: "Invoice ID", field: "invoice", minWidth: 180 },
    { headerName: "Invoice Number", field: "invoice_num", minWidth: 180 },
    { headerName: "Customer", field: "customer", minWidth: 180 },
    { headerName: "Invoice Date", field: "invoice_date", valueFormatter: dateValueFormatter, minWidth: 180 },
    // { headerName: "Receivable date", field: "receivable_date", valueFormatter: dateValueFormatter, minWidth: 200 },
    {
      headerName: "Net Receivable Breakup",
      children: [
        {
          headerName: "Invoice Amount (I)",
          field: "invoice_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "invoice_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Advance (A)",
          field: "advance_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "advance_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Debit Note (D)",
          field: "debit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "debit_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },
        {
          headerName: "Credit Note (C)",
          field: "credit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "credit_amount",
          minWidth: 180,
          // hide:true,
          columnGroupShow: "open",
        },

        {
          headerName: "Net Receivable (N)",
          field: "net_amount",
          cellStyle: { color: "#48AC23" },
          minWidth: 200,
          headerComponentParams: { plus, setPlus },
          // onCellDoubleClicked: onDoubleClickNetRecivables,
          tooltipField: "net_amount",
        },
      ],
    },

    {
      headerName: "Early Payment ",
      field: "early_payment",
      cellStyle: { color: "#4072E3" },
      minWidth: 180,
      // onCellDoubleClicked: onDoubleClickEarlyPayment,
    },
    {
      headerName: "Discount",
      field: "discount",
      cellStyle: { color: "#4072E3" },
      minWidth: 180,
    },
    // {
    //   headerName: "Payment Pending With Customer",
    //   field: "payment_pending_with_customer",
    //   cellStyle: { color: "#4072E3" },
    //   minWidth: 180,
    // },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "btnCellRenderer",
      // headerClass: "grid-header-centered",
      // cellClass: "center-cell",
      cellRendererParams: {
        async clicked(field: string, rowIndex: number) {
          console.log("Row Index", rowIndex);
          console.log("field", field);
          // console.log(tier2Invoice, imageSource, field);
          confirmearlypayment(rowIndex);
          // setSignedSrc(imageSource);
          // setInvoicetoupdate(tier2Invoice);
          // setOpenDialog(true);
          document.getElementById("modal").style.display = "flex";
          setLoader(false);
        },
        invoicesAvailableForDiscounting,
      },
      minWidth: 200,
    },
  ];

  const columnDefsModal: ColDef[] = [
    {
      headerName: "Loan Amount",
      field: "loan_payment",
      cellStyle: { color: "#4072E3" },
      minWidth: 150,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Early Payment Amount",
      field: "early_payment",
      cellStyle: { color: "#4072E3" },
      minWidth: 180,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Discount",
      field: "discount",
      cellStyle: { color: "#4072E3" },
      minWidth: 100,
      sortable: false,
      filter: false,
    },

    {
      headerName: "Interest Rate",
      field: "interest",
      cellStyle: { color: "#4072E3" },
      minWidth: 100,
      sortable: false,
      filter: false,
    },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 180,
    sortable: true,
    cellStyle: { color: "#4D4F5C" },
    // enableRowGroup: true,
    // enablePivot: true,
    // enableValue: true,
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    tooltipComponent: "customTooltip",
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };
  /**
   * This function renders the UI of the "Details" button at the right most column of the table. A click on this button will pop up a modal summarising all the details pertaining to that invoice. It also has an option to Approve or Decline.
   */
  const frameworkComponents = {
    btnCellRenderer: BtnCellRenderer,
    // agColumnHeader: CustomHeader,
    customTooltip: CustomTooltip,
  };
  /**
   * This method is called to fetch all the invoices which are in pending state. This method run as soon as the screen is loaded.
   */
  const onGridReady = async (params: any) => {
    await axios
      .get<ListTier2InvoicesForDiscountingResponse>("/api/ListTier2InvoicesForDiscounting")
      .then((response) => {
        // setTier2actiondata(response.data);
        console.log("response", response);
        setInvoicesAvailableForDiscounting(response.data.discountableTier2InvoiceList);
        // setNonDiscountedApprovedInvoices(response.data.nonDiscountableTier1InvoiceList);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
    setGridColumnApi(params.columnApi);
  };

  const closemodal = () => {
    document.getElementById("modal").style.display = "none";
  };

  // const columnDefsModalNetRecievable: ColDef[] = [
  //   { headerName: "Invoice Amount", field: "invoice", minWidth: 100 },
  //   { headerName: "Debit Note", field: "debit_note", minWidth: 100 },
  //   { headerName: "Advance", field: "advance", minWidth: 100 },
  //   { headerName: "NetRecievable", field: "net_recievable", minWidth: 100 },
  // ];
  // const closemodal = () => {
  //   setUploading(false);
  //   setOpenDialog(false);
  // };
  /**
   * This method sieve out the required field from the API response of pending state invoices.
   */

  const checkInterest = (val: any) => {
    if (val === null || val === undefined) {
      return "NA";
    } else {
      return val.toFixed(2) + "%";
    }
  };

  const getModalrowdata = () => {
    return [
      {
        loan_payment: formatMoney(discountedtier2invoice.earlyPaymentAmount),
        early_payment: formatMoney(discountedtier2invoice.loanAmount),
        discount: formatMoney(
          MoneyUtil.subtractMoney(discountedtier2invoice.loanAmount, discountedtier2invoice.earlyPaymentAmount)
        ),
        interest: checkInterest(discountedtier2invoice.bankAnchorInterestRatePercentage),
      },
    ];
  };

  const getrowdata = () => {
    return invoicesAvailableForDiscounting.map((inv) => {
      const totalDebitAmount = (data: any) => {
        // let amount = 0;
        // console.log("data", data)
        if (data.length === 0) {
          return {
            amount: 0,
            currency: "INR",
            precision: 2,
          };
        } else {
          const total = data
            .map((item: any) => item.debitAmount)
            .reduce((prev: DineroObject, curr: DineroObject) => MoneyUtil.addMoney(prev, curr));
          // console.log("total", total)
          return total;
        }
      };
      const totalCreditAmount = (data: any) => {
        // let amount = 0;
        if (data.length === 0) {
          return {
            amount: 0,
            currency: "INR",
            precision: 2,
          };
        } else {
          const total = data
            .map((item: any) => item.creditAmount)
            .reduce((prev: DineroObject, curr: DineroObject) => MoneyUtil.addMoney(prev, curr));
          // console.log("total", total)
          return total;
        }
      };
      const totalAdvanceAmount = (data: any) => {
        // let amount = 0;
        if (data.length === 0) {
          return {
            amount: 0,
            currency: "INR",
            precision: 2,
          };
        } else {
          const total = data
            .map((item: any) => item.advanceAmount)
            .reduce((prev: DineroObject, curr: DineroObject) => MoneyUtil.addMoney(prev, curr));
          // console.log("total", total)
          return total;
        }
      };

      return {
        invoice_num: inv.tier2Invoice.invoiceId,
        // customer: inv.tier2Invoice.tier1.actorInfo.name,
        customer: "Mega Enterprises",
        invoice_date: inv.tier2Invoice.invoiceDate,
        invoice_amount: formatMoney(inv.tier2Invoice.invoiceAmount),
        debit_amount: formatMoney(totalDebitAmount(inv.tier2Invoice.tier2DebitNotes)),
        credit_amount: formatMoney(totalCreditAmount(inv.tier2Invoice.tier2CreditNotes)),
        advance_amount: formatMoney(totalAdvanceAmount(inv.tier2Invoice.tier2Advances)),
        net_amount: formatMoney(
          MoneyUtil.addMoney(
            MoneyUtil.subtractMoney(inv.tier2Invoice.invoiceAmount, totalDebitAmount(inv.tier2Invoice.tier2DebitNotes)),
            MoneyUtil.subtractMoney(
              totalCreditAmount(inv.tier2Invoice.tier2CreditNotes),
              totalAdvanceAmount(inv.tier2Invoice.tier2Advances)
            )
          )
        ),
        early_payment: formatMoney(inv.earlyPaymentAmount),
        discount: formatMoney(MoneyUtil.subtractMoney(inv.loanAmount, inv.earlyPaymentAmount)),
        payment_pending_with_customer: formatMoney(
          MoneyUtil.subtractMoney(
            MoneyUtil.addMoney(
              MoneyUtil.subtractMoney(
                inv.tier2Invoice.invoiceAmount,
                totalDebitAmount(inv.tier2Invoice.tier2DebitNotes)
              ),
              MoneyUtil.subtractMoney(
                totalCreditAmount(inv.tier2Invoice.tier2CreditNotes),
                totalAdvanceAmount(inv.tier2Invoice.tier2Advances)
              )
            ),
            inv.loanAmount
          )
        ),

        // payable_date: inv.dueDate,
        // invoice_amount: formatMoney(inv.invoiceAmount),
        // payable_amount: formatMoney(inv.receivableAmount),
        // details: inv,
      };
    });

    // return tier2actiondata.invoices.map((inv) => {
    //   return {
    //     invoice: inv.invoiceId,
    //     vendor: inv.tier2.actorInfo.name,
    //     invoice_date: inv.invoiceDate,
    //     payable_date: inv.dueDate,
    //     invoice_amount: formatMoney(inv.invoiceAmount),
    //     payable_amount: formatMoney(inv.receivableAmount),
    //     details: inv,
    //   };
    // });
    // -----//
    // return [
    //   {
    //     invoice: 101105,
    //     customer: "Ather",
    //     invoice_date: "21 February 2022",
    //     invoice_amount: formatMoney(toDineroObject(2000000)),
    //     net_amount: formatMoney(toDineroObject(1400000)),
    //     early_payment: formatMoney(toDineroObject(1300000)),
    //     discount: formatMoney(toDineroObject(100000)),
    //     action: {},
    //   },
    //   {
    //     invoice: 100123,
    //     customer: "Ather",
    //     invoice_date: "22 February 2022",
    //     invoice_amount: formatMoney(toDineroObject(2000000)),
    //     net_amount: formatMoney(toDineroObject(1400000)),
    //     early_payment: formatMoney(toDineroObject(1300000)),
    //     discount: formatMoney(toDineroObject(100000)),
    //     action: {},
    //   },
    // ];
  };

  const confirmearlypayment = (rowIndex: number) => {
    // const discontedtier1 = availableForDiscounting?.find((element) => {
    //   console.log("element", element)
    //   console.log("invoiceIdToUpdate", invoiceIdToUpdate)
    //    return element.tier1Invoice.invoiceId === invoiceIdToUpdate;
    //  });

    const discontedtier2 = invoicesAvailableForDiscounting[rowIndex];

    //  discontedtier2.status = "Discounted";
    console.log("DiscountedTier2", discontedtier2);
    setDiscountedTier2Invoice(discontedtier2);
    console.log("discontedtier2", discontedtier2);
  };

  const updateDiscountedTier2Invoice = () => {
    //  await axios.post<UpdateTier1InvoicesForDiscountingResponse>("/api/UpdateTier1InvoicesForDiscounting").then(
    //    (response) => {
    //     console.log("post response", response)
    //    }
    //  ).catch((error) => {
    //     console.log("post error", error.response.data)
    //  })
    document.getElementById("modal").style.display = "none";
    callapi(discountedtier2invoice);
  };

  const callapi = async (discontedtier2: DiscountableTier2InvoiceInfo) => {
    await axios
      .post<UpdateTier2InvoicesForDiscountingResponse>("/api/UpdateTier2InvoiceForDiscounting", discontedtier2)
      .then((response) => {
        console.log("Response", response);
        // onGridReady();
        window.location.reload();
        //    document.getElementById("modal").style.display = "none";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showColumn = () => {
    console.log("gridColumnApi", gridColumnApi);
    console.log("hidecolumn", hideColumn);
    gridColumnApi.setColumnsVisible(["invoice_amount", "debit_amount", "credit_amount", "advance_amount"], hideColumn);
    console.log("gridColumnApiDown", gridColumnApi);
    console.log("here clicked");
    setHideColumn(!hideColumn);
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Early Payment", actor.name, location?.state?.openCollapseOb, actor)}
    >
      <button onClick={showColumn} id="show" style={{ display: "none" }}>
        show
      </button>
      <div>
        <div className="ag-theme-alpine mygrid" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
          {loader && <Loader />}
          <AgGridReact
            enableCellTextSelection={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponents}
            onGridReady={onGridReady}
            rowData={getrowdata()}
            domLayout="autoHeight"
            debug={true}
            // rowClassRules={{
            //   highlight(params) {
            //     return params.data.invoice === "KEINV1234";
            //   },
            // }}
          />
        </div>

        <div className="modal" id="modal">
          <div className="modal-background" onClick={closemodal} />{" "}
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                Are you sure want to take early <br /> payment on this invoice ?
              </p>
              <button className="delete" aria-label="close" onClick={closemodal} />
            </header>
            <section className="modal-card-body">
              {/* <p>
                Are you sure want to take early payment on this invoice ?
              </p> */}

              <AgGridReact
                enableCellTextSelection={true}
                suppressMenuHide={true}
                columnDefs={columnDefsModal}
                defaultColDef={defaultColDef}
                // frameworkComponents={frameworkComponents}
                // onGridReady={onModalGridReady}

                rowData={getModalrowdata()}
                domLayout="autoHeight"
                // rowClassRules={{
                //   highlight(params) {
                //     return params.data.invoice === "KEINV1234";
                //   },
                // }}
              />
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success" onClick={updateDiscountedTier2Invoice}>
                Confirm
              </button>
              <button className="button is-danger" onClick={closemodal}>
                Decline
              </button>
            </footer>
          </div>
        </div>

        {/* <div className="modal" id="modal">
          <div className="modal-background" onClick={closemodal} />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Breakup of recievable</p>
              <button className="delete" aria-label="close" onClick={closemodal} />
            </header>
            <section className="modal-card-body">
              <p>Please view the detailed breakup of Net Recievable in the table below asd</p>
              {tier1InvoiceBreakupData && (
                <div className="columns">
                  <div className="column" style={{ padding: 20 }}>
                    <tr>
                    <th>
                      Invoice Amount
                    </th>
                    <th>
                      Debit Note
                    </th>
                    <th>
                      Advance
                    </th>
                    <th>
                      Net Recievable
                    </th>
                  </tr>
                  <tr>
                    <td>{tier1InvoiceBreakupData.InvoiceAmount}</td>
                    <td>{tier1InvoiceBreakupData.DebitNote}</td>
                    <td>{tier1InvoiceBreakupData.Advance}</td>
                    <td>{tier1InvoiceBreakupData.NetRecievable}</td>
                  </tr>
                    <AgGridReact
                      enableCellTextSelection={true}
                      suppressMenuHide={true}
                      columnDefs={columnDefsModalNetRecievable}
                      defaultColDef={defaultColDef}
                      frameworkComponents={frameworkComponents}
                      onGridReady={onGridReady}
                      rowData={getRowDataForNetRecievableModal()}
                      domLayout="autoHeight"
                      rowClassRules={{
                        highlight(params) {
                          return params.data.invoice === "KEINV1234";
                        },
                      }}
                    />
                    <p>Advance : {tier1InvoiceBreakupData.Advance}</p>
                  <p>Debit Note : {tier1InvoiceBreakupData.DebitNote}</p>
                  <p>Invoice Amount : {tier1InvoiceBreakupData.InvoiceAmount}</p>
                  </div>
                </div>
              )}
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success" onClick={tallyadjustmentdone}>
              Confirm
            </button>
              <button className="button is-success" onClick={closemodal}>
                Close
              </button>
            </footer>
          </div>
        </div> */}
        {/* <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <header className="modal-card-head">
            <p className="modal-card-title">More Details</p>
            <button className="delete" onClick={closemodal} aria-label="close" />
          </header>
          <section className="modal-card-body">
            {invoicetoupdate && (
              <div className="columns">
                <div className="column">
                  <p>Customer : {invoicetoupdate.tier1.actorInfo.name}</p>
                  <p>Invoice Number : {invoicetoupdate.invoiceId}</p>
                  <p>Invoice Amount : {formatMoney(invoicetoupdate.invoiceAmount)}</p>
                </div>
                <div className="column">
                  <p>Receivable Amount : {formatMoney(invoicetoupdate.receivableAmount)}</p>
                  <p>Invoice Date : {formatDate(invoicetoupdate.invoiceDate)}</p>
                  <p>Payment Date : {formatDate(invoicetoupdate.dueDate)}</p>
                </div>
              </div>
            )}
            <div className="tabs is-toggle" style={{ marginLeft: "0vw", paddingTop: "20px" }}>
              <ul>
                <li
                  className={`${visible === "invoice" ? "is-active" : " "}`}
                  onClick={() => setVisible("invoice")}
                  id="tab-1"
                >
                  <a>
                    <span>Invoice</span>
                  </a>
                </li>
                <li className={`${visible === "grn" ? "is-active" : " "}`} onClick={() => setVisible("grn")} id="tab-2">
                  <a>
                    <span>GRN</span>
                  </a>
                </li>
                <li
                  className={`${visible === "debitNote" ? "is-active" : " "}`}
                  id="tab-3"
                  onClick={() => setVisible("debitNote")}
                >
                  <a>
                    <span>Debit Note</span>
                  </a>
                </li>
              </ul>
            </div>
            {invoicetoupdate && visible === "invoice" && (
              <p className="image is-4by3" id="invoice">
                <img
                  src={signedSrc.InvoiceFile !== undefined && signedSrc.InvoiceFile}
                  alt=""
                  crossOrigin="anonymous"
                />
              </p>
            )}
            {invoicetoupdate && visible === "grn" && (
              <p className="image is-4by3" id="grn">
                <img src={signedSrc.GrnFile !== undefined && signedSrc.GrnFile} alt="" crossOrigin="anonymous" />
              </p>
            )}

            {invoicetoupdate && visible === "debitNote" && (
              <div className="row " id="debitNotes" style={{ maxHeight: "55%", overflow: "auto" }}>
                {invoicetoupdate.tier2InvoiceDetails.debitNotes.map((note, index) => (
                  <div className="column">
                    <img
                      key={note.debitNoteFile}
                      src={signedSrc.DebitNotes.length !== 0 && signedSrc.DebitNotes[index]}
                      alt=""
                      crossOrigin="anonymous"
                      style={{ width: "100%" }}
                    />
                  </div>
                ))}
              </div>
            )}

            <div className="field">
              <div className="control">
                <label className="label">Remark</label>
                <textarea
                  className="input"
                  name="remark"
                  placeholder="Write your remark here(if any)"
                  style={{ height: "80px" }}
                  value={remark}
                  onChange={updateremark}
                />
              </div>
            </div>

            <footer className="modal-card-foot">
              <button
                className="button is-success"
                onClick={async () => {
                  setUploading(true);
                  await changestatus("Approved");
                  setUploading(false);
                }}
                disabled={uploading ? true : false}
              >
                Approve
              </button>
              <button
                className="button is-danger"
                onClick={async () => {
                  setUploading(true);
                  await changestatus("Rejected");
                  setUploading(false);
                }}
                disabled={uploading ? true : false}
              >
                Decline
              </button>
            </footer>
          </section>
        </Dialog> */}
      </div>
    </LoggedInSkeleton>
  );
};

export default Tier2EarlyPayment;

// --------------------- old file -------------------------//

// import { ColDef } from "ag-grid-community";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import { AgGridReact } from "ag-grid-react";
// import axios from "axios";
// import React, { useContext, useEffect, useState } from "react";
// import { useHistory, useParams } from "react-router";
// import { Tier2Invoice } from "../../../../src/database/entity/Tier2Invoice";
// import { DiscountedTier2Invoice } from "../../../../src/models/DiscountedTier2Invoice";
// import { ListTier2InvoicesResponse } from "../../../../src/routes/listTier2Invoices";
// import { ListTier2InvoicesForDiscountingResponse } from "../../../../src/routes/listTier2InvoicesForDiscounting";
// import { UpdateTier2InvoicesForDiscountingResponse } from "../../../../src/routes/updateTier2InvoicesForDiscounting";
// import { dateValueFormatter } from "../../Utils/DateUtils";
// import * as MoneyUtil from "../../Utils/MoneyUtils";
// import Loader from "../Common/Loader";
// import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
// import { createNotification, PushNotification } from "../Common/Notification/PushNotification";
// import { getTier2TopBarButtons } from "../Common/TopNavBar";
// import { userContext } from "../Contexts/userContext";
// import "./../Tier1DataUpdate/Tier1DataUpdate.scss";
// import BtnCellRenderer from "./BtnCellRenderer";
// import BtnCellRenderer2 from "./BtnCellRenderer2";
// import BtnCellRenderer3 from "./BtnCellRenderer3";
// import BtnCellRenderer4 from "./BtnCellRenderer4";
// import "./Tier2EarlyPayment.scss";
// /**
//  * @Component
//  * This component renders the early payment pipeline screens of Tier2. It constitutes four sub- tabs classifying various tier2 invoices by the state they are in our system. Four sub-tabs are Pending with Customer, Approved by Customer, Rejected by Bank, Rejected by Customer. All the sub-tab expect the second one contains one table which are populated by all the invoices concerning that state. The Approved by Customer sub-tab contains two tables - 1. Approved Invoices available for discounting and 2. Approved Invoices but not discountable right now.
//  */
// const Tier2EarlyPayment = () => {
//   const [invoicesAvailableForDiscounting, setInvoicesAvailableForDiscounting] = useState(
//     [] as DiscountedTier2Invoice[]
//   );
//   const [nonDiscountedApprovedInvoices, setNonDiscountedApprovedInvoices] = useState([] as Tier2Invoice[]);
//   const [rejectedbytier1, setRejectedbytier1] = useState([] as Tier2Invoice[]);
//   const [pendingbytier1, setPendingbytier1] = useState([] as Tier2Invoice[]);
//   const [discountedtier2invoice, setDiscountedTier2Invoice] = useState({} as DiscountedTier2Invoice);
//   const [rejectedByBank, setRejectedByBank] = useState<
//     {
//       tier2Invoice: Tier2Invoice;
//     }[]
//   >([]);
//   const [loader, setLoader] = useState({
//     1: true,
//     2: true,
//     3: true,
//     4: true,
//   });
//   const { actor } = useContext(userContext);
//   const { pathParam } = useParams<{ pathParam?: string }>();
//   const DEFAULT_ACTIVE_TAB = "Pending";
//   const history = useHistory();
//   const pathWay = "/tier2/early";
//   useEffect(() => {
//     console.log(pathParam);
//     if (!pathParam) {
//       history.push(`${pathWay}/${DEFAULT_ACTIVE_TAB}`);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);
//   const toggle = (activeTab: string) => {
//     if (pathParam !== activeTab) {
//       history.push(`${pathWay}/${activeTab}`);
//     }
//   };
//   /**
//    * This is the column definition for first sub-tab i.e Pending with Customers.
//    */
//   const columnDefs1: ColDef[] = [
//     { headerName: "Invoice Number", field: "invoice", minWidth: 200 },
//     { headerName: "Customer", field: "customer", minWidth: 200 },
//     {
//       headerName: "Invoice Date",
//       field: "invoice_date",
//       valueFormatter: dateValueFormatter,
//       minWidth: 200,
//     },
//     {
//       headerName: "Invoice Amount",
//       field: "invoice_amount",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Advance",
//       field: "advance",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Debit Note",
//       field: "debit_note",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Credit Note",
//       field: "credit_note",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Net receivable",
//       field: "receivable_amount",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Early Payment",
//       field: "early_payment",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Discount",
//       field: "discount",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Action",
//       field: "action",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },
//     // {
//     //   headerName: "Loan Amount",
//     //   field: "loan_amount",
//     //   headerTooltip: "Net Receivable amount multiplied by ltv ratio",
//     //   cellStyle: { color: "#48AC23" },
//     //   minWidth: 200,
//     // },
//     // {
//     //   headerName: "Receivable date",
//     //   field: "receivable_date",
//     //   valueFormatter: dateValueFormatter,
//     //   minWidth: 200,
//     // },
//     // {
//     //   headerName: "Send reminder",
//     //   field: "customerId",
//     //   cellRenderer: "btnCellRenderer3",
//     //   headerClass: "grid-header-centered",
//     //   cellClass: "center-cell",
//     //   cellRendererParams: {
//     //     actor: {
//     //       actorId: actor.id,
//     //       actorType: "Tier2",
//     //       actorInfo: {
//     //         name: actor.name,
//     //       },
//     //     },
//     //   },
//     //   minWidth: 200,
//     // },
//     // {
//     //   headerName: "Cancel request",
//     //   field: "customerId",
//     //   cellRenderer: "btnCellRenderer4",
//     //   cellClass: "center-cell",
//     //   headerClass: "grid-header-centered",
//     //   cellRendererParams: {
//     //     clicked(field: any) {
//     //       console.log("Field", field);

//     //       document.getElementById("modal2").style.display = "flex";
//     //     },
//     //   },
//     //   minWidth: 200,
//     // },
//   ];
//   /**
//    * This is the column header defintion of first table of second sub-tab i.e Approved by Customer.
//    */
//   const approvedWithDiscountingColumnDefs: ColDef[] = [
//     { headerName: "Invoice number", field: "invoice", minWidth: 200 },
//     { headerName: "Customer", field: "customer", minWidth: 200 },
//     {
//       headerName: "Invoice Amount",
//       field: "invoice_amount",
//       minWidth: 200,
//     },
//     {
//       headerName: "Payment Date",
//       field: "payment_date",
//       valueFormatter: dateValueFormatter,
//       minWidth: 200,
//     },
//     {
//       headerName: "Net Receivable",
//       field: "receivable_amount",
//       minWidth: 200,
//     },
//     {
//       headerName: "Early Payment Amount",
//       field: "payment_amount",
//       headerTooltip: "amount which is to be credited due to early payment",
//       cellStyle: { color: "#4072E3" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Early Payment Discount ",
//       field: "payment_discount",
//       headerTooltip: "amount which is deducted due to early payment",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Amount Pending with customers ",
//       field: "pending_amount",
//       headerTooltip: "Difference between receivable amount and loan amount",
//       cellStyle: { color: "#48AC23" },
//       minWidth: 200,
//     },

//     {
//       headerName: "Take early payment",
//       field: "invoice",
//       cellRenderer: "btnCellRenderer1",
//       headerClass: "grid-header-centered",
//       cellStyle: { textAlign: "center" },
//       cellRendererParams: {
//         clicked(field: string, availableForDiscounting: DiscountedTier2Invoice[]) {
//           confirmearlypayment(field, availableForDiscounting);
//           document.getElementById("modal").style.display = "flex";
//         },
//         invoicesAvailableForDiscounting,
//       },
//       minWidth: 200,
//     },
//   ];
//   /**
//    * This is the column header definition for the second table in Approved by Customer sub-tab.
//    */
//   const nonDiscountedApprovedInvoicesColumnDefs: ColDef[] = [
//     { headerName: "Invoice number", field: "invoice", minWidth: 200 },
//     { headerName: "Customer", field: "customer", minWidth: 200 },
//     {
//       headerName: "Invoice Amount",
//       field: "invoice_amount",
//       minWidth: 200,
//     },
//     {
//       headerName: "Payment Date",
//       field: "payment_date",
//       suppressSizeToFit: true,
//       valueFormatter: dateValueFormatter,
//       minWidth: 200,
//     },
//     {
//       headerName: "Net Receivable",
//       field: "receivable_amount",
//       minWidth: 200,
//     },
//     // {
//     //   headerName: "CheckBox",
//     //   field: "checkBox",
//     //   checkboxSelection: true,
//     // },
//   ];
//   /**
//    * This is the column definition for the third sub-tab i.e Rejected by Bank.
//    */
//   const columnDefs3: ColDef[] = [
//     { headerName: "Invoice number", field: "invoice", minWidth: 200 },
//     { headerName: "Customer", field: "customer", minWidth: 200 },
//     {
//       headerName: "Invoice amount",
//       field: "invoice_amount",
//       cellStyle: { color: "#4072E3" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Remark",
//       field: "remark",
//       cellClass: "remark-class",
//       tooltipField: "remark",
//       minWidth: 200,
//     },
//     {
//       headerName: "Reupload invoice",
//       field: "reupload",
//       cellRenderer: "btnCellRenderer2",
//       minWidth: 200,
//     },
//   ];
//   /**
//    * This is the column definition for the fourth sub-tab i.e Rejected by Customer.
//    */
//   const columnDefs4: ColDef[] = [
//     { headerName: "Invoice number", field: "invoice", minWidth: 200 },
//     { headerName: "Customer", field: "customer", minWidth: 200 },
//     { headerName: "Bank", field: "bank", minWidth: 200 },
//     {
//       headerName: "Invoice amount",
//       field: "invoice_amount",
//       cellStyle: { color: "#4072E3" },
//       minWidth: 200,
//     },
//     {
//       headerName: "Remark",
//       field: "remark",
//       cellClass: "remark-class",
//       tooltipField: "remark",
//       minWidth: 200,
//     },
//     {
//       headerName: "Reupload invoice",
//       field: "reupload",
//       cellRenderer: "btnCellRenderer2",
//       minWidth: 200,
//     },
//   ];

//   const defaultColDef: ColDef = {
//     flex: 1,
//     sortable: true,
//     resizable: true,
//     wrapText: true,
//     filter: true,
//     minWidth: 200,
//     autoHeight: true,
//     cellStyle: { color: "#4D4F5C" },
//     headerComponentParams: {
//       template:
//         '<div class="ag-cell-label-container" role="presentation">' +
//         '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
//         '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
//         '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
//         '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
//         '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
//         '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
//         '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
//         '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
//         "  </div>" +
//         "</div>",
//     },
//   };
//   /**
//    * This function renders the "send reminder" and "cancel" button on the UI of first sub-tab of the screen. These buttons are used to send a notifying reminder or cancel any invoice from the mix respectively.
//    */
//   const frameworkComponents1 = {
//     btnCellRenderer3: BtnCellRenderer3,
//     btnCellRenderer4: BtnCellRenderer4,
//   };
//   /**
//    * This function renders the UI of the "Accept" button at the second sub-tab of the screen. By clicking on this button user is claiming an early payment on it's invoice.
//    */
//   const frameworkComponents2 = {
//     btnCellRenderer1: BtnCellRenderer,
//   };
//   /**
//    * This function render UI for "Reupload" on third and fourth sub-tab.
//    */
//   const frameworkComponents3 = {
//     btnCellRenderer2: BtnCellRenderer2,
//   };
//   /**
//    * This method requests to fetch all the invoices in the pending state. It is called on the first sub-tab of the screen.
//    */
//   const onGridReady1 = () => {
//     axios
//       .get<ListTier2InvoicesResponse>("/api/ListTier2Invoices", {
//         params: {
//           approvalStatus: "Pending",
//         },
//       })
//       .then((response) => {
//         console.log(response.data);
//         setPendingbytier1(response.data.invoices);
//         setLoader({ ...loader, 1: false });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
//   /**
//    * This method requests to fetch all the invoices in the Approved state. It is called on the second sub-tab of the screen. The response is divided into two set of array which have in them discountable and non-discountable invoices for the two tables on this sub-tab.
//    */
//   const onGridReady2 = () => {
//     axios
//       .get<ListTier2InvoicesForDiscountingResponse>("/api/ListTier2InvoicesForDiscounting") // TODO(Priyanshu)
//       .then((response) => {
//         console.log(response.data);
//         setInvoicesAvailableForDiscounting(response.data.discountedTier2Invoices);
//         setNonDiscountedApprovedInvoices(response.data.nonDiscountedApprovedInvoices);
//         setLoader({ ...loader, 2: false });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
//   /**
//    * This method request all the invoices in the rejected state. Rejected here means it is refused by customer.
//    */
//   const onGridReady3 = () => {
//     axios
//       .get<ListTier2InvoicesResponse>("/api/ListTier2Invoices", {
//         params: {
//           approvalStatus: "Rejected",
//         },
//       })
//       .then((response) => {
//         setRejectedbytier1(response.data.invoices);
//         setLoader({ ...loader, 3: false });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
//   /**
//    * This method request all the invoices which are rejected by bank.
//    */
//   const onGridReady4 = () => {
//     const bankRejectedInvoices: {
//       tier2Invoice: Tier2Invoice;
//     }[] = [];
//     axios
//       .get<ListTier2InvoicesResponse>("/api/ListTier2Invoices")
//       .then((response) => {
//         console.log(response.data.invoices);
//         const rejectedInvoices = response.data.invoices.filter((inv) => inv.isRejected === true);
//         console.log(rejectedInvoices);
//         for (const rejected of rejectedInvoices) {
//           bankRejectedInvoices.push({
//             tier2Invoice: rejected,
//           });
//         }
//         console.log(bankRejectedInvoices.length, bankRejectedInvoices[0]);
//         setRejectedByBank(bankRejectedInvoices);
//         setLoader({ ...loader, 4: false });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
//   /**
//    * This method sieve out the required field from the API response for the first sub-tab i.e pending invoices.
//    */
//   const getpendingbytier1data = () => {
//     return pendingbytier1.map((inv) => {
//       return {
//         invoice: inv.invoiceId,
//         customer: inv.tier1.actorInfo.name,
//         customerId: inv.tier1Id,
//         receivable_amount: MoneyUtil.formatMoney(inv.receivableAmount),
//         loan_amount: MoneyUtil.formatMoney(inv.loanAmount),
//         invoice_date: inv.invoiceDate,
//         receivable_date: inv.dueDate,
//         payee: inv.tier1.actorInfo.name,
//       };
//     });
//   };
//   /**
//    * This method sieve out the required field from the API response for the second sub-tab i.e Approved invoices. Here, discountable approved invoices are being handled.
//    */
//   const getApprovedWithDiscountingRowData = () => {
//     return invoicesAvailableForDiscounting.map((inv) => {
//       return {
//         invoice: inv.tier2Invoice.invoiceId,
//         customer: inv.tier2Invoice.tier1.actorInfo.name,
//         payment_date: inv.tier2Invoice.dueDate,
//         payment_discount: MoneyUtil.formatMoney(
//           MoneyUtil.subtractMoney(inv.tier2Invoice.loanAmount, inv.discountedAmount)
//         ),
//         pending_amount: MoneyUtil.formatMoney(
//           MoneyUtil.subtractMoney(inv.tier2Invoice.receivableAmount, inv.tier2Invoice.loanAmount)
//         ),
//         invoice_amount: MoneyUtil.formatMoney(inv.tier2Invoice.invoiceAmount),
//         receivable_amount: MoneyUtil.formatMoney(inv.tier2Invoice.receivableAmount),
//         payment_amount: MoneyUtil.formatMoney(inv.discountedAmount),
//       };
//     });
//   };
//   /**
//    * This method sieve out the required field from the API response for the second sub-tab i.e Approved invoices. Here, non-discountable approved invoices are being handled.
//    */
//   const getNonDiscountedApprovedInvoicesRowData = () => {
//     return nonDiscountedApprovedInvoices.map((inv) => {
//       return {
//         invoice: inv.invoiceId,
//         customer: inv.tier1.actorInfo.name,
//         payment_date: inv.dueDate,
//         invoice_amount: MoneyUtil.formatMoney(inv.invoiceAmount),
//         receivable_amount: MoneyUtil.formatMoney(inv.receivableAmount),
//       };
//     });
//   };
//   /**
//    * This method is called when the Tier2 claim early payment by clicking the button "Accept". It requests the discounting API and send a notification to bank.
//    */
//   const confirmearlypayment = async (invoiceIdToUpdate: string, availableForDiscounting: DiscountedTier2Invoice[]) => {
//     const discontedtier2 = availableForDiscounting.find((element) => {
//       return element.tier2Invoice.invoiceId === invoiceIdToUpdate;
//     });

//     discontedtier2.status = "Discounted";
//     console.log("DiscountedTier2", discontedtier2);
//     setDiscountedTier2Invoice(discontedtier2);
//   };
//   const updateDiscountedTier2Invoice = () => {
//     console.log(discountedtier2invoice);
//     document.getElementById("modal").style.display = "none";
//     callapi(discountedtier2invoice);
//   };

//   const callapi = async (discontedtier2: DiscountedTier2Invoice) => {
//     axios
//       .post<UpdateTier2InvoicesForDiscountingResponse>("/api/UpdateTier2InvoiceForDiscounting", discontedtier2)
//       .then((response) => {
//         console.log("Response", response);
//         onGridReady2();
//         //    document.getElementById("modal").style.display = "none";
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//     await PushNotification({
//       notification: createNotification(
//         7, // hard coded bank id
//         `request of ${MoneyUtil.formatMoney(discontedtier2.discountedAmount)} raised against ${
//           discontedtier2.partAnchorInvoices[0].anchorInvoice.anchor.actorInfo.name
//         }'s invoice ${discontedtier2.partAnchorInvoices[0].anchorInvoice.invoiceId} by ${actor.name}`,
//         discontedtier2.tier2Invoice.tier2.actorType,
//         "Early-Payment-Request"
//       ),
//     });
//   };
//   /**
//    * This method sieve out the required field from the API response for the third sub-tab i.e Rejected by customer invoices.
//    */
//   const getrejectedrowdata = () => {
//     return rejectedbytier1.map((inv) => {
//       return {
//         invoice: inv.invoiceId,
//         customer: inv.tier1.actorInfo.name,
//         invoice_amount: MoneyUtil.formatMoney(inv.invoiceAmount),
//         remark: inv.tier2InvoiceDetails.remark,
//       };
//     });
//   };
//   /**
//    * This method sieve out the required field from the API response for the fourth sub-tab i.e Rejected by bank invoices.
//    */
//   const getBankRejectedRowData = () => {
//     console.log(rejectedByBank);
//     return rejectedByBank.map((inv) => {
//       return {
//         invoice: inv.tier2Invoice.invoiceId,
//         customer: inv.tier2Invoice.tier1.actorInfo.name,
//         bank: "ICICI", // bank name is hardcoded
//         invoice_amount: MoneyUtil.formatMoney(inv.tier2Invoice.invoiceAmount),
//         remark: inv.tier2Invoice.remarks,
//       };
//     });
//   };
//   const closemodal = () => {
//     document.getElementById("modal").style.display = "none";
//   };
//   const rowSelectionType = "single";
//   const onSelectionChanged = (event: any) => {
//     console.log(event.api.getSelectedRows());
//   };
//   const closemodal2 = () => {
//     document.getElementById("modal").style.display = "none";
//   };
//   return (
//     <LoggedInSkeleton topBarButtons={getTier2TopBarButtons("Early Payment")}>
//       <div className="tabs is-toggle" style={{ marginLeft: "2vw" }}>
//         <ul>
//           <li
//             className={`${pathParam === "Pending" ? "is-active" : " "}`}
//             onClick={() => {
//               toggle("Pending");
//             }}
//             id="tab-1"
//           >
//             <a>
//               <span>Pending with Customer</span>
//             </a>
//           </li>
//           <li
//             className={`${pathParam === "Approved" ? "is-active" : " "}`}
//             onClick={() => toggle("Approved")}
//             id="tab-2"
//           >
//             <a>
//               <span> Approved by Customer</span>
//             </a>
//           </li>
//           <li
//             className={`${pathParam === "Rejected" ? "is-active" : " "}`}
//             onClick={() => toggle("Rejected")}
//             id="tab-3"
//           >
//             <a>
//               <span>Rejected by Customer</span>
//             </a>
//           </li>
//           <li
//             className={`${pathParam === "RejectedByBank" ? "is-active" : " "}`}
//             onClick={() => toggle("RejectedByBank")}
//             id="tab-4"
//           >
//             <a>
//               <span>Rejected by Bank</span>
//             </a>
//           </li>
//         </ul>
//       </div>
//       {pathParam === "Pending" && (
//         <div className="ag-theme-alpine mygrid" id="table-1" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
//           {loader[1] && <Loader />}
//           <AgGridReact
//             enableCellTextSelection={true}
//             suppressMenuHide={true}
//             columnDefs={columnDefs1}
//             defaultColDef={defaultColDef}
//             frameworkComponents={frameworkComponents1}
//             onGridReady={onGridReady1}
//             rowData={getpendingbytier1data()}
//             rowSelection={rowSelectionType}
//             domLayout="autoHeight"
//             enableBrowserTooltips={true}
//           />
//         </div>
//       )}

//       <div className="modal" id="modal2">
//         <div className="modal-background" onClick={closemodal} />
//         <div className="modal-card">
//           <header className="modal-card-head">
//             <p className="modal-card-title">Confirmation</p>
//             <button className="delete" aria-label="close" onClick={closemodal} />
//           </header>
//           <section className="modal-card-body">
//             <p>Are you sure you want to cancel request of early payment?</p>
//           </section>
//           <footer className="modal-card-foot">
//             <button className="button is-success" onClick={closemodal}>
//               Confirm
//             </button>{" "}
//             //TODO(Priyanshu ) what to do set status when he cancel the request for early payment
//             <button className="button is-danger" onClick={closemodal}>
//               Cancel
//             </button>
//           </footer>
//         </div>
//       </div>

//       {/* <div className="table-info has-background-info invoice-approved" >Invoices approved by tier 1</div> */}
//       {pathParam === "Approved" && (
//         <div id="table-2">
//           {loader[2] && <Loader />}
//           <div className="ag-theme-alpine mygrid" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
//             <h5 className="title is-5">Approved</h5>
//             <AgGridReact
//               enableCellTextSelection={true}
//               columnDefs={approvedWithDiscountingColumnDefs}
//               suppressMenuHide={true}
//               defaultColDef={defaultColDef}
//               frameworkComponents={frameworkComponents2}
//               onGridReady={onGridReady2}
//               rowData={getApprovedWithDiscountingRowData()}
//               domLayout="autoHeight"
//               enableBrowserTooltips={true}
//             />

//             <div className="dont-like">
//               <p>Don’t like the discounting rates? Check back again later</p>
//             </div>
//             <div>
//               <h5 className="title is-5">Approved but not available for discounting</h5>
//               <AgGridReact
//                 enableCellTextSelection={true}
//                 suppressMenuHide={true}
//                 columnDefs={nonDiscountedApprovedInvoicesColumnDefs}
//                 defaultColDef={defaultColDef}
//                 frameworkComponents={frameworkComponents2}
//                 rowData={getNonDiscountedApprovedInvoicesRowData()}
//                 rowSelection={rowSelectionType}
//                 onSelectionChanged={onSelectionChanged}
//                 domLayout="autoHeight"
//                 enableBrowserTooltips={true}
//               />
//             </div>
//           </div>
//           {/* <div className="table-info has-background-info invoice-approved" >Invoices rejected by tier 1</div> */}
//         </div>
//       )}

//       <div className="modal" id="modal">
//         <div className="modal-background" onClick={closemodal2} />
//         <div className="modal-card">
//           <header className="modal-card-head">
//             <p className="modal-card-title">Confirmation</p>
//             <button className="delete" aria-label="close" onClick={closemodal2} />
//           </header>
//           <section className="modal-card-body">
//             <p>Are you sure you want to take early payment on this invoice ?</p>
//           </section>
//           <footer className="modal-card-foot">
//             <button className="button is-success" onClick={updateDiscountedTier2Invoice}>
//               Confirm
//             </button>
//             <button className="button is-danger" onClick={closemodal}>
//               Decline
//             </button>
//           </footer>
//         </div>
//       </div>
//       {pathParam === "Rejected" && (
//         <div className="ag-theme-alpine mygrid" id="table-3" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
//           {loader[3] && <Loader />}
//           <AgGridReact
//             enableCellTextSelection={true}
//             suppressMenuHide={true}
//             columnDefs={columnDefs3}
//             defaultColDef={defaultColDef}
//             frameworkComponents={frameworkComponents3}
//             enableBrowserTooltips={true}
//             onGridReady={onGridReady3}
//             rowData={getrejectedrowdata()}
//             domLayout="autoHeight"
//           />
//         </div>
//       )}
//       {pathParam === "RejectedByBank" && (
//         // tslint:disable-next-line: prettier
//         <div
//           className="ag-theme-alpine mygrid"
//           id="table-3"
//           style={{ paddingRight: "2vw", paddingLeft: "2vw" }}
//         >
//           {loader[4] && <Loader />}
//           <AgGridReact
//             enableCellTextSelection={true}
//             suppressMenuHide={true}
//             columnDefs={columnDefs4}
//             defaultColDef={defaultColDef}
//             frameworkComponents={frameworkComponents3}
//             enableBrowserTooltips={true}
//             onGridReady={onGridReady4}
//             rowData={getBankRejectedRowData()}
//             domLayout="autoHeight"
//           />
//         </div>
//       )}
//     </LoggedInSkeleton>
//   );
// };

// export default Tier2EarlyPayment;
