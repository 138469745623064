import React, { useEffect, useState } from "react";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// import { FrontendActor } from "../../src/models/FrontendActor";
// import Anchor from "./Components/Anchor/Anchor";
import AnchorAuditTrail from "./Components/AnchorAuditTrail/AnchorAuditTrail";
import { AnchorLeadershipDashboard } from "./Components/AnchorLeadershipDashboard/AnchorLeadershipDashboard";
// import { AnchorEarlyPayment } from "./Components/AnchorEarlyPayment/AnchorEarlyPayment";
import Axios from "axios";
import packageJson from "../package.json";

import CollectionAccount from "./Components/Admin/CollectionAccount";

import DisbursmentAccount from "./Components/Admin/DisbursmentAccount";

import AnchorLedgerAdjustment from "./Components/AnchorLedgerAdjustment/AnchorLedgerAdjustment";
import AnchorPayables from "./Components/AnchorPayables/AnchorPayables";
// import ReconMapping from "./Components/Admin/ReconMapping";
import Register from "./Components/Admin/Register";

import { AnchorBulkUpload } from "./Components/AnchorUpload/AnchorBulkUpload";
import BankCollection from "./Components/BankCollection/BankCollection";
import BankCollectionLoanDetails from "./Components/BankCollection/BankCollectionLoanDetails";
import { BankDisbursement } from "./Components/BankDisbursement/BankDisbursement";
import BankLoanDetails from "./Components/BankDisbursement/BankLoanDetails";
import "./Components/Common/Common.scss";

import SideBar from "./Components/Common/SideBar";
import { userContext } from "./Components/Contexts/userContext";
import Login from "./Components/Login/Login";
import ChangePassword from "./Components/ResetPassword/ChangePassword";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import { AnchorSettings } from "./Components/Settings/AnchorSettings";
import { BankSettings } from "./Components/Settings/BankSettings";
import { Tier1Settings } from "./Components/Settings/Tier1Settings";
import { Tier2Settings } from "./Components/Settings/Tier2Settings";
import Tier1AuditTrail from "./Components/Tier1AuditTrail/Tier1AuditTrail";
// import Tier1Action from "./Components/Tier1Action/Tier1Action";
// import Tier1Consolidated from "./Components/Tier1Consolidated/Tier1Consolidated";
// import Tier1DataUpdate from "./Components/Tier1DataUpdate/Tier1DataUpdate";
import Tier1EarlyPaymentPage from "./Components/Tier1EarlyPayment/Tier1EarlyPayment";
import Tier1LedgerAdjustment from "./Components/Tier1LedgerAdjusment/Tier1LedgerAdjustment";
// import Tier1Action from "./Components/Tier1Action/Tier1Action";
// import Tier1Consolidated from "./Components/Tier1Consolidated/Tier1Consolidated";
// import Tier1DataUpdate from "./Components/Tier1DataUpdate/Tier1DataUpdate";
// import Tier1Recon360 from "./Components/Tier1Recon360/Tier1Recon360";
import { Tier1Upload } from "./Components/Tier1Upload/Tier1Upload";
import Tier2Account from "./Components/Tier2Account/Tier2Account";
import Tier2AuditTrail from "./Components/Tier2AuditTrail/Tier2AuditTrail";
// import Tier2Consolidated from "./Components/Tier2Consolidated/Tier2Consolidated";
import Faq from "./Components/Recon360/Faq/Faq";
import Tier2EarlyPayment from "./Components/Tier2EarlyPayment/Tier2EarlyPayment";
import Tier2LedgerAdjustment from "./Components/Tier2LedgerAdjustment/Tier2LedgerAdjustment";
import { FrontendActor } from "./entity/models/FrontendActor";
// import Tier2Upload from "./Components/Tier2Upload/Tier2Upload";
const App = () => {
  const [actor, setActor] = useState<FrontendActor>(() => {
    const localData = localStorage.getItem("actor");
    try {
      return localData ? JSON.parse(localData) : null;
    } catch (e) {
      return null;
    }
  });
  useEffect(() => {
    // Function to fetch the backend version
    const fetchBackendVersion = () => {
      try {
        Axios.get("/api/version")
          .then((response) => {
            const backendVersion = response.data.version;
            // Access the version property from package.json
            const frontendVersion = packageJson.version;
            // Compare frontend and backend versions
            if (frontendVersion !== backendVersion) {
              console.log("A new version is available. refreshing the page.");
              // Perform a hard refresh
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log("error version", error?.response);
          });
      } catch (error) {
        console.log("error version", error);
      }
    };

    // Polling interval (every 1 minutes in this example)
    const pollingInterval = 1 * 60 * 1000; // 1 minutes in milliseconds

    // Initial fetch
    fetchBackendVersion();

    // Set up interval for periodic polling
    const intervalId = setInterval(fetchBackendVersion, pollingInterval);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const AdminRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (actor && actor.actorType === "Admin") {
            return <Component {...props} />;
          } else {
            return <Redirect to="/" />;
          }
        }}
      />
    );
  };

  return (
    <BrowserRouter>
      <userContext.Provider value={{ actor, setActor }}>
        <SideBar />
        <Switch>
          <Route exact={true} path="/">
            <Login />
          </Route>
          <Route exact={true} path="/reset/:token/:accountType">
            <ChangePassword />
          </Route>
          <Route exact={true} path="/reset">
            <ResetPassword />
          </Route>
          <Route exact={true} path="/faq">
            <Faq />
          </Route>
          {/* <Route exact={true} path="/register">
            <Register />
          </Route> */}

          <Route exact={true} path="/tier1/early">
            <Tier1EarlyPaymentPage />
          </Route>

          <Route exact={true} path="/tier1/audit/:pathParam?">
            <Tier1AuditTrail />
          </Route>

          <Route exact={true} path="/tier1/ledger/:pathParam?">
            <Tier1LedgerAdjustment />
          </Route>

          <Route exact={true} path="/tier1/upload">
            <Tier1Upload />
          </Route>

          {/* <Route exact={true} path="/tier1/recon360/:pathParam?">
            <Tier1Recon360 />
          </Route> */}

          <Route exact={true} path="/tier2/account">
            <Tier2Account />
          </Route>

          {/* <Route exact={true} path="/tier2/consolidated/:pathParam?">
            <Tier2Consolidated />
          </Route> */}

          <Route exact={true} path="/tier2/ledger/:pathParam?">
            <Tier2LedgerAdjustment />
          </Route>

          <Route exact={true} path="/tier2/audit/:pathParam?">
            <Tier2AuditTrail />
          </Route>

          <Route path="/tier2/early/:pathParam?">
            <Tier2EarlyPayment />
          </Route>

          {/* <Route exact={true} path="/tier2/upload">
            <Tier2Upload />
          </Route> */}
          <Route exact={true} path="/bank/collection">
            <BankCollection />
          </Route>
          <Route exact={true} path="/bank/disbursement">
            <BankDisbursement />
          </Route>
          <Route exact={true} path="/bank/settings">
            <BankSettings />
          </Route>

          <Route exact={true} path="/anchor/settings">
            <AnchorSettings />
          </Route>

          <Route exact={true} path="/tier1/settings">
            <Tier1Settings />
          </Route>

          <Route exact={true} path="/tier2/settings">
            <Tier2Settings />
          </Route>

          <Route exact={true} path="/anchor/api/payables">
            <AnchorPayables />
          </Route>

          <Route exact={true} path="/anchor/early/:pathParam?">
            <AnchorLedgerAdjustment />
          </Route>

          <Route exact={true} path="/anchor/upload">
            <AnchorBulkUpload />
          </Route>

          <Route exact={true} path="/anchor/ld">
            <AnchorLeadershipDashboard />
          </Route>

          <Route exact={true} path="/anchor/audit/:pathParam?">
            <AnchorAuditTrail />
          </Route>

          <Route path="/bank/disbursement/:loanId" component={BankLoanDetails} />
          <Route path="/bank/collection/:loanId" component={BankCollectionLoanDetails} />

          {/* AdminRoute for admin paths */}

          <AdminRoute exact={true} path="/admin/collectionaccount" component={CollectionAccount} />
          <AdminRoute exact={true} path="/admin/disbursmentaccount" component={DisbursmentAccount} />

          <AdminRoute exact={true} path="/admin/Register" component={Register} />

          <Redirect to="/" />
        </Switch>
      </userContext.Provider>
    </BrowserRouter>
  );
};

export default App;
