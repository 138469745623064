import { DineroObject } from "dinero.js";
import { ActorMappings } from "../../entity/ActorMappings";
// import { ErpAdjustmentStatusStrings } from "../database/entity/Tier1EarlyPayment";
import { ExternalDocTemplate } from "../../entity/ExternalDocTemplate";
import { Loan } from "../../entity/Loan";
import { Notification } from "../../entity/Notifications";
// import { AnchorLedgerSap } from "../database/entity/sap-database/AnchorLedgerSap";
import { Tier1Advance } from "../../entity/Tier1Advance";
import { Tier1CreditNote } from "../../entity/Tier1CreditNote";
import { Tier1DebitNote } from "../../entity/Tier1DebitNote";
import { Tier1EarlyPayment } from "../../entity/Tier1EarlyPayment";
import { Tier1Invoice } from "../../entity/Tier1Invoice";
import { Tier1Tier2InvoiceMapping } from "../../entity/Tier1Tier2InvoiceMapping";
import { Tier2Advance } from "../../entity/Tier2Advance";
import { Tier2CreditNote } from "../../entity/Tier2CreditNote";
import { Tier2DebitNote } from "../../entity/Tier2DebitNote";
import { Tier2Invoice } from "../../entity/Tier2Invoice";
import { Tier2EntryAdjustment } from "../models/Tier2EntryAdjustment";
/**
 * Utility interface
 */
export const DineroAmount = { amount: 0, currency: "INR", precision: 2 } as DineroObject;
export type TypeCurrency = keyof typeof DineroAmount.currency;
export interface SapLedger {
  assignment: string;
  documentNumber: number;
  documentType: string;
  documentDate: Date;
  lineId: string;
  paymentTerms: string;
  paymentDate: Date;
  netDueDate: Date;
  amountLCvalue: string;
  Lcurrency: string;
  remarks: string;
  itemState: string;
  clearingDate: Date;
  clearingDocumentNumber: number;
  reference: string;
  postingDate: Date;
  specialGlInd: string;
  companyCode: string;
  buisnessArea: string;
  buisnessPlace: string;
  yearMonth: string;
  glAccount: string;
  paymentMethod: string;
  purchaseDocument: string;
  baselinePaymentDate: Date;
  amountInDocCurrency: string;
  currency: string;
  withholdtaxbaseamount: string;
  withholdtaxamount: string;
  taxcurrency: string;
}
export interface SapUploadRequest {
  supplierCode: number;
  ledger: SapLedger[];
}

export interface PartTier1Invoice {
  tier1Invoice: Tier1Invoice;
  partialAmount: DineroObject;
}
/**
 * Utility interface which has details of discounted tier2 invoice. This interface is used when Tier2 claims for an early payment on it's invoice.
 */
export interface DiscountableTier2InvoiceInfo {
  tier2Invoice: Tier2Invoice;
  invoiceId: string;
  customer: string;
  invoiceDate: Date;
  invoiceAmount: DineroObject;
  totalAdvanceAmount: DineroObject;
  totalCreditAmount: DineroObject;
  totalDebitAmount: DineroObject;
  netReceivable: DineroObject;
  earlyPaymentAmount: DineroObject;
  discount: DineroObject;
  paymentPendingWithCustomer: DineroObject;
  partTier1Invoices: PartTier1Invoice[];
  loanAmount: DineroObject;
  remainingPaymentAmount: DineroObject;
  discountedAnnualRatePercentage: number;
  bankAnchorInterestRatePercentage: number;
  status: ErpAdjustmentStatusStrings;
  bankId: number;
}
export interface DiscountableTier1InvoiceInfo {
  tier1Invoice: Tier1Invoice;
  invoiceId: string;
  customer: string;
  invoiceDate: Date;
  invoiceAmount: DineroObject;
  totalDebitAmount: DineroObject;
  totalCreditAmount: DineroObject;
  totalAdvanceAmount: DineroObject;
  netReceivable: DineroObject;
  earlyPaymentAmount: DineroObject;
  discount: DineroObject;
  utilizedBySupplier: DineroObject;
  remainingPaymentAmount: DineroObject;
  loanAmount: DineroObject;
  bankAnchorInterestRatePercentage: number;
  discountedAnnualRatePercentage: number;
  status: ErpAdjustmentStatusStrings;
  bankId: number;
}

export enum LoanStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  Paid = "Paid",
}

export enum LoanType {
  Tier1EarlyPayment = "Tier1EarlyPayment",
  Tier2EarlyPayment = "Tier2EarlyPayment",
}

export type LoanTypeStrings = keyof typeof LoanType;

export type LoanStatusStrings = keyof typeof LoanStatus;

export enum BankApprovalStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
}

export type BankApprovalStatusStrings = keyof typeof BankApprovalStatus;

export enum ErpAdjustmentStatus {
  Pending = "Pending",
  Done = "Done",
}

export type ErpAdjustmentStatusStrings = keyof typeof ErpAdjustmentStatus;

// TODO(harshil) - Remove the null from here
export enum Tier2InvoiceApprovalStatus {
  Approved = "Approved",
  Rejected = "Rejected",
  Pending = "Pending",
}

export type Tier2InvoiceApprovalStatusString = keyof typeof Tier2InvoiceApprovalStatus;

export enum ActorTypes {
  Anchor = "Anchor",
  Tier1 = "Tier1",
  Tier2 = "Tier2",
  Bank = "Bank",
  Admin = "Admin",
}
export enum AccountTypeForRecon {
  ReconUser = "ReconUser",
  Partner = "Partner",
  Both = "Both",
}

export type ActorTypeStrings = keyof typeof ActorTypes;

export enum NAType {
  NA = "NA",
}

export type NAString = keyof typeof NAType;

// ListTier1AudtiTrail interfaces
export interface Tier1AuditTrailInfo {
  InvoiceNumber: string;
  InvoiceDate: Date;
  Customer: string;
  InvoiceAmount: DineroObject;
  Advance: DineroObject;
  DebitNote: DineroObject;
  CreditNote: DineroObject;
  NetReceivable: DineroObject;
  EarlyPayment: DineroObject;
  EarlyPaymentAccount: string;
  InterestRate: number;
  PaymentAmount: DineroObject;
  PaymentAccount: string;
  Supplier: string;
  SupplierInvoice: string;
  SupplierInvoiceAmount: DineroObject;
  SupplierAdvance: DineroObject;
  SupplierDebitNote: DineroObject;
  SupplierCreditNote: DineroObject;
  NetPayable: DineroObject;
  SupplierEarlyPayment: DineroObject;
  SupplierPaymentAccount: string;
  SupplierPayment: DineroObject;
  SupplierSOAAdjustment: string;
}
export interface ListTier1AuditTrailResponse {
  Tier1AuditTrailInfoList: Tier1AuditTrailInfo[];
}

// ListTier2AuditTrail Interfaces
export interface Tier2AuditTrailInfo {
  InvoiceNumber: string;
  InvoiceDate: Date;
  Customer: string;
  InvoiceAmount: DineroObject;
  Advance: DineroObject;
  DebitNote: DineroObject;
  CreditNote: DineroObject;
  NetReceivable: DineroObject;
  EarlyPayment: DineroObject;
  EarlyPaymentAccount: string;
  Interest: number;
  PaymentAmount: DineroObject;
  PaymentAccount: string;
}
export interface ListTier2AuditTrailResponse {
  Tier2AuditTrailList: Tier2AuditTrailInfo[];
}

// updateTier1EntriesForLoan Interfaces
export interface UpdateTier1EntriesForLoanResponse {
  message: string;
}

// updateTier1InvoicesForDisvounting Interface
export interface UpdateTier1InvoicesForDiscountingResponse {
  tier1EarlyPayment: Tier1EarlyPayment;
}

// updateTier2EntriesForLoan Interface
export interface UpdateTier2EntriesForLoanResponse {
  message: string;
}

// UpdateTier2InvoicesForDiscounting Interface
export interface UpdateTier2InvoicesForDiscountingResponse {
  tier1Tier2InvoiceMapping: Tier1Tier2InvoiceMapping;
}

// updateLoanForBankApproval Interface
export interface UpdateLoanForBankApprovalResponse {
  tier1Tier2Mapping?: Tier1Tier2InvoiceMapping;
  tier1EarlyPayment?: Tier1EarlyPayment;
}

// UpdateActorMappings Interface
export interface UpdateActorMappingsResponse {
  actorMapping: ActorMappings;
}

// updateActorMapping Interfaces
/**
 * Response Interface for Listing of Notifications Method
 */

export interface ListNotificatonsResponse {
  results: Notification[];
}
/**
 * Response Interface for Posting of Notifications Method
 */
export interface PostNotificatonsResponse {
  results: Notification;
}

// listTier2LedgerAdjustment Response
export interface ListTier2LedgerAdjustmentInfo {
  LoanId: number;
  LoanType: LoanType;
  TransactionId: string;
  PaymentTime: Date;
  InvoiceNumber: string;
  ChangeInReceivables: DineroObject;
  PaymentAccount: string;
}

export interface ListTier2LedgerAdjustmentResponse {
  tier2LedgerAdjustmentInfoList: ListTier2LedgerAdjustmentInfo[];
}

/**
 * Utility interface segregrating Discounted and Non Discounted Tier2Invoice.
 */
export interface ListTier2InvoicesForDiscountingResponse {
  discountableTier2InvoiceList: DiscountableTier2InvoiceInfo[];
  nonDiscountableTier2InvoiceList: Tier2Invoice[];
}

// ListTier2Invoices Interface
export interface ListTier2InvoicesResponse {
  invoices: Tier2Invoice[];
}

// ListTier2EarlyPaymentReceived Interface
export interface ListTier2EarlyPaymentReceivedResponse {
  invoices: Tier2EntryAdjustment[];
}

// listTier1LedgerAdjustment Interfaces
export interface ListTier1LedgerAdjustmentInfo {
  LoanId: number;
  LoanType: LoanType;
  TransactionId: string;
  CreditedTo: string;
  PaymentAmount: DineroObject;
  InvoiceNumber: string;
  ChangeInReceivables: DineroObject;
  SupplierInvoiceNumber: string;
  ChangeInPayables: DineroObject;
}

export interface ListTier1LedgerAdjustmentResponse {
  tier1LedgerAdjustmentInfoList: ListTier1LedgerAdjustmentInfo[];
}

// ListTier1InvoicesForDiscounting Interfaces
export interface ListTier1InvoicesForDiscountingResponse {
  discountableTier1InvoiceList: DiscountableTier1InvoiceInfo[];
  nonDiscountableTier1InvoiceList: Tier1Invoice[];
}

// ListTier1InvoicesResponse Interface
export interface ListTier1InvoicesResponse {
  invoices: Tier1Invoice[];
}

// ListLoansForPayment Response
export interface ListLoansForPayment {
  loans: Loan[];
}

// ListLoansForBankApprovalResponse Interface
export interface ListLoansForBankApprovalResponse {
  loans: Loan[];
}

// ListAnchorAuditTrail Interfaces
export interface AnchorAuditTrailInfo {
  InvoiceId: string;
  InvoiceDate: Date;
  Supplier: string;
  InvoiceAmount: DineroObject;
  DebitNote: DineroObject;
  Advance: DineroObject;
  CreditNote: DineroObject;
  NetPayment: DineroObject;
  LendersShare: DineroObject;
  SuppliersShare: DineroObject;
  SupplierSOAAdjustment: string;
}
export interface AnchorAuditTrailResponse {
  AnchorAuditTrailInfoList: AnchorAuditTrailInfo[];
}

// ListAllActorMappingsResponse Interface
export interface ListAllActorMappingsResponse {
  actorMappings: ActorMappings[];
}

// InsertTier2InvoiceResponse Interface
export interface Tier2InvoiceResponse {
  invoice: Tier2Invoice;
}

// InsertTier2DebitNoteResponse Interface
export interface Tier2DebitNoteResponse {
  debitNote: Tier2DebitNote;
}

// InsertTier2CreditNoteResponse Interface
export interface Tier2CreditNoteResponse {
  creditNote: Tier2CreditNote;
}

// InsertTier2AdvanceResponse Interface
export interface Tier2AdvanceResponse {
  advance: Tier2Advance;
}

// InsertTier1InvoiceResponse Interface
export interface Tier1InvoiceResponse {
  invoice: Tier1Invoice;
}

// InsertTier1DebitNoteResponse Interface
export interface Tier1DebitNoteResponse {
  debitNote: Tier1DebitNote;
}

// InsertTier1CreditNoteResponse Interface
export interface Tier1CreditNoteResponse {
  creditNote: Tier1CreditNote;
}

// InsertTier1AdvanceResponse Interfacae
export interface Tier1AdvanceResponse {
  advance: Tier1Advance;
}

// UpdateActorMappingsResponse Interface
export interface UpdateActorMappingsResponse {
  actorMapping: ActorMappings;
}

// GetLoanById Interfaces
export interface LoanInfo {
  loan_Id: number;
  loanType: LoanType;
  bankApprovalStatus: LoanStatus;
  loanAmount: DineroObject;
  discount: DineroObject;
  EarlyPayment: DineroObject;
  Tier1InvoiceId: string;
  Tier1InvoiceAmount: DineroObject;
  Tier1InvoiceDueDate: Date;
  Tier1InvoiceTotalDebitAmount: DineroObject;
  Tier1InvoiceTotalCreditAmount: DineroObject;
  Tier1InvoiceTotalAdvanceAmount: DineroObject;
  Tier1InvoiceNetReceivable: DineroObject;
  Tier2InvoiceId: string;
  Tier2InvoiceAmount: DineroObject;
  Tier2InvoiceDueDate: Date;
  Tier2InvoiceTotalDebitAmount: DineroObject;
  Tier2InvoiceTotalCreditAmount: DineroObject;
  Tier2InvoiceTotalAdvanceAmount: DineroObject;
  Tier2InvoiceNetReceivable: DineroObject;
  InterestRate: number;
  tier1: string;
  anchor: string;
}

export interface GetLoanByIdResponse {
  loan: LoanInfo;
}

// ExternalDocTemplate Interfaces
/**
 * Response Interface for the listing of templates method
 */
export interface ListExternalDocTemplateResponse {
  templates: ExternalDocTemplate[];
}
/**
 * Response Interface for the inserting of templates method
 */
export interface ExternalDocTemplateResponse {
  template: ExternalDocTemplate;
}

/**
 * Request responses
 */

// request interface for AuditTrail APIs
export interface AuditTrailRequestParmas {
  screenType: string;
}

// request interface for getLoanById
export interface GetLoanByIdRequestParams {
  loanId: string;
}

// request interface for updateTier1EntriesForLoan
export interface UpdateEntriesForLoanRequestBody {
  loanIdsAndTypes: LoanIdAndType[];
}

export interface LoanIdAndType {
  loanId: number;
  loanType: LoanType;
}
