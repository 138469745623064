import React from "react";
import "./../Tier2EarlyPayment/Tier2EarlyPayment.scss";
import { EntryAdjusted } from "./AnchorLedgerAdjustment";
interface BtnCellRendererProps {
  /** This property takes care of clicking */
  clicked: (arg0: EntryAdjusted) => void;
  value: EntryAdjusted;
  erpAdjustment: string;
}

/**
 * @Component
 * Anchor Component for Anchor main page
 * checks how states are handled
 */
class BtnCellRenderer extends React.Component<BtnCellRendererProps> {
  constructor(props: BtnCellRendererProps) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }

  /** This is button click handler
   * @public
   */
  public btnClickedHandler() {
    this.props.clicked(this.props.value);
  }

  render() {
    return (
      <div>
        {this.props.value.anchorAdjustment === "Pending" && (
          <button className="button is-accept" onClick={this.btnClickedHandler}>
            Confirm
          </button>
        )}
      </div>
    );
  }
}

export default BtnCellRenderer;
