import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import React, { useContext } from "react";
import { useLocation } from "react-router";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";

export const Tier2Settings = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Settings", actor.name, location?.state?.openCollapseOb, actor)}
    />
  );
};
