import { ColDef } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
// import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";
// import { useHistory, useParams } from "react-router";
// import { AnchorTier2InvoiceMapping } from "../../../../src/database/entity/AnchorTier2InvoiceMapping";
// import { ListAnchorTier2InvoicesMappingResponse } from "../../../../src/routes/listAnchorTier2InvoicesMapping";
// import { UpdateAnchorTier2InvoicesMappingResponse } from "../../../../src/routes/updateAnchorTier2InvoicesMapping";
import { dateValueFormatter } from "../../Utils/DateUtils";
// import { formatMoney } from "../../Utils/MoneyUtils";
// import * as MoneyUtil from "../../Utils/MoneyUtils";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
// import { createNotification, PushNotification } from "../Common/Notification/PushNotification";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
// import { userContext } from "../Contexts/userContext";
import BtnCellRenderer from "./BtnCellRenderer";
// import { toDineroObject } from "../../Utils/BulkUploadUtility";

/**
 * This interface is used to interact with BtnCellRenderer Component.
 */
export interface EntryAdjusted {
  anchorInvoiceId: string;
  tier2InvoiceId: string;
  anchorId: number;
  tier1Id: number;
  tier2Id: number;
  anchorAdjustment: string;
}

/**
 * This is the interface for CollectionRowThis is the interface for CollectionRow
 * @interface
 *
 */
export interface CollectionRow {
  invoice: string;
  supplier: string;
  invoice_date: Date;
  payable_amount: string;
  supplier_payable: string;
  nakad_payable: string;
  entryAdjusted?: EntryAdjusted;
}

export interface RowData {
  invoice: string;
}

/**
 * @Component
 * Anchor Component for Anchor Entry Adjustment tab. It contains two sub tabs - pending and completed.
 *
 * 1.Pending - All the anchor invoices(Tier1Invoices) which were matched against a Tier2Invoice. The loan for the mapping has been sanctioned and disbursed. This tab represents the invoices which are due to be adjusted in Anchor's ERP.
 *
 * 2.Completed - All the anchor invoices(Tier1Invoices) which are adjusted in ERP.
 *
 * This component uses Ag grid library to demostrate the data in tabular form.
 */
const AnchorPayables = () => {
  const location = useLocation<any>();
  // const [anchorData, setAnchorData] = useState([] as AnchorTier2InvoiceMapping[]);
  // const [gridApi, setGridApi] = useState({} as GridApi);
  const { actor } = useContext(userContext);
  // const history = useHistory();
  // const { pathParam } = useParams<{ pathParam?: string }>();
  // const DEFAULT_ACTIVE_TAB = "Pending";
  // const pathWay = "/anchor";

  /**
   * react hook
   * runs first time only when dependency array is empty
   * if empty array not given than it runs everytime on the change dependency array
   * dependency array defines variable on which use effect is used e.g: api call on first load of page
   * @public
   */
  useEffect(() => {
    // if (!pathParam) {
    //   history.push(`${pathWay}/${DEFAULT_ACTIVE_TAB}`);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const toggle = (activeTab: string) => {
  //   if (pathParam !== activeTab) {
  //     history.push(`${pathWay}/${activeTab}`);
  //   }
  // };

  /** This is a column definition of the grid on Anchor Entry Adjustment page - Pending sub-tab
   * @public
   */
  // const colDefns: ColDef[] = [
  //   { headerName: "Invoice number", field: "invoice", minWidth: 180 },
  //   { headerName: "Supplier", field: "supplier", minWidth: 180 },
  //   { headerName: "Invoice date", field: "invoice_date", valueFormatter: dateValueFormatter, minWidth: 180},
  //   {
  //     headerName: "Invoice Amount",
  //     field: "invoice_amount",
  //     cellStyle: { color: "#48AC23" },
  //     minWidth: 180,
  //   },
  //   {
  //     headerName: "Debit",
  //     field: "debit_amount",
  //     cellStyle: { color: "#48AC23" },
  //     minWidth: 180,
  //   },
  //   {
  //     headerName: "Advance",
  //     field: "advance_amount",
  //     cellStyle: { color: "#48AC23" },
  //     minWidth: 180,
  //   },
  //   {
  //     headerName: "Net Payment",
  //     field: "net_payment",
  //     cellStyle: { color: "#48AC23" },
  //     minWidth: 180,
  //   },
  //   {
  //     headerName: "Lender's Share",
  //     field: "lender_payable",
  //     headerTooltip: "Net amount payable to the lender",
  //     cellStyle: { color: "#48AC23" },
  //     minWidth: 200,
  //   },
  //   {
  //     headerName: "Supplier's Share",
  //     field: "supplier_payable",
  //     headerTooltip: "Net amount payable to the supplier",
  //     cellStyle: { color: "#48AC23" },
  //     minWidth: 180,
  //   },
  //   {
  //     headerName: "Supplier Acknowledgment",
  //     field: "supplier_acknowledgment",
  //     cellStyle: { color: "#48AC23" },
  //     // cellRenderer: "btnCellRenderer",
  //     // cellClass: "center-cell",
  //     // cellRendererParams: {
  //     //   clicked(entryAdjusted: EntryAdjusted) {
  //     //     updateAnchorAdjustment(entryAdjusted);
  //     //     console.log("Entry Adjusted", entryAdjusted);
  //     //   },
  //     // },
  //     minWidth: 180,
  //   },
  // ];
  /** This is a column definition of the grid on Anchor Entry Adjustment page - Completed sub-tab
   * @public
   */
  const colDefns: ColDef[] = [
    { headerName: "Invoice number", field: "invoice", minWidth: 180 },
    { headerName: "Supplier", field: "supplier", minWidth: 180 },
    { headerName: "Invoice date", field: "invoice_date", valueFormatter: dateValueFormatter, minWidth: 180 },
    {
      headerName: "Payable Amount",
      field: "invoice_amount",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Debit Note",
      field: "debit_amount",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Advance",
      field: "advance_amount",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Net Payable",
      field: "net_payable",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Status",
      field: "discounting_status",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    // {
    //   headerName: "Net Payment",
    //   field: "net_payment",
    //   cellStyle: { color: "#48AC23" },
    //   minWidth: 180,
    // },
    // {
    //   headerName: "Lender's Share",
    //   field: "lender_payable",
    //   headerTooltip: "Net amount payable to the lender",
    //   cellStyle: { color: "#48AC23" },
    //   minWidth: 180,
    // },
    // {
    //   headerName: "Supplier's Share",
    //   field: "supplier_payable",
    //   headerTooltip: "Net amount payable to the supplier",
    //   cellStyle: { color: "#48AC23" },
    //   minWidth: 180,
    // },
  ];

  const defaultColDef: ColDef = {
    flex: 2,
    minWidth: 200,
    sortable: true,
    resizable: true,
    wrapText: true,
    filter: true,
    autoHeight: true,
    cellStyle: { color: "#4D4F5C" },
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };
  /**
   * Button Component on pending sub-tab to confirm adjustment of entry in ERP.
   */
  const frameworkComponents = {
    btnCellRenderer: BtnCellRenderer,
  };

  /**
   * This handler is called on the initial rendering of the screen. It fetches all the list of anchor invoices against which loan has been sanctioned and disbursed.
   * @public
   */
  const onGridReady = async () => {
    // const result = await axios.get<ListAnchorTier2InvoicesMappingResponse>("/api/ListAnchorTier2InvoicesMapping", {
    //   params: {
    //     status: "Approved",
    //   },
    // });
    // console.log("Returned AnchorTier2INvoicesMapping", result.data.anchorTier2InvoicesMapping);
    // //setAnchorData(result.data.anchorTier2InvoicesMapping);
    // setGridApi(params.api);
  };

  /**
   * This method returns the data which is published on the pending sub-tab of the screen.
   * @public
   */
  function getRowData(): any {
    return [];
  } // {
  // const pendingAnchorData = anchorData.filter((aData) => aData.anchorAdjustment === "Pending");
  // return pendingAnchorData.map((aData) => {
  //   const row: CollectionRow = {
  //     invoice: aData.anchorInvoiceId,
  //     supplier: aData.anchorInvoice.tier1.actorInfo.name,
  //     invoice_date: aData.anchorInvoice.invoiceDate,
  //     payable_amount: formatMoney(aData.anchorInvoice.invoiceAmount),
  //     nakad_payable: formatMoney(aData.loanAmount),
  //     supplier_payable: formatMoney(MoneyUtil.subtractMoney(aData.anchorInvoice.invoiceAmount, aData.loanAmount)),
  //     entryAdjusted: {
  //       anchorInvoiceId: aData.anchorInvoiceId,
  //       tier2InvoiceId: aData.tier2InvoiceId,
  //       anchorId: aData.anchorId,
  //       tier1Id: aData.tier1Id,
  //       tier2Id: aData.tier2Id,
  //       anchorAdjustment: aData.anchorAdjustment,
  //     },
  //   };
  //   return row;
  // });
  //   return [];
  // }
  // {
  //   invoice: 1010,
  //   supplier: "Mega Enterprises",
  //   invoice_date: "14 February 2022",
  //   invoice_amount: formatMoney(toDineroObject(5000)),
  //   debit_amount: formatMoney(toDineroObject(5000)),
  //   advance_amount: formatMoney(toDineroObject(5000)),
  //   net_payment: formatMoney(toDineroObject(5000)),
  //   lender_payable: formatMoney(toDineroObject(5000)),
  //   supplier_payable: formatMoney(toDineroObject(5000)),
  //   supplier_acknowledgment: formatMoney(toDineroObject(5000)),
  // },
  // {
  //   invoice: 1011,
  //   supplier: "Mega Enterprises",
  //   invoice_date: "14 February 2022",
  //   invoice_amount: formatMoney(toDineroObject(5000)),
  //   debit_amount: formatMoney(toDineroObject(5000)),
  //   advance_amount: formatMoney(toDineroObject(5000)),
  //   net_payment: formatMoney(toDineroObject(5000)),
  //   lender_payable: formatMoney(toDineroObject(5000)),
  //   supplier_payable: formatMoney(toDineroObject(5000)),
  //   supplier_acknowledgment: formatMoney(toDineroObject(5000)),
  // },
  //   ]
  // };
  /**
   * This method returns the data which is published on the completed sub-tab of the screen.
   * @public
   */
  // const getAfterAdjustmentData = () => {
  // const completedAnchorData = anchorData.filter((aData) => aData.anchorAdjustment === "Done");
  // console.log(completedAnchorData);
  // return completedAnchorData.map((aData) => {
  //   const row: CollectionRow = {
  //     invoice: aData.anchorInvoiceId,
  //     supplier: aData.anchorInvoice.tier1.actorInfo.name,
  //     invoice_date: aData.anchorInvoice.invoiceDate,
  //     payable_amount: formatMoney(aData.anchorInvoice.invoiceAmount),
  //     nakad_payable: formatMoney(aData.loanAmount),
  //     supplier_payable: formatMoney(MoneyUtil.subtractMoney(aData.anchorInvoice.invoiceAmount, aData.loanAmount)),
  //     entryAdjusted: {
  //       anchorInvoiceId: aData.anchorInvoiceId,
  //       tier2InvoiceId: aData.tier2InvoiceId,
  //       anchorId: aData.anchorId,
  //       tier1Id: aData.tier1Id,
  //       tier2Id: aData.tier2Id,
  //       anchorAdjustment: aData.anchorAdjustment,
  //     },
  //   };
  //   return row;
  // });
  // invoice:
  // supplier:
  // invoice_date:
  // invoice_amount:
  // debit_amount:
  // advance_amount:
  // net_payment:
  // lender_payable:
  // supplier_payable:
  // supplier_acknowledgment:
  //   return [
  //     {
  //       invoice: 1012,
  //       supplier: "Mega Enterprises",
  //       invoice_date: "18 February 2022",
  //       invoice_amount: formatMoney(toDineroObject(5000)),
  //       debit_amount: formatMoney(toDineroObject(5000)),
  //       advance_amount: formatMoney(toDineroObject(5000)),
  //       net_payment: formatMoney(toDineroObject(5000)),
  //       lender_payable: formatMoney(toDineroObject(5000)),
  //       supplier_payable: formatMoney(toDineroObject(5000)),
  //       //supplier_acknowledgment: formatMoney(toDineroObject(5000)),
  //     },
  //     {
  //       invoice: 1013,
  //       supplier: "Mega Enterprises",
  //       invoice_date: "18 February 2022",
  //       invoice_amount: formatMoney(toDineroObject(5000)),
  //       debit_amount: formatMoney(toDineroObject(5000)),
  //       advance_amount: formatMoney(toDineroObject(5000)),
  //       net_payment: formatMoney(toDineroObject(5000)),
  //       lender_payable: formatMoney(toDineroObject(5000)),
  //       supplier_payable: formatMoney(toDineroObject(5000)),
  //       //supplier_acknowledgment: formatMoney(toDineroObject(5000)),
  //     },
  //   ]
  // };

  /**
   * @public
   * This method is called to signal our application that Anchor has updated it's ERP. It also sends a notification to bank detailing the same.
   */
  // const updateAnchorAdjustment = async (entryAdjusted: EntryAdjusted) => {
  //   const result = await axios.post<UpdateAnchorTier2InvoicesMappingResponse>(
  //     "/api/UpdateAnchorTier2InvoicesMapping",
  //     entryAdjusted
  //   );
  //   console.log(result.data);
  //   await PushNotification({
  //     notification: createNotification(
  //       result.data.anchorTier2InvoiceMapping.bankId,
  //       `${result.data.anchorTier2InvoiceMapping.anchorInvoice.anchor.actorInfo.name} has routed payment in favor of ${result.data.anchorTier2InvoiceMapping.bank.actorInfo.name} for loan number# ${result.data.anchorTier2InvoiceMapping.mappingInfo.loanId}`,
  //       result.data.anchorTier2InvoiceMapping.anchorInvoice.anchor.actorType,
  //       "Bank-Payment"
  //     ),
  //   });
  //   onGridReady();
  // };
  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Anchor Payables", actor.name, location?.state?.openCollapseOb, actor)}
    >
      {/* <div className="tabs  is-toggle" style={{ marginLeft: "2vw" }}> */}
      {/* <ul>
          <li className={`${pathParam === "Pending" ? "is-active" : " "}`} onClick={() => toggle("Pending")}>
            <a>
              <span>Open</span>
            </a>
          </li>
          <li className={`${pathParam === "Completed" ? "is-active" : " "}`} onClick={() => toggle("Completed")}>
            <a>
              <span>Settled</span>
            </a>
          </li>
        </ul> */}
      {/* </div> */}
      {/* {pathParam === "Pending" && ( */}
      <div className="ag-theme-alpine mygrid" id="table-1" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
        <AgGridReact
          enableCellTextSelection={true}
          suppressMenuHide={true}
          defaultColDef={defaultColDef}
          frameworkComponents={frameworkComponents}
          onGridReady={onGridReady}
          rowData={getRowData()}
          columnDefs={colDefns}
          domLayout="autoHeight"
        />
      </div>
      {/* )} */}
      {/* {pathParam === "Completed" && (
        <div className="ag-theme-alpine mygrid" id="table-1" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
          <AgGridReact
            enableCellTextSelection={true}
            suppressMenuHide={true}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            rowData={getAfterAdjustmentData()}
            columnDefs={settledColDefns}
            domLayout="autoHeight"
          />
        </div>
      )} */}
      <div className="column" style={{ paddingLeft: "2vw" }}>
        <input
          className="button is-bold is-success"
          type="button"
          value="Download"
          onClick={() => {
            // gridApi.exportDataAsCsv({ fileName: actor.name + "_Adjustment_" + pathParam });
          }}
        />
      </div>
    </LoggedInSkeleton>
  );
};

export default AnchorPayables;
