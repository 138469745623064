import { ColDef, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";

import React, { useEffect, useState } from "react";
import { Tier1Invoice } from "../../entity/Tier1Invoice";
import { formatDate } from "../../Utils/DateUtils";
import { formatMoney } from "../../Utils/MoneyUtils";
import { AnchorInvoiceRow, InvoicesTypes } from "../AnchorUpload/AnchorBulkUpload";
// type ModalType = 1 | 2 | 3 | null;
const rowSelectionType = "multiple";
interface InvoiceObject {
  tier2Invoice: Tier1Invoice;
  tier2InvoiceRow: AnchorInvoiceRow;
}

const InvoiceFlowChecksForAnchorInvoicesBulkUploads = (props: {
  tier2Invoices: Tier1Invoice[];
  tier2InvoiceRows: AnchorInvoiceRow[];
  update: (ids: string[]) => void;
  onClose: () => void;
  currentModal: number;
  tier2invoicesCheck?: InvoicesTypes;
}) => {
  const [askDifferentInvoices, setAskDifferentInvoices] = useState<InvoiceObject[]>([]);
  const [oldInvoices, setOldInvoices] = useState<InvoiceObject[]>([]);
  const [allIds, setAllIds] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  console.log("Tier2INvoicesChecksInModals", props.tier2invoicesCheck);
  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
    wrapText: true,
    filter: true,
    minWidth: 100,
    autoHeight: true,
    cellStyle: { color: "#4D4F5C" },
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };
  const dialog1: ColDef[] = [
    { headerName: "Invoice Number", field: "invoiceNumber" },
    { headerName: "Buyer / Seller", field: "supplier", minWidth: 150 },
    {
      headerName: "Message",
      field: "message",
      minWidth: 280,
      cellStyle: { "white-space": "normal", "word-break": "normal" },
    },
    { headerName: "Old Invoice Date", field: "oldInvoiceDate" },
    { headerName: "Old Invoice Amount", field: "oldInvoiceAmount" },
    { headerName: "New Invoice Date", field: "newInvoiceDate" },
    { headerName: "New Invoice Amount", field: "newInvoiceAmount" },
    { headerName: "Overwrite", field: "checked", checkboxSelection: true },
  ];
  const dialog2: ColDef[] = [
    { headerName: "Invoice Number", field: "invoiceNumber" },
    { headerName: "Buyer / Seller", field: "supplier", minWidth: 150 },
    {
      headerName: "Message",
      field: "message",
      minWidth: 280,
      cellStyle: { "white-space": "normal", "word-break": "normal" },
    },
    { headerName: "Old Invoice Date", field: "oldInvoiceDate" },
    { headerName: "Old Invoice Amount", field: "oldInvoiceAmount" },
    { headerName: "New Invoice Date", field: "newInvoiceDate" },
    { headerName: "New Invoice Amount", field: "newInvoiceAmount" },
  ];

  useEffect(() => {
    if (loading) {
      props.update(allIds);
      setSelectedIds([]);
      setAllIds([]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  const getRowData1 = () => {
    return askDifferentInvoices.map((inv) => {
      return {
        invoiceNumber: inv.tier2Invoice.invoiceId,
        supplier: inv.tier2InvoiceRow.supplier,
        message: " Invoice uploaded earlier, you want to overwrite?",
        oldInvoiceDate: formatDate(inv.tier2Invoice.invoiceDate),
        oldInvoiceAmount: formatMoney(inv.tier2Invoice.invoiceAmount),
        newInvoiceDate: formatDate(inv.tier2InvoiceRow.invoiceDate),
        newInvoiceAmount: formatMoney(inv.tier2InvoiceRow.invoiceAmount),
      };
    });
  };

  const getRowData2 = () => {
    return oldInvoices.map((inv) => {
      return {
        invoiceNumber: inv.tier2Invoice.invoiceId,
        supplier: inv.tier2InvoiceRow.supplier,
        message:
          inv.tier2Invoice.tier1Tier2InvoiceMappings.length > 0 &&
          inv.tier2Invoice.tier1Tier2InvoiceMappings[0].bankApprovalStatus === "Approved"
            ? "Invoice already being discounted"
            : "Invoice already approved, if you want to change please cancel this invoice from early payment pipeline and re-upload",
        oldInvoiceDate: formatDate(inv.tier2Invoice.invoiceDate),
        oldInvoiceAmount: formatMoney(inv.tier2Invoice.invoiceAmount),
        newInvoiceDate: formatDate(inv.tier2InvoiceRow.invoiceDate),
        newInvoiceAmount: formatMoney(inv.tier2InvoiceRow.invoiceAmount),
      };
    });
  };
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    console.log(event.api.getSelectedNodes());
    setSelectedIds(() => {
      return event.api.getSelectedNodes().map((rowNode) => rowNode.data.invoiceNumber);
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    const Ids: string[] = [];
    params.api.getModel().forEachNode((node) => Ids.push(node.data.invoiceNumber));
    console.log("All Rows", Ids);
    setAllIds(Ids);
  };
  // Dialog for Ask Different
  const dialogOpen1 = () => {
    return (
      <div>
        <header className="modal-card-head">
          <p className="modal-card-title">Attention</p>
          {/* <button className="delete" aria-label="close" onClick={props.onClose}></button> */}
        </header>
        <section className="modal-card-body">
          <div>
            <strong>Do you want to overwrite existing non-discounted invoices?</strong>
          </div>
          <div className="ag-theme-alpine ">
            <AgGridReact
              enableCellTextSelection={true}
              suppressMenuHide={true}
              columnDefs={dialog1}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowData={getRowData1()}
              rowSelection={rowSelectionType}
              onSelectionChanged={onSelectionChanged}
              suppressRowClickSelection={true}
              domLayout="autoHeight"
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-success"
            onClick={() => {
              console.log("Selected Ids", selectedIds);
              const invoiceIds = allIds.filter((id) => !selectedIds.includes(id));
              console.log("InvoiceIds", invoiceIds);
              setAllIds(invoiceIds);
              setLoading(true);
            }}
          >
            Proceed
          </button>
          {/* <button className="button" onClick={props.onClose}>
            Cancel
          </button> */}
        </footer>
      </div>
    );
  };

  // Dialog for Old
  const dialogOpen2 = () => {
    return (
      <div>
        <header className="modal-card-head">
          <p className="modal-card-title">Attention</p>
          {/* <button className="delete" aria-label="close"></button> */}
        </header>
        <section className="modal-card-body">
          <div>
            <strong>Following invoices not updated - Already Discounted</strong>
          </div>
          <div className="ag-theme-alpine ">
            <AgGridReact
              enableCellTextSelection={true}
              suppressMenuHide={true}
              columnDefs={dialog2}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowData={getRowData2()}
              rowSelection={rowSelectionType}
              onSelectionChanged={onSelectionChanged}
              suppressRowClickSelection={true}
              domLayout="autoHeight"
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-success"
            onClick={() => {
              setLoading(true);
            }}
          >
            Proceed
          </button>
          {/* <button className="button" onClick={props.onClose}>
            Cancel
          </button> */}
        </footer>
      </div>
    );
  };
  useEffect(() => {
    if (props.currentModal === 1) {
      console.log("Inside useEffect pending");
      getAskDifferentInvoices(props.tier2invoicesCheck.nonDiscountedInvoices);
    } else if (props.currentModal === 2) {
      console.log("Discounted Invoices...");
      getOldInvoices(props.tier2invoicesCheck.discountedInvoices);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentModal]);
  const getAskDifferentInvoices = (anchorInvoices: Tier1Invoice[]) => {
    const invoicesToUpdate: InvoiceObject[] = [];
    for (const inv of anchorInvoices) {
      const differentInvoice: InvoiceObject = {
        tier2Invoice: null,
        tier2InvoiceRow: null,
      };
      differentInvoice.tier2Invoice = inv;
      differentInvoice.tier2InvoiceRow = props.tier2InvoiceRows.find((row) => row.invoiceNumber === inv.invoiceId);
      invoicesToUpdate.push(differentInvoice);
    }
    setAskDifferentInvoices(invoicesToUpdate);
  };
  const getOldInvoices = (anchorInvoice: Tier1Invoice[]) => {
    console.log("Old Invoices", anchorInvoice);
    const invoiceToUpdate: InvoiceObject[] = [];
    const invoiceIds: string[] = [];
    for (const inv of anchorInvoice) {
      const previousInvoice: InvoiceObject = {
        tier2Invoice: null,
        tier2InvoiceRow: null,
      };
      invoiceIds.push(inv.invoiceId);
      previousInvoice.tier2Invoice = inv;
      previousInvoice.tier2InvoiceRow = props.tier2InvoiceRows.find((row) => row.invoiceNumber === inv.invoiceId);
      invoiceToUpdate.push(previousInvoice);
    }
    setOldInvoices(invoiceToUpdate);
  };
  return (
    <div className="modal" style={{ display: "flex" }}>
      <div className="modal-background" />
      <div className="modal-card" style={{ width: "100%", overflow: "auto" }}>
        {props.currentModal === 1 && dialogOpen1()}
        {props.currentModal === 2 && dialogOpen2()}
      </div>
      {/* <button className="modal-close is-large" aria-label="close" style={{ display: "none" }} onClick={props.onClose} /> */}
    </div>
  );
};

export { InvoiceFlowChecksForAnchorInvoicesBulkUploads };
