import { /*CellDoubleClickedEvent,*/ ColDef } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { DineroObject } from "dinero.js";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { DiscountableTier1InvoiceInfo } from "../../entity/models/UtilityInterfaces";
import { Tier1EarlyPayment } from "../../entity/Tier1EarlyPayment";
import { Tier1Invoice } from "../../entity/Tier1Invoice";
import { dateValueFormatter } from "../../Utils/DateUtils";
import { formatMoney } from "../../Utils/MoneyUtils";
import * as MoneyUtil from "../../Utils/MoneyUtils";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
import BtnCellRenderer from "./BtnCellRenderer";
import CustomTooltip from "./CustomTooltip";
import "./Tier1EarlyPayment.scss";

export interface ListTier1InvoicesForDiscountingResponse {
  discountableTier1InvoiceList: DiscountableTier1InvoiceInfo[];
  nonDiscountableTier1InvoiceList: Tier1Invoice[];
}

export interface UpdateTier1InvoicesForDiscountingResponse {
  tier1EarlyPayment: Tier1EarlyPayment;
}

export interface ImageSourceFiles {
  InvoiceFile?: string;
  GrnFile?: string;
  DebitNotes?: string[];
}

export interface Tier1InvoiceBreakupData {
  InvoiceAmount: number;
  DebitNote: number;
  Advance: number;
  NetRecievable: number;
}

export interface SelectedRowInvoiceInvoiceInfo {
  // tier1Invoice: Tier1Invoice;
  earlyPaymentAmount: DineroObject;
  // interestRate: number;
  loanAmount: DineroObject;
  bankAnchorInterestRatePercentage: number;
  discountedAnnualRatePercentage: number;
  // status: ErpAdjustmentStatusStrings;
}

/**
 * @Component
 * This component renders the tier1 early payment screen. It displays all invoices of a Tier1 on which it can take an early payment.
 */
const Tier1EarlyPaymentPage = () => {
  const location = useLocation<any>();
  const [gridColumnApiT, setGridColumnApiT] = useState(null);
  const [hideColumnT, setHideColumnT] = useState(true);
  const [plus, setPlus] = useState(true);

  // const [selectedRowNumber, setSelectedRowNumber] = useState(0);
  // const [selectedRowInvoiceData, setSelectedRowInvoiceData] = useState({} as DiscountableTier1InvoiceInfo);
  // const [selectedRowInvoiceData, setSelectedRowInvoiceData] = useState()

  const [invoicesAvailableForDiscounting, setInvoicesAvailableForDiscounting] = useState(
    [] as DiscountableTier1InvoiceInfo[]
  );
  // const [nonDiscountedApprovedInvoices, setNonDiscountedApprovedInvoices] = useState([] as Tier1Invoice[]);

  // const [tier2actiondata, setTier2actiondata] = useState({
  //   invoices: [],
  // } as ListTier2InvoicesResponse);
  // const [remark, setRemark] = useState("");
  // const [visible, setVisible] = useState<visibleTab>("invoice");
  // const [signedSrc, setSignedSrc] = useState<ImageSourceFiles>({});
  // const [uploading, setUploading] = useState(false);
  // const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { actor } = useContext(userContext);
  // const updateremark = (event: React.ChangeEvent<HTMLTextAreaElement>) => setRemark(event.target.value);

  // const [tier1InvoiceBreakupData, setTier1InvoiceBreakupData] = useState<Tier1InvoiceBreakupData>();
  const [discountedtier1invoice, setDiscountedTier1Invoice] = useState({} as DiscountableTier1InvoiceInfo);

  useEffect(() => {
    // console.log("hideColumnT",hideColumnT)
  }, []);

  // const [invoicetoupdate, setInvoicetoupdate] = useState();
  const [loader, setLoader] = useState(true);

  /**
   * This is the column definition for the screen.
   */

  // invoice:
  // vendor:
  // invoice_date:
  // invoice_amount:
  // net_amount:
  // early_payment:
  // discount:
  // action:
  // const onDoubleClickNetRecivables = (event: CellDoubleClickedEvent) => {
  //   console.log("the cell is double clicked", event.colDef, event.data);
  //   // const invoiceData: Tier1InvoiceBreakupData = {
  //   //   Advance: 3000,
  //   //   InvoiceAmount: 3000,
  //   //   DebitNote: 3000,
  //   //   NetRecievable: 3000,
  //   // };
  //   // setTier1InvoiceBreakupData(invoiceData);
  //   document.getElementById("modal").style.display = "flex";
  // };

  const columnDefs: any = [
    {
      headerName: "",
      children: [
        { headerName: "Invoice ID", field: "invoice", minWidth: 140 },

        { headerName: "Customer", field: "customer", minWidth: 180 },

        { headerName: "Invoice Date", field: "invoice_date", valueFormatter: dateValueFormatter, minWidth: 180 },
      ],
    },

    // { headerName: "Receivable date", field: "receivable_date", valueFormatter: dateValueFormatter, minWidth: 200 },
    {
      headerName: "Net Receivable Breakup",
      children: [
        {
          headerName: "Invoice Amount (I)",
          field: "invoice_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "invoice_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Debit Note (D)",
          field: "debit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "debit_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Credit Note (C)",
          field: "credit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "credit_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Advance (A)",
          field: "advance_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "advance_amount",
          minWidth: 180,
          // hide: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Net Receivable (N)",
          field: "net_amount",
          cellStyle: { color: "#48AC23" },
          minWidth: 180,
          // onCellDoubleClicked: onDoubleClickNetRecivables,
          headerComponentParams: { plus, setPlus },
          tooltipField: "net_amount",
          // columnGroupShow: 'closed',
        },
      ],
    },

    {
      headerName: "Early Payment Amount",
      field: "early_payment",
      cellStyle: { color: "#4072E3" },
      // minWidth: 180,
      // onCellDoubleClicked: onDoubleClickEarlyPayment,
    },
    {
      headerName: "Discount",
      field: "discount",
      cellStyle: { color: "#4072E3" },
      minWidth: 140,
    },
    {
      headerName: "Utilized by Supplier",
      field: "utilized_by_supplier",
      cellStyle: { color: "#4072E3" },
      minWidth: 140,
    },
    {
      headerName: "Payment Pending With Customer",
      field: "payment_pending_with_customer",
      cellStyle: { color: "#4072E3" },
      // minWidth: 280,
      // headerClass: "grid-header-centered",
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "btnCellRenderer",
      // headerClass: "grid-header-centered",
      // cellClass: "center-cell",
      cellRendererParams: {
        clicked(field: string, rowIndex: number) {
          // async clicked(tier2Invoice: Tier2Invoice, rowIndex: number, imageSource: ImageSourceFiles, selectedData:any, field: string, availableForDiscounting: DiscountableTier1InvoiceInfo[]) {
          console.log("field", field);
          confirmearlypayment(rowIndex);
          // console.log("availableForDiscounting", availableForDiscounting)
          // setSelectedRowInvoiceData(availableForDiscounting[])
          // console.log("selectedRowInvoiceData", selectedRowInvoiceData)
          // console.log("Row Index", rowIndex);
          // setSelectedRowNumber(rowIndex)
          // console.log("data",tier2Invoice, imageSource);
          // console.log("selectedData", selectedData)
          // updateTier1InvoicesForDiscounting()
          // setSignedSrc(imageSource);
          // setInvoicetoupdate(selectedData);
          // setOpenDialog(true);
          // onModalGridReady(selectedData)
          // setSelectedRowInvoiceData(selectedData);
          // console.log("selectedRowInvoiceData", selectedRowInvoiceData)
          document.getElementById("modal").style.display = "flex";
          setLoader(false);
        },
        invoicesAvailableForDiscounting,
      },
      minWidth: 200,
    },
  ];

  const columnDefsModal: ColDef[] = [
    {
      headerName: "Loan Amount",
      field: "loan_payment",
      cellStyle: { color: "#4072E3" },
      minWidth: 150,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Early Payment Amount",
      field: "early_payment",
      cellStyle: { color: "#4072E3" },
      minWidth: 200,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Discount",
      field: "discount",
      cellStyle: { color: "#4072E3" },
      minWidth: 100,
      sortable: false,
      filter: false,
    },

    {
      headerName: "Interest Rate",
      field: "interest",
      cellStyle: { color: "#4072E3" },
      minWidth: 100,
      sortable: false,
      filter: false,
    },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 180,
    sortable: true,
    cellStyle: { color: "#4D4F5C" },
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    tooltipComponent: "customTooltip",
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };
  /**
   * This function renders the UI of the "Details" button at the right most column of the table. A click on this button will pop up a modal summarising all the details pertaining to that invoice. It also has an option to Approve or Decline.
   */
  const frameworkComponents = {
    btnCellRenderer: BtnCellRenderer,
    // agColumnHeader: CustomHeader,
    customTooltip: CustomTooltip,
  };
  /**
   * This method is called to fetch all the invoices which are in pending state. This method run as soon as the screen is loaded.
   */
  const onGridReady = async (params: any) => {
    await axios
      .get<ListTier1InvoicesForDiscountingResponse>("/api/ListTier1InvoicesForDiscounting")
      .then((response) => {
        // setTier2actiondata(response.data);
        console.log("response", response);
        setInvoicesAvailableForDiscounting(response.data.discountableTier1InvoiceList);
        // setNonDiscountedApprovedInvoices(response.data.nonDiscountableTier1InvoiceList);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
    setGridColumnApiT(params.columnApi);
  };

  console.log("invoicesAvailableForDiscounting", invoicesAvailableForDiscounting);

  // const onModalGridReady = (selectedData:any) => {
  //   // check rowIndex and invoice id
  //   console.log("modal open")
  //   console.log("selectedRowNumber", selectedRowNumber)
  //   console.log("invoicetoupdate", selectedData)
  //   setSelectedRowInvoiceData(selectedData)
  //        console.log("selectedRowInvoiceData", selectedRowInvoiceData)
  //   // console.log("invoicesAvailableForDiscounting", invoicesAvailableForDiscounting)
  //   // invoicesAvailableForDiscounting.map((inv, invIndex) => {
  //   //   if(selectedRowNumber === invIndex) {
  //   //     setSelectedRowInvoiceData(inv)
  //   //     console.log("selectedRowInvoiceData", selectedRowInvoiceData)
  //   //   }
  //   // })

  // }

  const closemodal = () => {
    document.getElementById("modal").style.display = "none";
  };

  // const columnDefsModalNetRecievable: ColDef[] = [
  //   { headerName: "Invoice Amount", field: "invoice", minWidth: 100 },
  //   { headerName: "Debit Note", field: "debit_note", minWidth: 100 },
  //   { headerName: "Advance", field: "advance", minWidth: 100 },
  //   { headerName: "NetRecievable", field: "net_recievable", minWidth: 100 },
  // ];
  // const closemodal = () => {
  //   setUploading(false);
  //   setOpenDialog(false);
  // };
  /**
   * This method sieve out the required field from the API response of pending state invoices.
   */

  // const getRowDataForNetRecievableModal = () => {
  //   return [
  //     {
  //       invoice: tier1InvoiceBreakupData.InvoiceAmount,
  //       debit_note: tier1InvoiceBreakupData.DebitNote,
  //       advance: tier1InvoiceBreakupData.Advance,
  //       net_recievable: tier1InvoiceBreakupData.NetRecievable,
  //     },
  //   ];
  // };

  // const totalDebitAmount = (data:any) => {
  //   // let amount = 0;
  //   // console.log("data", data)
  //   if(data.length === 0) {
  //     return 0;
  //   }else {
  //     const total = data.map((item :any) => item.debitAmount).reduce((prev:DineroObject, curr:DineroObject) => MoneyUtil.addMoney(prev, curr));
  //     console.log("total", total)
  //     return formatMoney(total)
  //   }
  // }
  // const totalCreditAmount = (data:any) => {
  //   // let amount = 0;
  //   if(data.length === 0) {
  //     return 0;
  //   }else {
  //     const total = data.map((item :any) => item.creditAmount).reduce((prev:DineroObject, curr:DineroObject) => MoneyUtil.addMoney(prev, curr));
  //     // console.log("total", total)
  //     return formatMoney(total)
  //   }
  // }
  // const totalAdvanceAmount = (data:any) => {
  //   let amount = 0;
  //   if(data.length === 0) {
  //     return amount;
  //   }else {
  //     const total = data.map((item :any) => item.advanceAmount).reduce((prev:DineroObject, curr:DineroObject) => MoneyUtil.addMoney(prev, curr));
  //     // console.log("total", total)
  //     return formatMoney(total)
  //   }
  // }

  const getrowdata = () => {
    return invoicesAvailableForDiscounting.map((inv) => {
      const totalDebitAmount = (data: any) => {
        // let amount = 0;
        // console.log("data", data)
        if (data.length === 0) {
          return {
            amount: 0,
            currency: "INR",
            precision: 2,
          };
        } else {
          const total = data
            .map((item: any) => item.debitAmount)
            .reduce((prev: DineroObject, curr: DineroObject) => MoneyUtil.addMoney(prev, curr));
          // console.log("total", total)
          return total;
        }
      };
      const totalCreditAmount = (data: any) => {
        // let amount = 0;
        if (data.length === 0) {
          return {
            amount: 0,
            currency: "INR",
            precision: 2,
          };
        } else {
          const total = data
            .map((item: any) => item.creditAmount)
            .reduce((prev: DineroObject, curr: DineroObject) => MoneyUtil.addMoney(prev, curr));
          // console.log("total", total)
          return total;
        }
      };
      const totalAdvanceAmount = (data: any) => {
        // let amount = 0;
        if (data.length === 0) {
          return {
            amount: 0,
            currency: "INR",
            precision: 2,
          };
        } else {
          const total = data
            .map((item: any) => item.advanceAmount)
            .reduce((prev: DineroObject, curr: DineroObject) => MoneyUtil.addMoney(prev, curr));
          // console.log("total", total)
          return total;
        }
      };

      return {
        invoice: inv.tier1Invoice.invoiceId,
        // customer: inv.tier1Invoice.anchor.actorInfo.name,
        customer: inv.customer,
        invoice_date: inv.tier1Invoice.invoiceDate,
        invoice_amount: formatMoney(inv.tier1Invoice.invoiceAmount),
        debit_amount: formatMoney(totalDebitAmount(inv.tier1Invoice.tier1DebitNotes)),
        credit_amount: formatMoney(totalCreditAmount(inv.tier1Invoice.tier1CreditNotes)),
        advance_amount: formatMoney(totalAdvanceAmount(inv.tier1Invoice.tier1Advances)),
        net_amount: formatMoney(
          MoneyUtil.addMoney(
            MoneyUtil.subtractMoney(inv.tier1Invoice.invoiceAmount, totalDebitAmount(inv.tier1Invoice.tier1DebitNotes)),
            MoneyUtil.subtractMoney(
              totalCreditAmount(inv.tier1Invoice.tier1CreditNotes),
              totalAdvanceAmount(inv.tier1Invoice.tier1Advances)
            )
          )
        ),
        early_payment: formatMoney(inv.earlyPaymentAmount),
        discount: formatMoney(MoneyUtil.subtractMoney(inv.loanAmount, inv.earlyPaymentAmount)),
        // payment_pending_with_customer: formatMoney(MoneyUtil.subtractMoney(MoneyUtil.addMoney((MoneyUtil.subtractMoney(inv.tier1Invoice.invoiceAmount, totalDebitAmount(inv.tier1Invoice.tier1DebitNotes))), (MoneyUtil.subtractMoney(totalCreditAmount(inv.tier1Invoice.tier1CreditNotes), totalAdvanceAmount(inv.tier1Invoice.tier1Advances)))), inv.loanAmount)),
        // payment_pending_with_customer: "₹0"
        utilized_by_supplier: formatMoney(inv.utilizedBySupplier),
        payment_pending_with_customer: formatMoney(inv.remainingPaymentAmount),

        // payable_date: inv.dueDate,
        // invoice_amount: formatMoney(inv.invoiceAmount),
        // payable_amount: formatMoney(inv.receivableAmount),
        // details: inv,
      };
    });

    // return [
    //   {
    //     invoice: 101105,
    //     customer: "Ather",
    //     invoice_date: "21 February 2022",
    //     invoice_amount: formatMoney(toDineroObject(2000000)),
    //     net_amount: formatMoney(toDineroObject(1400000)),
    //     early_payment: formatMoney(toDineroObject(1300000)),
    //     discount: formatMoney(toDineroObject(100000)),
    //     action: {},
    //   },
    //   {
    //     invoice: 100123,
    //     customer: "Ather",
    //     invoice_date: "22 February 2022",
    //     invoice_amount: formatMoney(toDineroObject(2000000)),
    //     net_amount: formatMoney(toDineroObject(1400000)),
    //     early_payment: formatMoney(toDineroObject(1300000)),
    //     discount: formatMoney(toDineroObject(100000)),
    //     action: {},
    //   },
    // ];
  };

  const checkInterest = (val: any) => {
    if (val === null || val === undefined) {
      return "NA";
    } else {
      return val.toFixed(2) + "%";
    }
  };

  const getModalrowdata = () => {
    return [
      {
        loan_payment: formatMoney(discountedtier1invoice.earlyPaymentAmount),
        early_payment: formatMoney(discountedtier1invoice.loanAmount),
        discount: formatMoney(
          MoneyUtil.subtractMoney(discountedtier1invoice.loanAmount, discountedtier1invoice.earlyPaymentAmount)
        ),
        interest: checkInterest(discountedtier1invoice.bankAnchorInterestRatePercentage),
      },
    ];
  };

  const confirmearlypayment = (rowIndex: number) => {
    // const discontedtier1 = availableForDiscounting?.find((element) => {
    //   console.log("element", element)
    //   console.log("invoiceIdToUpdate", invoiceIdToUpdate)
    //    return element.tier1Invoice.invoiceId === invoiceIdToUpdate;
    //  });

    const discontedtier1 = invoicesAvailableForDiscounting[rowIndex];

    //  discontedtier2.status = "Discounted";
    console.log("DiscountedTier2", discontedtier1);
    setDiscountedTier1Invoice(discontedtier1);
    console.log("discontedtier1", discontedtier1);
  };

  const updateDiscountedTier1Invoice = () => {
    //  await axios.post<UpdateTier1InvoicesForDiscountingResponse>("/api/UpdateTier1InvoicesForDiscounting").then(
    //    (response) => {
    //     console.log("post response", response)
    //    }
    //  ).catch((error) => {
    //     console.log("post error", error.response.data)
    //  })
    document.getElementById("modal").style.display = "none";
    callapi(discountedtier1invoice);
  };

  const callapi = async (discontedtier1: DiscountableTier1InvoiceInfo) => {
    await axios
      .post<UpdateTier1InvoicesForDiscountingResponse>("/api/UpdateTier1InvoicesForDiscounting", discontedtier1)
      .then((response) => {
        console.log("Response", response);
        // onGridReady();
        window.location.reload();
        //    document.getElementById("modal").style.display = "none";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const updateTier1InvoicesForDiscountingRowData = () => {

  // }

  const showColumnT = () => {
    // console.log("gridColumnApiT", gridColumnApiT);
    // console.log("showBtn clicked")
    // console.log("showBtn2 clicked")
    gridColumnApiT.setColumnsVisible(
      ["invoice_amount", "debit_amount", "credit_amount", "advance_amount"],
      hideColumnT
    );
    // console.log("gridColumnApiDownT", gridColumnApiT);
    setHideColumnT(!hideColumnT);
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Invoice Approval", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <button onClick={showColumnT} id="showTier1" style={{ display: "none" }}>
        show
      </button>
      <div>
        <div className="ag-theme-alpine mygrid" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
          {loader && <Loader />}
          <AgGridReact
            enableCellTextSelection={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponents}
            onGridReady={onGridReady}
            rowData={getrowdata()}
            domLayout="autoHeight"
            tooltipShowDelay={0}
            // tooltipHideDelay={2000}
            rowClassRules={{
              highlight(params) {
                return params.data.invoice === "KEINV1234";
              },
            }}
          />
        </div>

        <div className="modal" id="modal">
          <div className="modal-background" onClick={closemodal} />{" "}
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                Are you sure want to take early <br /> payment on this invoice ?
              </p>
              <button className="delete" aria-label="close" onClick={closemodal} />
            </header>
            <section className="modal-card-body">
              {/* <p>
                Are you sure want to take early payment on this invoice ?
              </p> */}

              <AgGridReact
                enableCellTextSelection={false}
                suppressMenuHide={false}
                columnDefs={columnDefsModal}
                defaultColDef={defaultColDef}
                // frameworkComponents={frameworkComponents}
                // onGridReady={onModalGridReady}

                rowData={getModalrowdata()}
                domLayout="autoHeight"
                // rowClassRules={{
                //   highlight(params) {
                //     return params.data.invoice === "KEINV1234";
                //   },
                // }}
              />
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success" onClick={updateDiscountedTier1Invoice}>
                Confirm
              </button>
              <button className="button is-danger" onClick={closemodal}>
                Decline
              </button>
            </footer>
          </div>
        </div>
      </div>
    </LoggedInSkeleton>
  );
};

export default Tier1EarlyPaymentPage;
