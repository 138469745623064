import React from "react";
import "./../Tier2EarlyPayment/Tier2EarlyPayment.scss";
import { Tier1BlockData } from "./Tier1Settings";
interface BtnCellRendererProps {
  /** This property takes care of clicking */
  clicked: (arg0: Tier1BlockData, rowIndex: number, btnType: string) => void;
  value: Tier1BlockData;
  // suspend:boolean
  rowIndex: any;
  data: any;
  suspend: any;
  stopDays: any;
}

/**
 * @Component
 * Button Component for blocking Tier2 via Tier1
 * checks how states are handled
 */
export class BtnCellRenderer extends React.Component<BtnCellRendererProps, any> {
  constructor(props: BtnCellRendererProps) {
    super(props);
    console.log("suspend1", this.props);
    console.log("rowIndex1", this.props.rowIndex);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
    // this.state = {
    //   suspend: this.props.suspend ,
    //   rowIndex: this.props.rowIndex
    // };
  }

  /** This is button click handler
   * @public
   */
  public btnClickedHandler() {
    // let selectedData = this.params.api.getSelectedRows();
    // console.log(selectedData);
    console.log("suspend2", this.props);
    console.log("rowIndex2", this.props.rowIndex);
    const btnType = this.props.value.suspend[this.props.rowIndex].suspend1 ? "suspend" : "enable";
    console.log("suspend1", this.props.value.suspend[this.props.rowIndex].suspend1);

    this.props.clicked(this.props.value, this.props.rowIndex, btnType);

    // this.setState({ suspend: !this.state.suspend })
  }

  render() {
    return (
      <div>
        {/* {this.props.value.suspend[this.props.rowIndex].suspend1 ? ( */}
        {/* {this.props.data.suspended_supplier.suspend[this.props.rowIndex].suspend1 ? ( */}

        {this.props.suspend[this.props.rowIndex].suspend1 ? (
          <button className="button is-danger" onClick={this.btnClickedHandler} style={{ width: "100%" }}>
            Suspend
          </button>
        ) : (
          <button className="button is-success" onClick={this.btnClickedHandler} style={{ width: "100%" }}>
            Enable
          </button>
        )}
      </div>
    );
  }
}
