import React from "react";

const CustomTooltip = (props: any) => {
  //   const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);
  //   console.log("data", data);

  props.reactContainer.classList.add("custom-tooltip");

  // let data = props.api.getDisplayedRowAtIndex(props.rowIndex).data;

  return (
    <div className="custom-tooltip" style={{ backgroundColor: props.color || "white" }}>
      {/* <p>
        <span>{data.athlete}</span>
      </p> */}
      <div style={{ boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px" }}>
        <h6>Net Receivable Breakup</h6>
        <p>(I) Invoice Amount | (D) Debit Note | (C) Credit Note | (A) Advance | (N) Net Receivable</p>
        <p>Net Receivable (N) is calculated using the formulae: N = I + C - D - A</p>
        {/* {data.net_payment} */}
      </div>
      {/* <p>
        <span>Total: </span> {data.total}
      </p> */}
    </div>
  );
};

export default CustomTooltip;
