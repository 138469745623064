import { ColDef } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { DineroObject } from "dinero.js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { formatDate } from "../../Utils/DateUtils";
import { formatMoney } from "../../Utils/MoneyUtils";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
import BtnCellRenderer from "./BtnCellRenderer";

export interface AnchorAuditTrailInfo {
  InvoiceId: string;
  InvoiceDate: Date;
  Supplier: string;
  InvoiceAmount: DineroObject;
  DebitNote: DineroObject;
  Advance: DineroObject;
  CreditNote: DineroObject;
  NetPayment: DineroObject;
  LendersShare: DineroObject;
  SuppliersShare: DineroObject;
  SupplierSOAAdjustment: string;
}

export interface AnchorAuditTrailResponse {
  AnchorAuditTrailInfoList: AnchorAuditTrailInfo[];
}

/**
 * This interface is used to interact with BtnCellRenderer Component.
 */
export interface EntryAdjusted {
  anchorInvoiceId: string;
  tier2InvoiceId: string;
  anchorId: number;
  tier1Id: number;
  tier2Id: number;
  anchorAdjustment: string;
}

/**
 * This is the interface for CollectionRowThis is the interface for CollectionRow
 * @interface
 *
 */
export interface CollectionRow {
  invoice: string;
  supplier: string;
  invoice_date: Date;
  payable_amount: string;
  supplier_payable: string;
  nakad_payable: string;
  entryAdjusted?: EntryAdjusted;
}

export interface RowData {
  invoice: string;
}

/**
 * @Component
 * Anchor Component for Anchor Entry Adjustment tab. It contains two sub tabs - pending and completed.
 *
 * 1.Pending - All the anchor invoices(Tier1Invoices) which were matched against a Tier2Invoice. The loan for the mapping has been sanctioned and disbursed. This tab represents the invoices which are due to be adjusted in Anchor's ERP.
 *
 * 2.Completed - All the anchor invoices(Tier1Invoices) which are adjusted in ERP.
 *
 * This component uses Ag grid library to demostrate the data in tabular form.
 */
const AnchorAuditTrail = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const [anchorAuditTrailOpenData, setAnchorAuditTrailOpenData] = useState([] as AnchorAuditTrailInfo[]);
  const [anchorAuditTrailSettledData, setAnchorAuditTrailSettledData] = useState([] as AnchorAuditTrailInfo[]);
  const [loaderOpen, setLoaderOpen] = useState(true);
  const [loaderSettled, setLoaderSettled] = useState(true);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [hideColumn, setHideColumn] = useState(true);
  const [plus, setPlus] = useState(true);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const history = useHistory();
  const { pathParam } = useParams<{ pathParam?: string }>();
  const DEFAULT_ACTIVE_TAB = "Open";
  const pathWay = "/anchor/audit";

  /**
   * react hook
   * runs first time only when dependency array is empty
   * if empty array not given than it runs everytime on the change dependency array
   * dependency array defines variable on which use effect is used e.g: api call on first load of page
   * @public
   */
  useEffect(() => {
    if (!pathParam) {
      history.push(`${pathWay}/${DEFAULT_ACTIVE_TAB}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (activeTab: string) => {
    if (pathParam !== activeTab) {
      history.push(`${pathWay}/${activeTab}`);
    }
  };

  /** This is a column definition of the grid on Anchor Entry Adjustment page - Pending sub-tab
   * @public
   */

  const colElements = Array.from(document.getElementsByClassName("ag-header-cell") as HTMLCollectionOf<HTMLElement>);

  console.log("ele", colElements);

  const colDefns: any = [
    { headerName: "Invoice ID", field: "invoice_id", minWidth: 160 },
    { headerName: "Supplier", field: "supplier", minWidth: 180 },
    {
      headerName: "Invoice date",
      field: "invoice_date",
      minWidth: 180,
    },
    {
      headerName: "Expand",
      children: [
        {
          headerName: "Invoice Amount",
          field: "invoice_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "invoice_amount",
          minWidth: 180,
          columnGroupShow: "open",
        },
        {
          headerName: "Debit Note",
          field: "debit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "debit_amount",
          minWidth: 180,
          columnGroupShow: "open",
        },
        {
          headerName: "Credit Note",
          field: "credit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "credit_amount",
          minWidth: 180,
          columnGroupShow: "open",
        },
        {
          headerName: "Advance",
          field: "advance_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "advance_amount",
          minWidth: 180,
          columnGroupShow: "open",
        },
        {
          headerName: "Net Payment",
          field: "net_payment",
          cellStyle: { color: "#48AC23" },
          minWidth: 180,
          headerComponentParams: { plus, setPlus },
          tooltipField: "net_payment",
        },
      ],
    },

    {
      headerName: "Lender's Share",
      field: "lender_payable",
      headerTooltip: "Net amount payable to the lender",
      cellStyle: { color: "#48AC23" },
      minWidth: 200,
    },
    {
      headerName: "Supplier's Share",
      field: "supplier_payable",
      headerTooltip: "Net amount payable to the supplier",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Supplier SOA Adjustment",
      field: "supplier_acknowledgment",
      cellStyle: (params: any) => (params.value === "No" ? { color: "red" } : { color: "#48AC23" }),
      minWidth: 180,
    },
  ];

  /** This is a column definition of the grid on Anchor Entry Adjustment page - Completed sub-tab
   * @public
   */
  const settledColDefns: any = [
    { headerName: "Invoice ID", field: "invoice_id", minWidth: 160 },
    { headerName: "Supplier", field: "supplier", minWidth: 180 },
    {
      headerName: "Invoice date",
      field: "invoice_date",
      minWidth: 180,
    },
    {
      headerName: "Expand",
      children: [
        {
          headerName: "Invoice Amount",
          field: "invoice_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "invoice_amount",
          minWidth: 180,
          columnGroupShow: "open",
        },
        {
          headerName: "Debit Note",
          field: "debit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "debit_amount",
          minWidth: 180,
          columnGroupShow: "open",
        },
        {
          headerName: "Credit Note",
          field: "credit_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "credit_amount",
          minWidth: 180,
          columnGroupShow: "open",
        },
        {
          headerName: "Advance",
          field: "advance_amount",
          cellStyle: { color: "#48AC23", backgroundColor: "#c6e3f8" },
          headerClass: "advance_amount",
          minWidth: 180,
          columnGroupShow: "open",
        },
        {
          headerName: "Net Payment",
          field: "net_payment",
          cellStyle: { color: "#48AC23" },
          minWidth: 180,
          headerComponentParams: { plus, setPlus },
        },
      ],
    },

    {
      headerName: "Lender's Share",
      field: "lender_payable",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Supplier's Share",
      field: "supplier_payable",
      cellStyle: { color: "#48AC23" },
      minWidth: 180,
    },
    {
      headerName: "Supplier SOA Adjustment",
      field: "supplier_acknowledgment",
      cellStyle: (params: any) => (params.value === "No" ? { color: "red" } : { color: "#48AC23" }),
    },
  ];

  const defaultColDef: ColDef = {
    flex: 2,
    minWidth: 200,
    sortable: true,
    resizable: true,
    wrapText: true,
    filter: true,
    autoHeight: true,
    cellStyle: { color: "#4D4F5C" },
    tooltipComponent: "customTooltip",
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };

  /**
   * Button Component on pending sub-tab to confirm adjustment of entry in ERP.
   */
  const frameworkComponents = {
    btnCellRenderer: BtnCellRenderer,
    // agColumnHeader: CustomHeader,
    // customTooltip: CustomTooltip,
  };

  /**
   * This handler is called on the initial rendering of the screen. It fetches all the list of anchor invoices against which loan has been sanctioned and disbursed.
   * @public
   */
  const onGridReadyOpen = async (params: any) => {
    await axios
      .get<AnchorAuditTrailResponse>("/api/ListAnchorAuditTrail", {
        params: {
          screenType: "open",
        },
      })
      .then((response) => {
        console.log("response", response.data.AnchorAuditTrailInfoList);
        setAnchorAuditTrailOpenData(response.data.AnchorAuditTrailInfoList);
        setLoaderOpen(false);
      })
      .catch((error) => {
        console.log("error", error);
      });

    setGridColumnApi(params.columnApi);
    console.log("setGridColumnApi", params.columnApi);
    console.log("setGridColumnApi", gridColumnApi);
  };

  const onGridReadySettled = async (params: any) => {
    await axios
      .get<AnchorAuditTrailResponse>("/api/ListAnchorAuditTrail", {
        params: {
          // screenType: pathParam.toLocaleLowerCase()
          screenType: "settled",
        },
      })
      .then((response) => {
        console.log("response", response.data.AnchorAuditTrailInfoList);
        setAnchorAuditTrailSettledData(response.data.AnchorAuditTrailInfoList);
        setLoaderSettled(false);
      })
      .catch((error) => {
        console.log("error", error);
      });

    setGridColumnApi(params.columnApi);
    console.log("setGridColumnApi", params.columnApi);
    console.log("setGridColumnApi", gridColumnApi);
  };

  /**
   * This method returns the data which is published on the pending sub-tab of the screen.
   * @public
   */
  const getOpenRowData = () => {
    return anchorAuditTrailOpenData.map((inv) => {
      return {
        invoice_id: inv.InvoiceId,
        supplier: inv.Supplier,
        invoice_date: formatDate(inv.InvoiceDate),
        invoice_amount: formatMoney(inv.InvoiceAmount),
        debit_amount: formatMoney(inv.DebitNote),
        credit_amount: formatMoney(inv.CreditNote),
        advance_amount: formatMoney(inv.Advance),
        net_payment: formatMoney(inv.NetPayment),
        lender_payable: formatMoney(inv.LendersShare),
        supplier_payable: formatMoney(inv.SuppliersShare),
        supplier_acknowledgment: inv.SupplierSOAAdjustment,
      };
    });
  };
  /**
   * This method returns the data which is published on the completed sub-tab of the screen.
   * @public
   */
  const getSettledRowData = () => {
    return anchorAuditTrailSettledData.map((inv) => {
      return {
        invoice_id: inv.InvoiceId,
        supplier: inv.Supplier,
        invoice_date: formatDate(inv.InvoiceDate),
        invoice_amount: formatMoney(inv.InvoiceAmount),
        debit_amount: formatMoney(inv.DebitNote),
        credit_amount: formatMoney(inv.CreditNote),
        advance_amount: formatMoney(inv.Advance),
        net_payment: formatMoney(inv.NetPayment),
        lender_payable: formatMoney(inv.LendersShare),
        supplier_payable: formatMoney(inv.SuppliersShare),
        supplier_acknowledgment: inv.SupplierSOAAdjustment,
      };
    });
  };

  /**
   * @public
   * This method is called to signal our application that Anchor has updated it's ERP. It also sends a notification to bank detailing the same.
   */

  const showColumn = () => {
    console.log("gridColumnApi", gridColumnApi);
    gridColumnApi.setColumnsVisible(["invoice_amount", "debit_amount", "credit_amount", "advance_amount"], hideColumn);
    console.log("gridColumnApiDown", gridColumnApi);
    setHideColumn(!hideColumn);
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Anchor Landing", actor.name, location?.state?.openCollapseOb, actor)}
    >
      <button onClick={showColumn} id="show" style={{ display: "none" }}>
        show
      </button>
      <div className="tabs  is-toggle" style={{ marginLeft: "2vw" }}>
        <ul>
          <li className={`${pathParam === "Open" ? "is-active" : " "}`} onClick={() => toggle("Open")}>
            <a>
              <span>Open</span>
            </a>
          </li>
          <li className={`${pathParam === "Settled" ? "is-active" : " "}`} onClick={() => toggle("Settled")}>
            <a>
              <span>Settled</span>
            </a>
          </li>
        </ul>
      </div>
      {pathParam === "Open" && (
        <div style={containerStyle}>
          <div className="ag-theme-alpine mygrid" id="table-1" style={gridStyle}>
            {loaderOpen && <Loader />}
            <AgGridReact
              enableCellTextSelection={true}
              suppressMenuHide={true}
              defaultColDef={defaultColDef}
              frameworkComponents={frameworkComponents}
              onGridReady={onGridReadyOpen}
              rowData={getOpenRowData()}
              columnDefs={colDefns}
              domLayout="autoHeight"
              tooltipShowDelay={0}
              // tooltipHideDelay={2000}
              // components={components}
            />
          </div>
        </div>
      )}
      {pathParam === "Settled" && (
        <div className="ag-theme-alpine mygrid" id="table-1" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
          {loaderSettled && <Loader />}
          <AgGridReact
            enableCellTextSelection={true}
            suppressMenuHide={true}
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponents}
            onGridReady={onGridReadySettled}
            rowData={getSettledRowData()}
            columnDefs={settledColDefns}
            domLayout="autoHeight"
            // components={components}
          />
        </div>
      )}
      <div className="column" style={{ paddingLeft: "2vw" }}>
        <input
          className="button is-bold is-success theme_btn"
          type="button"
          value="Download"
          onClick={() => {
            // gridApi.exportDataAsCsv({ fileName: actor.name + "_Adjustment_" + pathParam });
          }}
        />
      </div>
    </LoggedInSkeleton>
  );
};

export default AnchorAuditTrail;
