import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormLabel,
  List,
  ListItem,
  styled,
  Switch,
  Tooltip,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FrontendActor, PathType } from "../../entity/models/FrontendActor";
import Logo from "../../Graphics/icon.png";
import { userContext } from "../Contexts/userContext";
// import uiLogger from "../Recon360/PartnerCommunication/UiLogger";
import { NavBarStructure } from "./Constants";
import { getAdminTopBarButtons, getBankTopBarButtons, getReconTopBarButtons } from "./TopNavBar";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export const getTopBarButtons: (actor: FrontendActor) => NavBarStructure[] = (actor: FrontendActor) => {
  const path = ActorTypeToPathType(actor);

  if (!actor) return;

  if (path === PathType.admin) {
    return getAdminTopBarButtons("Register");
  } else if (path === PathType.bank) {
    return getBankTopBarButtons("None");
  } else if (path === PathType.recon) {
    return getReconTopBarButtons("", "", {}, actor);
  } else return getReconTopBarButtons("", "", {}, actor);
};

const isOverFlowingY = (element: Element) => {
  return element.scrollHeight > element.clientHeight;
};

export function ActorTypeToPathType(actor: FrontendActor, truePath = false): PathType {
  if (!actor) return;
  if (actor.actorType === "Admin") {
    return PathType.admin;
  } else if (
    actor.actorType === "Tier1" ||
    actor.actorType === "Tier2" ||
    actor.actorType === "Anchor" ||
    actor.actorType === "GroupedPartner"
  ) {
    if (truePath) {
      const accountName = actor.name.toLowerCase().replace(/[^a-z\d]+/gi, "");
      const path = PathType.recon.replace(":accountName", accountName) as PathType;
      return path;
    } else return PathType.recon;
  } else if (actor.actorType === "Bank") {
    return PathType.bank;
  }
  return null;
}

export function redirect(actor: FrontendActor, history: ReturnType<typeof useHistory>, returnPath?: boolean) {
  let path = ActorTypeToPathType(actor);

  if (path === PathType.recon) {
    const accountName = actor.name.toLowerCase().replace(/[^a-z\d]+/gi, "");
    path = PathType.recon.replace(":accountName", accountName) as PathType;
  }

  if (!path) alert(actor.actorType + " not implemented");

  if (returnPath) return path;
  else history.push(path);
}

const HOME_ROUTE = "#home";

const SideBar = () => {
  const location = useLocation<any>();
  const history = useHistory<any>();
  const { actor } = useContext(userContext);
  const sidebar = useRef<HTMLDivElement>(null);
  const [mini, setMini] = useState(false);

  // Function to hide the sidebar when the mouse leaves
  const hideSidebar = () => {
    if (sidebar.current?.classList.contains("hoverable")) {
      sidebar.current?.classList.add("close");
    }

    const menuBox = sidebar.current?.querySelector(".menu_container");

    if (menuBox) {
      menuBox.classList.toggle("overflowing", isOverFlowingY(menuBox));
    }
  };

  // Function to show the sidebar when the mouse enter
  const showSidebar = () => {
    if (sidebar.current?.classList.contains("hoverable") && !mini) {
      sidebar.current?.classList.remove("close");
    }

    const menuBox = sidebar.current?.querySelector(".menu_container");

    if (menuBox) {
      menuBox.classList.toggle("overflowing", isOverFlowingY(menuBox));
    }
  };

  if (!navigator?.platform?.includes("Mac")) {
    document.documentElement.classList.add("NonMac");
    document.body.classList.add("NonMacBody");
  }

  console.log("actor location sideBarButtons", actor, location, getTopBarButtons(actor));

  if (location?.pathname === "/") {
    document.title = "NAKAD | Pioneers of tokenized supply chain finance";
  }

  return (
    <>
      {actor?.name &&
        location?.pathname !== "/" &&
        location?.pathname !== "/reset" &&
        !location?.pathname.includes("/confirmBalanceFromMail") && (
          <nav
            key={"fixed"}
            ref={sidebar}
            className="sidebar locked hoverable close"
            onMouseEnter={showSidebar}
            onMouseLeave={hideSidebar}
            onTouchStartCapture={showSidebar}
          >
            <div className="logo_items flex">
              <Link className="nav_image" to={HOME_ROUTE} onClick={() => redirect(actor, history)}>
                {/* <img src="https://images.unsplash.com/photo-1636114673156-052a83459fc1" /> */}
                <img src={Logo} alt="Logo" />
              </Link>
              <Link className="logo_name" to={HOME_ROUTE} onClick={() => redirect(actor, history)}>
                NAKAD
              </Link>
              <i className="bx nav_image" />
            </div>

            <div className="menu_container">
              <div className="menu_items">
                {actor?.name &&
                  getTopBarButtons(actor).map((btn, _i) => {
                    return btn.hasSubTab ? (
                      <React.Fragment key={btn.name + _i}>
                        <List className="menu_group" disablePadding={true}>
                          <Accordion
                            sx={{
                              background: "transparent !important",
                              boxShadow: "unset !important",
                              m: "0 !important",
                            }}
                            defaultExpanded={
                              btn.subMenu.findIndex((sub) =>
                                location.pathname.toLowerCase().includes(sub.to.toLowerCase())
                              ) > -1
                            }
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              sx={{
                                m: "0 2px !important",
                                minHeight: "unset !important",
                                p: "0 !important",
                                "& > div": {
                                  m: "0 !important",
                                },
                                justifyContent: "flex-start",
                              }}
                              // onClick={btn?.eventName ? () => uiLogger(btn.eventName) : null}
                            >
                              <div className="menu_title flex">
                                <Tooltip title={mini ? btn.name : ""} arrow={true} placement="right">
                                  <span>{btn.icon ? btn.icon : null}</span>
                                </Tooltip>
                                <span className="text_title">{btn.name}</span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: "0 !important" }}>
                              {btn.subMenu?.map((subMenu, _j) => (
                                <ListItem
                                  className="list_item"
                                  key={subMenu.name + _j}
                                  disablePadding={true}
                                  sx={{ display: "block" }}
                                >
                                  <Tooltip title={mini ? subMenu.name : ""} arrow={true} placement="right">
                                    <Link
                                      className={
                                        location.pathname.toLowerCase().includes(subMenu.to.toLowerCase())
                                          ? "link flex active"
                                          : "link flex "
                                      }
                                      to={subMenu.to}
                                      // onClick={subMenu?.eventName ? () => uiLogger(subMenu.eventName) : null}
                                    >
                                      {subMenu?.icon ? subMenu?.icon : <i className="fa-regular fa-square" />}
                                      <span>{subMenu.name}</span>
                                    </Link>
                                  </Tooltip>
                                </ListItem>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </List>
                        <div className="sidebar_profile flex" />
                      </React.Fragment>
                    ) : (
                      <li className="list_item" key={btn.name + _i}>
                        <Tooltip title={mini ? btn.name : ""} arrow={true} placement="right">
                          <Link
                            className={
                              location.pathname.toLowerCase().includes(btn.to.toLowerCase())
                                ? "link flex active"
                                : "link flex "
                            }
                            to={btn.to}
                            // onClick={btn?.eventName ? () => uiLogger(btn.eventName) : null}
                          >
                            {btn?.icon ? btn?.icon : <i className="fa-regular fa-square" />}
                            <span>{btn.name}</span>
                          </Link>
                        </Tooltip>
                      </li>
                    );
                  })}
              </div>
            </div>

            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexFlow: "column",
                justifyContent: "center",
                width: 60,
                alignItems: "center",
              }}
            >
              <FormLabel sx={{ fontSize: 10 }}>Mini</FormLabel>
              <Android12Switch checked={mini} onChange={() => setMini(!mini)} />
            </Box>
          </nav>
        )}
    </>
  );
};

export default SideBar;
