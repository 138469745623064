import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { DineroObject } from "dinero.js";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../../node_modules/@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css";
import { BankApprovalStatusStrings, LoanStatus, LoanType } from "../../entity/models/UtilityInterfaces";
import { Tier1EarlyPayment } from "../../entity/Tier1EarlyPayment";
import { Tier1Tier2InvoiceMapping } from "../../entity/Tier1Tier2InvoiceMapping";
import { formatDate, formatDateToTime } from "../../Utils/DateUtils";
import { formatMoney } from "../../Utils/MoneyUtils";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getBankTopBarButtons } from "../Common/TopNavBar";
import "./BankLoanDetails.scss";
import { BankRejectionFields, BankRejectionModal } from "./BankRejectionModal";

export interface UpdateLoanForBankApprovalResponse {
  tier1Tier2Mapping?: Tier1Tier2InvoiceMapping;
  tier1EarlyPayment?: Tier1EarlyPayment;
}

export interface LoanInfo {
  loan_Id: number;
  loanType: LoanType;
  bankApprovalStatus: LoanStatus;
  loanAmount: DineroObject;
  discount: DineroObject;
  EarlyPayment: DineroObject;
  Tier1InvoiceId: string;
  Tier1InvoiceAmount: DineroObject;
  Tier1InvoiceDueDate: Date;
  Tier1InvoiceTotalDebitAmount: DineroObject;
  Tier1InvoiceTotalCreditAmount: DineroObject;
  Tier1InvoiceTotalAdvanceAmount: DineroObject;
  Tier1InvoiceNetReceivable: DineroObject;
  Tier2InvoiceId: string;
  Tier2InvoiceAmount: DineroObject;
  Tier2InvoiceDueDate: Date;
  Tier2InvoiceTotalDebitAmount: DineroObject;
  Tier2InvoiceTotalCreditAmount: DineroObject;
  Tier2InvoiceTotalAdvanceAmount: DineroObject;
  Tier2InvoiceNetReceivable: DineroObject;
  InterestRate: number;
  anchorCreationDate: Date;
  tier1CreationDate: Date;
  tier1: string;
  anchor: string;
}

export interface GetLoanByIdResponse {
  loan: LoanInfo;
}

export interface BankLoanDetailsProps {
  match: {
    params: {
      loanId: string;
      // loanId
    };
  };
}
/**
 * This method displays all the loan related information for a particular loan. The UI constitutes of three sections -
 *
 * 1. Overall Informartion - Summary of the loan.
 *
 * 2. Customer Documents - Information related to Tier2 Invoice
 *
 * 3. Buyer Documents - Information related to Tier1 Invoice.
 *
 * @param props {BankLoanDetailsProps}
 * @Component
 */
const BankLoanDetails = (props: BankLoanDetailsProps) => {
  console.log("props BankLoanDetails", props);
  const [loan, setLoan] = useState(null as any);
  const [loader, setLoader] = useState(true);
  // const [bankLoanDetailstier1, setBankLoanDetailstier1] = useState();
  // const [bankLoanDetailstier1Tier2Mapping, setBankLoanDetailstier1Tier2Mapping] = useState();
  // const [loan, setLoan] = useState(null as Loan);
  // const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [openRejectionModal, setOpenRejectionModal] = useState<boolean>(false);
  const history = useHistory();
  // const { actor } = useContext(userContext);

  useEffect(() => {
    axios
      .get<GetLoanByIdResponse>("/api/GetLoanById", {
        // check this any
        // diff api
        params: { loanId: props.match.params.loanId },
        // fetch all details from loanId
      })
      .then((response) => {
        console.log("response", response.data.loan);
        setLoan(response.data.loan);
        setLoader(false);
        // setBankLoanDetailstier1(response.data.loan.tier1EarlyPayment)
        // console.log("bankLoanDetailstier1", bankLoanDetailstier1)
        // setBankLoanDetailstier1Tier2Mapping(response.data.loan.tier1Tier2InvoiceMapping)
        // console.log("bankLoanDetailstier1Tier2Mapping", bankLoanDetailstier1Tier2Mapping)

        // const foundLoan = response.data.loans.find(
        //   (l:any) => l.loanId === props.match.params.loanId,
        //   // check loan type if  l.tier1Invoice
        //   //else l.

        //   // divide page into 2 depend on type
        //   // set tier1 details in one state in one compenent
        //   // set tier2 details in other component
        //   // from that state show all data in jsx
        // );
        const foundLoan = response.data.loan;
        if (foundLoan !== undefined) {
          // console.log(foundLoan.not)
          setLoan(foundLoan);
          // setBankLoanDetailstier1(foundLoan.tier1EarlyPayment)
          // setBankLoanDetailstier1Tier2Mapping(foundLoan.tier1Tier2InvoiceMapping)
        }
        // console.log(foundLoan.tier1Tier2InvoiceMapping);
      });
  }, [props.match.params.loanId]);
  /**
   * This handler is called when bank sanctions the loan. It updates the requested loan as Approved in our system and send notification to all the parties involved regarding the same.
   * @param status {BankApprovalStatus}
   */
  console.log("foundLoan", loan);
  //  console.log("bankLoanDetailstier1", bankLoanDetailstier1)
  //  console.log("bankLoanDetailstier1Tier2Mapping", bankLoanDetailstier1Tier2Mapping)
  const onClickHandler = async (status: BankApprovalStatusStrings) => {
    // check is this correct or BankApprovalStatus need to use this
    if (status === "Approved") {
      setUploading(true);
      if (loan.loanType === "Tier1EarlyPayment") {
        loan.bankApprovalStatus = status;
        const { data } = await axios.post<UpdateLoanForBankApprovalResponse>(
          // invoice ==> loan
          "/api/UpdateLoanForBankApproval",
          loan
        );
        console.log(data);
      } else {
        loan.bankApprovalStatus = status;
        const { data } = await axios.post<UpdateLoanForBankApprovalResponse>(
          // invoice ==> loan
          "/api/UpdateLoanForBankApproval",
          loan
        );
        console.log(data);
      }

      // const notification = createNotification(status);
      // await PushNotification({
      //   notification: createNotification(
      //     loan.tier1Tier2InvoiceMapping.tier2Id,
      //     `${Dinero(loan.tier1Tier2InvoiceMapping.loanAmount).toFormat("$0,0")}  has been ${status} by ${actor.name}.`,
      //     status
      //   ),
      // });
      // await PushNotification({
      //   notification: createNotification(
      //     loan.tier1Tier2InvoiceMapping.tier1Id,
      //     `Your supplier ${loan.tier1Tier2InvoiceMapping.tier2.actorInfo.name} has received early payment for invoice ${loan.tier1Tier2InvoiceMapping.tier2InvoiceId}`,
      //     "Payables"
      //   ),
      // });
      // await PushNotification({
      //   notification: createNotification(
      //     loan.tier1Tier2InvoiceMapping.tier1Id,
      //     `Your supplier ${loan.tier1Tier2InvoiceMapping.tier2.actorInfo.name} has received early payment for invoice ${loan.tier1Tier2InvoiceMapping.tier2InvoiceId}`,
      //     "Receivables"
      //   ),
      // });
      // await PushNotification({
      //   notification: createNotification(
      //     loan.tier1Tier2InvoiceMapping.anchorId,
      //     `Early payment has been disbursed on ${loan.tier1Tier2InvoiceMapping.tier1Invoice.tier1.actorInfo.name} invoice# ${loan.tier1Tier2InvoiceMapping.tier1InvoiceId}  by ${actor.name}.`,
      //     "Anchor-Adjustment"
      //   ),
      // });

      history.push("/bank/disbursement");
    } else if (status === "Rejected") {
      setOpenRejectionModal(true);
    }
  };
  // const createNotification = (id: number, msg: string, status?: any): Notification => {
  //   const addedString = status === "Approved" ? "Please adjust the accounting entry for same" : "";
  //   const notification: Notification = {
  //     actorId: id,
  //     notificationDate: new Date(),
  //     notificationDetails: {
  //       notification_type: "Bank",
  //       notification_message: msg + addedString,
  //       notification_data: {
  //         status,
  //       },
  //     },
  //   } as unknown as Notification;
  //   return notification;
  //   // const { data } = await axios.post("/api/PostNotification", notification);
  //   // console.log(data);
  // };
  const backHandler = () => {
    history.goBack();
  };
  /**
   * This handler is called when bank declines the requested loan. It updates the requested loan as Declined in our system and send the notification along with reasons for rejection (if any).
   * @param rejectedFields {BankRejectionFields}
   */
  const rejectionHandler = async (rejectedFields: BankRejectionFields) => {
    console.log("Rejecting...");
    if (loan.loanType === "Tier1EarlyPayment") {
      loan.bankApprovalStatus = "Rejected";
      loan.rejectedOf = "Tier1";
    } else {
      loan.bankApprovalStatus = "Rejected";
      loan.rejectedOf = rejectedFields.rejectedOff;
    }
    // loan.rejectedOf = rejectedFields.rejectedOff;
    loan.rejectionRemarks = rejectedFields.rejectionRemarks;
    await axios.post<UpdateLoanForBankApprovalResponse>("/api/UpdateLoanForBankApproval", loan);
    // if (loan.rejectedOf === "Tier1") {
    //   await PushNotification({
    //     notification: createNotification(
    //       loan.tier1Tier2InvoiceMapping.anchorId,
    //       `Invoice Number ${loan.tier1Tier2InvoiceMapping.tier1InvoiceId} of amount ${Dinero(
    //         loan.tier1Tier2InvoiceMapping.tier1Invoice.invoiceAmount
    //       ).toFormat("$0,0")}  has been rejected by ${actor.name}.`,
    //       "RejectedDueToTier1"
    //     ),
    //   });
    // } else if (loan.rejectedOf === "Tier2") {
    //   await PushNotification({
    //     notification: createNotification(
    //       loan.tier1Tier2InvoiceMapping.tier2Id,
    //       `Invoice Number ${loan.tier1Tier2InvoiceMapping.tier2InvoiceId} of amount ${Dinero(
    //         loan.tier1Tier2InvoiceMapping.tier2Invoice.invoiceAmount
    //       ).toFormat("$0,0")}  has been rejected by ${actor.name}.`,
    //       "RejectedDueToTier2"
    //     ),
    //   });
    // }
    setOpenRejectionModal(false);
    history.push("/bank/disbursement");
    console.log("Done");
  };
  // const [visibleDialog, setVisibleDialog] = useState<string>("");
  // const convertDebitNoteAmountIntoDinero = (money: number) => {
  //   const dinero: DineroObject = {
  //     amount: money,
  //     precision: 2,
  //     currency: "INR",
  //   };

  //   return formatMoney(dinero);
  // };
  const toolTipInfo = (info: string) => {
    console.log("LOANS", loan);
    if (loan.loanType === "Tier1EarlyPayment") {
      console.log("loan inside if", loan);
      const verfiedBy =
        // loan.tier1Tier2InvoiceMapping.tier2Invoice.uploadedBy === "Tier1"
        //   ? loan.tier1Tier2InvoiceMapping.tier1.actorInfo.name
        //   :
        loan.anchor;
      return info === "Customer"
        ? `\tVerification Details\nUsername : ${verfiedBy}\nCreation Date : ${formatDateToTime(loan.tier1CreationDate)}`
        : `\tVerification Details\nUsername : ${loan.anchor}\nCreation Date : ${formatDateToTime(
            loan.anchorCreationDate
          )}`;
    } else if (loan.loanType === "Tier2EarlyPayment") {
      const verfiedBy =
        // loan.tier1Tier2InvoiceMapping.tier2Invoice.uploadedBy === "Tier1"
        //   ? loan.tier1Tier2InvoiceMapping.tier1.actorInfo.name
        //   :
        loan.tier1;
      return info === "Customer"
        ? `\tVerification Details\nUsername : ${verfiedBy}\nCreation Date : ${formatDateToTime(loan.tier1CreationDate)}`
        : `\tVerification Details\nUsername : ${loan.anchor}\nCreation Date : ${formatDateToTime(
            loan.anchorCreationDate
          )}`;
    }
  };

  // const toolTipInfo = (info: string) => {
  //   console.log("LOANS", loan);
  //   const verfiedBy =
  //     loan.tier1Tier2InvoiceMapping.tier2Invoice.uploadedBy === "Tier1"
  //       ? loan.tier1Tier2InvoiceMapping.tier1.actorInfo.name
  //       : loan.tier1Tier2InvoiceMapping.tier2.actorInfo.name;
  //   return info === "Customer"
  //     ? `\tVerification Details\nUsername : ${verfiedBy}\nCreation Date : ${formatDateToTime(
  //         loan.tier1Tier2InvoiceMapping.tier2Invoice.lastUpdateTimestamp
  //       )}`
  //     :''
  //     //  `\tVerification Details\nUsername : ${
  //     //     loan.tier1Tier2InvoiceMapping.anchor.actorInfo.name
  //     //   }\nCreation Date : ${formatDateToTime(loan.tier1Tier2InvoiceMapping.tier1Invoice.lastUpdateTimestamp)}`;
  //     //---tier1Invoice not coming in loan---
  // };

  /**
   * Utility UI function
   */
  const BankLoanDetailsInternal = () => {
    return (
      <div className="bank-loan-details" style={{ paddingLeft: "2vw", paddingRight: "2vw" }}>
        {openRejectionModal && (
          <BankRejectionModal
            rejectionHandler={rejectionHandler}
            onClose={() => setOpenRejectionModal(false)}
            loanType={loan.loanType}
          />
        )}
        {loader && <Loader />}
        <div className="detailed" style={{ marginBottom: "20px" }}>
          {/* two component  */}
          {/* if tier1 then buyer col only  else both column*/}
          {loan.loanType === "Tier1EarlyPayment" ? (
            <>
              <div className="column">
                <h2>Overall Information</h2>
                <div className="columns">
                  <div className="row">
                    <div className="column is-half">
                      <small>Loan Number</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.loan_Id}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Buyer</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.tier1}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Account</small>
                    </div>
                    <div className="column is-half">
                      <small>{123456}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Loan</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.EarlyPayment)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Payer</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.anchor}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Annualized IRR</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.InterestRate + "%"}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Repayment Date</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatDate(loan.Tier1InvoiceDueDate)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Existing loan </small>
                    </div>
                    <div className="column is-half">
                      <small>No</small>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* <div className="column">
             <div className="row">
               <h2>Customer Documents</h2>
 
               <div
                 className=" has-tooltip-info has-tooltip-right has-tooltip-top-mobile has-tooltip-arrow "
                 data-tooltip={toolTipInfo("Customer")}
                 style={{ marginLeft: "10px" }}
               >
                 <FontAwesomeIcon icon="check-circle" color="green" size="lg" />
               </div>
             </div>
 
             <div className="columns">
               <div className="row">
                 <div className="column is-half">
                   <small>Invoice#</small>
                 </div>
                 <div className="column is-half">
                   <small>{loan.tier1Tier2InvoiceMapping.tier2Invoice.invoiceId}</small>
                 </div>
               </div>
               <div className="row">
                 <div className="column is-half">
                   <small>Invoice value</small>
                 </div>
                 <div className="column is-half">
                   <small>{formatMoney(loan.tier1Tier2InvoiceMapping.tier2Invoice.invoiceAmount)}</small>
                 </div>
               </div>
               <div className="row">
                 <div className="column is-half">
                   <small>GRN#</small>
                 </div>
               </div>
               <div className="row">
                 <div className="column is-half">
                   <small>Debit note #</small>
                 </div>
                 <div className="column is-half">
                   {loan &&
                     loan.tier1Tier2InvoiceMapping.tier2Invoice.tier2InvoiceDetails.debitNotes.map((d, i) => (
                       <small key={i}>
                         {d.debitNoteNumber}
                         <a
                           style={{ paddingLeft: "15px" }}
                           onClick={() => {
                             setIsConfirmDialogOpen(true);
                             setVisibleDialog(d.debitNoteNumber);
                           }}
                         >
                           <i className="fas fa-image"></i>
                         </a>
                         {visibleDialog === d.debitNoteNumber && (
                           <Dialog
                             open={isConfirmDialogOpen}
                             onClose={() => {
                               setIsConfirmDialogOpen(false);
                               setVisibleDialog("");
                             }}
                           >
                             <header className="modal-card-head">
                               <p className="modal-card-title">
                                 <h3>DebitNote File</h3>
                               </p>
                               <button
                                 className="delete"
                                 aria-label="close"
                                 onClick={() => {
                                   setIsConfirmDialogOpen(false);
                                   setVisibleDialog("");
                                 }}
                               />
                             </header>
                             <section className="modal-card-body">
                               <div className="row">
                                 <div>
                                   <p>DebitNote# : {d.debitNoteNumber}</p>
                                 </div>
                               </div>
                               <div className="row">
                                 <div>
                                   <p>DebitNote Value: {convertDebitNoteAmountIntoDinero(d.debitNoteAmount * 100)}</p>
                                 </div>
                               </div>
 
                               {d.debitNoteFile && (
                                 <p className="image is-4by3" id="invoice">
                                   <img src={d.debitNoteFile} alt="Invoice" />
                                 </p>
                               )}
                             </section>
                             <footer className="modal-card-foot">
                               <button
                                 className="button  is-success"
                                 style={{ height: "50px", width: "100%" }}
                                 value="Ok"
                                 onClick={() => {
                                   setIsConfirmDialogOpen(false);
                                   setVisibleDialog("");
                                 }}
                               >
                                 Ok
                               </button>
                             </footer>
                           </Dialog>
                         )}
                       </small>
                     ))}
                 </div>
               </div>
               <div className="row">
                 <div className="column is-half">
                   <small>Receivable</small>
                 </div>
                 <div className="column is-half">
                   <small> {formatMoney(loan.tier1Tier2InvoiceMapping.tier2Invoice.invoiceAmount)} </small>
                 </div>
               </div>
             </div>
           </div> */}
              <hr />
              <div className="column">
                <div className="row">
                  <h2>Documents from Anchor</h2>

                  <div
                    className=" has-tooltip-info has-tooltip-right  has-tooltip-top-mobile has-tooltip-arrow "
                    data-tooltip={toolTipInfo("Buyer")}
                    style={{ marginLeft: "10px" }}
                  >
                    <FontAwesomeIcon icon="check-circle" color="green" size="lg" />
                  </div>
                </div>
                <div className="columns">
                  <div className="row">
                    <div className="column is-half">
                      <small>Invoice#</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.Tier1InvoiceId}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Invoice value</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>GRN#</small>
                    </div>
                    <div className="column is-half">
                      <small />
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Debit Note</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceTotalDebitAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Credit Note</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceTotalCreditAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Advance Note</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceTotalAdvanceAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Net Receivable</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceNetReceivable)}</small>
                    </div>
                  </div>
                </div>

                <div className="buttons">
                  <button className="button is-link is-light" onClick={backHandler}>
                    Back
                  </button>
                  {loan && loan.bankApprovalStatus === "Pending" && (
                    <button
                      className="is-success button"
                      onClick={() => onClickHandler("Approved")}
                      disabled={uploading ? true : false}
                    >
                      Approve
                    </button>
                  )}
                  {loan && loan.bankApprovalStatus === "Pending" && (
                    <button
                      className="is-danger button"
                      onClick={() => onClickHandler("Rejected")}
                      disabled={uploading ? true : false}
                    >
                      Decline
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="column">
                <h2>Overall Information</h2>
                <div className="columns">
                  <div className="row">
                    <div className="column is-half">
                      <small>Loan Number</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.loan_Id}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Customer</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.tier1}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Account</small>
                    </div>
                    <div className="column is-half">
                      <small>{123456}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Loan</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.EarlyPayment)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Payer</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.anchor}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Annualized IRR</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.InterestRate + "%"}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Repayment Date</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatDate(loan.Tier1InvoiceDueDate)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Existing loan </small>
                    </div>
                    <div className="column is-half">
                      <small>No</small>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="column">
                <div className="row">
                  <h2>Documents from Tier1</h2>

                  <div
                    className=" has-tooltip-info has-tooltip-right has-tooltip-top-mobile has-tooltip-arrow "
                    data-tooltip={toolTipInfo("Customer")}
                    style={{ marginLeft: "10px" }}
                  >
                    <FontAwesomeIcon icon="check-circle" color="green" size="lg" />
                  </div>
                </div>

                <div className="columns">
                  <div className="row">
                    <div className="column is-half">
                      <small>Invoice#</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.Tier2InvoiceId}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Invoice value</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier2InvoiceAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>GRN#</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Debit Note</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier2InvoiceTotalDebitAmount)}</small>
                      {/* {loan &&
                    loan.tier1Tier2InvoiceMapping.tier2Invoice.tier2InvoiceDetails.debitNotes.map((d, i) => (
                      <small key={i}>
                        {d.debitNoteNumber}
                        <a
                          style={{ paddingLeft: "15px" }}
                          onClick={() => {
                            setIsConfirmDialogOpen(true);
                            setVisibleDialog(d.debitNoteNumber);
                          }}
                        >
                          <i className="fas fa-image"></i>
                        </a>
                        {visibleDialog === d.debitNoteNumber && (
                          <Dialog
                            open={isConfirmDialogOpen}
                            onClose={() => {
                              setIsConfirmDialogOpen(false);
                              setVisibleDialog("");
                            }}
                          >
                            <header className="modal-card-head">
                              <p className="modal-card-title">
                                <h3>DebitNote File</h3>
                              </p>
                              <button
                                className="delete"
                                aria-label="close"
                                onClick={() => {
                                  setIsConfirmDialogOpen(false);
                                  setVisibleDialog("");
                                }}
                              />
                            </header>
                            <section className="modal-card-body">
                              <div className="row">
                                <div>
                                  <p>DebitNote# : {d.debitNoteNumber}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div>
                                  <p>DebitNote Value: {convertDebitNoteAmountIntoDinero(d.debitNoteAmount * 100)}</p>
                                </div>
                              </div>

                              {d.debitNoteFile && (
                                <p className="image is-4by3" id="invoice">
                                  <img src={d.debitNoteFile} alt="Invoice" />
                                </p>
                              )}
                            </section>
                            <footer className="modal-card-foot">
                              <button
                                className="button  is-success"
                                style={{ height: "50px", width: "100%" }}
                                value="Ok"
                                onClick={() => {
                                  setIsConfirmDialogOpen(false);
                                  setVisibleDialog("");
                                }}
                              >
                                Ok
                              </button>
                            </footer>
                          </Dialog>
                        )}
                      </small>
                    ))} */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Credit Note</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier2InvoiceTotalCreditAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Advance Note</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier2InvoiceTotalAdvanceAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Net Receivable</small>
                    </div>
                    <div className="column is-half">
                      <small> {formatMoney(loan.Tier2InvoiceNetReceivable)} </small>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="column">
                <div className="row">
                  <h2>Documents from Anchor</h2>

                  <div
                    className=" has-tooltip-info has-tooltip-right  has-tooltip-top-mobile has-tooltip-arrow "
                    data-tooltip={toolTipInfo("Buyer")}
                    style={{ marginLeft: "10px" }}
                  >
                    <FontAwesomeIcon icon="check-circle" color="green" size="lg" />
                  </div>
                </div>
                <div className="columns">
                  <div className="row">
                    <div className="column is-half">
                      <small>Invoice#</small>
                    </div>
                    <div className="column is-half">
                      <small>{loan.Tier1InvoiceId}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Invoice value</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>GRN#</small>
                    </div>
                    <div className="column is-half">
                      <small />
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Debit Note</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceTotalDebitAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Credit Note</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceTotalCreditAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Advance Note</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceTotalAdvanceAmount)}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column is-half">
                      <small>Net Receivable</small>
                    </div>
                    <div className="column is-half">
                      <small>{formatMoney(loan.Tier1InvoiceNetReceivable)}</small>
                    </div>
                  </div>
                </div>

                <div className="buttons">
                  <button className="button is-link is-light" onClick={backHandler}>
                    Back
                  </button>
                  {loan && loan.bankApprovalStatus === "Pending" && (
                    <button
                      className="is-success button"
                      onClick={() => onClickHandler("Approved")}
                      disabled={uploading ? true : false}
                    >
                      Approve
                    </button>
                  )}
                  {loan && loan.bankApprovalStatus === "Pending" && (
                    <button
                      className="is-danger button"
                      onClick={() => onClickHandler("Rejected")}
                      disabled={uploading ? true : false}
                    >
                      Decline
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <LoggedInSkeleton topBarButtons={getBankTopBarButtons("None")}>
      {loan === null ? <div /> : BankLoanDetailsInternal()}
    </LoggedInSkeleton>
  );
};

export default BankLoanDetails;
